<template>

  <body class="img-sfondo-home position-sticky">

    <!-- TITOLO -->
    <div v-if="this.lingua == 'IT'" class="margin-title d-flex justify-content-center">
      <h1 class="home-title text-center">IL MONDO</h1>
    </div>
    <div v-else-if="this.lingua == 'EN'" class="margin-title d-flex justify-content-center">
      <h1 class="home-title text-center">THE WORLD</h1>
    </div>
    <div v-else-if="this.lingua == 'FI'" class="margin-title d-flex justify-content-center">
      <h1 class="home-title text-center">MAAILMA</h1>
    </div>
    
    <!-- CONTINENTI -->

    <!-- italiano -->
    <div v-if="this.lingua == 'IT'" class="container">
      <div class="row m-footer">

        <div class="col flexiamo">
          <div class="row continente-custom justify-content-center text-center">
            <router-link class="link" to="/europa">EUROPA</router-link>
          </div>
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/asia">ASIA</router-link>
          </div>
        </div>
        
        <div class="col flexiamo">
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/nord-america">NORD AMERICA</router-link>
          </div>
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/sud-america">SUD AMERICA</router-link>
          </div>
        </div>
      
        <div class="col flexiamo">  
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/oceania">OCEANIA</router-link>
          </div>
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/africa">AFRICA</router-link>
          </div>
        </div>  

      </div>
    </div>

    <!-- inglese -->
    <div v-else-if="this.lingua == 'EN'" class="container">
      <div class="row m-footer">

        <div class="col flexiamo">
          <div class="row continente-custom justify-content-center text-center">
            <router-link class="link" to="/europa">EUROPE</router-link>
          </div>
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/asia">ASIA</router-link>
          </div>
        </div>
        
        <div class="col flexiamo">
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/nord-america">NORTH AMERICA</router-link>
          </div>
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/sud-america">SOUTH AMERICA</router-link>
          </div>
        </div>
      
        <div class="col flexiamo">           
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/oceania">OCEANIA</router-link>
          </div>
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/africa">AFRICA</router-link>
          </div>
        </div>  

      </div>
    </div>

    <!-- finlandese -->
    <div v-else-if="this.lingua == 'FI'" class="container">
      <div class="row m-footer">

        <div class="col flexiamo">
          <div class="row continente-custom justify-content-center text-center">
            <router-link class="link" to="/europa">EUROOPPA</router-link>
          </div>
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/asia">AASIA</router-link>
          </div>
        </div>
        
        <div class="col flexiamo">  
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/nord-america">POHJOIS-AMERIKKA</router-link>
          </div>
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/sud-america">ETELÄ-AMERIKKA</router-link>
          </div>
        </div>  

        <div class="col flexiamo">
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/oceania">OSEANIA</router-link>
          </div>
          <div class="row continente-custom text-center justify-content-center">
            <router-link class="link" to="/africa">AFRIKA</router-link>
          </div>
        </div>

      </div>
    </div>

  </body>






</template>

<script>


export default {
  name: 'HomeView',
  //importiamo la lingua
  props: ['lingua'],

  data(){
    return{

    }
  },

 
  methods:{

  }
}
</script>

<style>

/* IMMAGINE DI SFONDO E CENTRAMENTO CONTENUTO */
body{
  margin:0;
  padding:0;
  height: max-content;
}
.img-sfondo-home{
  background-image: url("../assets/map_world_sfondo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.m-footer{
  padding-bottom: 8%;
}

/* TITOLO */
.margin-title{
  padding-top: 28%;
  padding-bottom: 5%;
}
.home-title{
  color: rgba(189, 220, 249, 0.69);
  background-color: rgba(0, 0, 0, 0.43);
  backdrop-filter: blur(10px);
  border: 5px solid rgba(189, 220, 249, 0.69);
  border-radius: 100px;
  font-weight: bold;
  font-size: 20px;
  width: max-content;
  filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
  padding: 10px 40px 10px 40px;
}

/* card del continente */
.continente-custom{
  padding: 0px 10px 0px 10px;
  
}
.link{
  background-color:rgba(189, 220, 249, 0.53);
  border: 6px solid rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 100px;
  transition: 0.2s;
  min-width: max-content;
  font-weight: bold;
  font-size: 12px;
  color:black;
  text-decoration: none;
  margin: 10px 10px 10px 10px;
  padding: 10px 20px 10px 20px;
}
.link:hover{
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.65);
  border: 5px solid rgba(189, 220, 249, 0.69);
  color: rgba(189, 220, 249, 0.69); 
}
  

@media (min-width: 300px) {  
  .home-title{
    font-size: 30px;
    padding: 10px 50px 10px 50px;
  }
  .link{
    font-size: 20px;
  }
  .margin-title{
  padding-top: 23%;
  }
}
@media (min-width: 390px) {  
  .home-title{
    font-size: 35px;
    padding: 10px 60px 10px 60px;
  }
  .link{
    font-size: 25px;
  }
}
@media (min-width: 414px) { 
  .margin-title{
    padding-top: 20%;
  }
  .home-title{
    font-size: 40px;
    padding: 10px 70px 10px 70px;
  }
  .link{
    font-size: 30px;
  }
}
@media (min-width: 470px) { 
  .margin-title{
    padding-top: 18%;
  }
}
@media (min-width: 570px) { 
  .margin-title{
    padding-top: 15%;
  }
}
@media (min-width: 500px) { 
  .home-title{
    font-size: 55px;
    padding: 10px 80px 10px 80px;
  }
  .link{
    font-size: 40px;
  }
}
@media (min-width: 768px){
  .continente-custom{
    padding: 20px 10px 20px 10px;  
  }
  .link{
    font-size: 30px;
    margin: 10px 30px 10px 30px;
    padding: 20px 20px 20px 20px;
  }
  .home-title{
    font-size: 45px;
    padding: 10px 90px 10px 90px;
  }
  .margin-title{
    padding-top: 15%;
    padding-bottom: 5%;
  }
  .m-footer{
    padding-bottom: 20%;
  }
  .flexiamo{
    display: flex;
    justify-content: space-evenly;
  }
}
@media (min-width: 992px) { 
  .link{
    font-size: 28px;
    margin: 10px 30px 10px 30px;
    padding: 20px 20px 20px 20px;
  }
  .margin-title{
    padding-top: 13%;
    padding-bottom: 5%;
  }
  .home-title{
    padding: 10px 100px 10px 100px;
  }
  .flexiamo{
    display: block;
  }
  .m-footer{
    padding-bottom: 23%;
  }
}
@media (min-width: 1200px) { 
  .link{
    font-size: 35px;
    margin: 30px 30px 30px 30px;
  }
  .home-title{
    font-size: 50px;
  }
  .margin-title{
    padding-top: 11%;
    padding-bottom: 2%;
  }
  .m-footer{
    padding-bottom: 15%;
  }
}
@media (min-width: 1400px) { 
  .continente-custom{
    padding: 20px 30px 20px 30px;
  }
  .link{
    font-size: 38px;
  }
  .m-footer{
    padding-bottom: 10%;
  }
}




</style>
