//importo le viste
import { createRouter, createWebHistory } from 'vue-router'
//vista home
import HomeView from '../views/HomeView.vue'
//vista studio
import StudioView from '../views/StudioView.vue'
//viste dei continenti
import AfricaView from '../views/AfricaView.vue'
import AsiaView from '../views/AsiaView.vue'
import EuropaView from '../views/EuropaView.vue'
import NordAmericaView from '../views/NordAmericaView.vue'
import OceaniaView from '../views/OceaniaView.vue'
import SudAmericaView from '../views/SudAmericaView.vue'
//indovina capitale
import IndovinaCapitaleView from '../views/GAMES/IndovinaCapitaleView.vue'
//indovina stato dalla capitale
import IndovinaStatoView from '../views/GAMES/IndovinaStatoView.vue'
//indovina stato dalla bandiera
import IndovinaBandieraView from '../views/GAMES/IndovinaBandieraView.vue'
//indovina stato dalla mappa
import IndovinaMappaView from '../views/GAMES/IndovinaMappaView.vue'
//indovina stato dalla mappa
import IndovinaUEView from '../views/GAMES/IndovinaUEView.vue'
//viste per i link delle immagini
import linkBandiereView from '../views/link_immagini/linkBandiereView.vue'
import linkWikipediaView from '../views/link_immagini/linkWikipediaView.vue'


const routes = [

  //HOME
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  //STUDIO
  {
    path: '/studio',
    name: 'studio',
    component: StudioView
  },

  //CONTINENTI
    //AFRICA
    {
      path: '/africa',
      name: 'africa',
      component: AfricaView
    },
    //ASIA
    {
      path: '/asia',
      name: 'asia',
      component: AsiaView
    },
    //EUROPA
    {
      path: '/europa',
      name: 'europa',
      component: EuropaView
    },
    //NORD AMERICA
    {
      path: '/nord-america',
      name: 'nordAmerica',
      component: NordAmericaView
    },   
    //OCEANIA
    {
      path: '/oceania',
      name: 'oceania',
      component: OceaniaView
    },
    //SUD AMERICA
    {
      path: '/sud-america',
      name: 'sudAmercia',
      component: SudAmericaView
    },
  //

  //GIOCHI
    //indovina la capitale 
    {
      path: '/indovina-capitale/:continente',
      name: 'indovinaCapitale',
      component: IndovinaCapitaleView
    },
    //indovina stato dalla capitale
    {
      path: '/indovina-stato/:continente',
      name: 'indovinaStato',
      component: IndovinaStatoView
    },
    //indovina stato dalla bandiera
    {
      path: '/indovina-bandiera/:continente',
      name: 'indovinaBandiera',
      component: IndovinaBandieraView
    },
    //indovina stato dalla mappa
    {
      path: '/indovina-mappa/:continente',
      name: 'indovinaMappa',
      component: IndovinaMappaView
    },
    //indovina se lo stato fa parte dell'UE
    {
      path: '/indovina-ue',
      name: 'indovinaUE',
      component: IndovinaUEView
    },
  //

  //link delle immagini
    //link immagini bandiere
    {
      path: '/link-bandiere',
      name: 'linkBandiere',
      component: linkBandiereView
    },
    //link immagini wikipedia
    {
      path: '/link-wikipedia',
      name: 'linkWikipedia',
      component: linkWikipediaView
    },
  //

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
