<template>
    <div class="h-footer d-flex justify-content-evenly">

        <a class="toggle-footer d-flex align-items-center">
            <router-link v-if="this.lingua == 'IT'" class="toggle-footer-custom" to="/link-wikipedia" type="button">Immagini wikipedia</router-link>
            <router-link v-if="this.lingua == 'EN'" class="toggle-footer-custom" to="/link-wikipedia" type="button">Images wikipedia</router-link>
            <router-link v-if="this.lingua == 'FI'" class="toggle-footer-custom" to="/link-wikipedia" type="button">Kuvat wikipedia</router-link>
        </a>

        <a class="toggle-footer d-flex align-items-center">
            <router-link v-if="this.lingua == 'IT'" class="toggle-footer-custom" to="/link-bandiere" type="button">Immagini bandiere</router-link>
            <router-link v-if="this.lingua == 'EN'" class="toggle-footer-custom" to="/link-bandiere" type="button">Flag images</router-link>
            <router-link v-if="this.lingua == 'FI'" class="toggle-footer-custom" to="/link-bandiere" type="button">Lippukuvia</router-link>
        </a>    
    </div>
   
</template>
<script>
    export default{
        name: 'Footer',
        props: ['lingua'],
    }

</script>
<style>
.h-footer{
    height: 25vh;
    background-color: black;
    width: 100%;
}
.toggle-footer{
    
    text-decoration: none;
    margin-bottom: 20px;
}
.toggle-footer-custom{
    color: rgba(189, 220, 249, 0.69);
    text-decoration: none;
    border-bottom: 3px solid black;

}
.toggle-footer-custom:hover{
   border-bottom: 3px solid rgba(189, 220, 249, 0.69);
   transition: 0.3s;  
}
</style>

