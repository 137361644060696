<template>

    <h1 class="text-azzurrino">LINK IMMAGINI WIKIPEDIA</h1>

    <!-- AFRICA -->
    <h2 class="pt-5 text-warning">AFRICA</h2>

    <div class="d-flex">
        <p class="testo-bianco-sporco pe-2">Africa:</p>
        <a href="https://it.wikipedia.org/wiki/File:Africa_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:Africa_(orthographic_projection).svg</a>
    </div>

    <div class="pt-2">
        <!-- ALGERIA -->
        <div class="testo-bianco-sporco">ALGERIA</div>
        <a href="https://it.wikipedia.org/wiki/File:Algeria_(centered_orthographic_projection).svg
        ">https://it.wikipedia.org/wiki/File:Algeria_(centered_orthographic_projection).svg
        </a>
        <!-- ANGOLA -->
        <div class="testo-bianco-sporco">ANGOLA</div>
        <a href="https://it.wikipedia.org/wiki/File:Angola_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:Angola_(orthographic_projection).svg</a>
        <!-- BENIN -->
        <div class="testo-bianco-sporco">BENIN</div>
        <a href="https://it.wikipedia.org/wiki/File:BEN_orthographic.svg">https://it.wikipedia.org/wiki/File:BEN_orthographic.svg</a>
        <!-- BOTSWANA -->
        <div class="testo-bianco-sporco">BOTSWANA</div>
        <a href="https://it.wikipedia.org/wiki/File:BWA_orthographic.svg">https://it.wikipedia.org/wiki/File:BWA_orthographic.svg</a>
        <!-- BURKINA FASO -->
        <div class="testo-bianco-sporco">BURKINA FASO</div>
        <a href="https://it.wikipedia.org/wiki/File:Burkina_Faso_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:Burkina_Faso_(orthographic_projection).svg</a>
        <!-- BURUNDI -->
        <div class="testo-bianco-sporco">BURUNDI</div>
        <a href="https://it.wikipedia.org/wiki/File:Burundi_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:Burundi_(orthographic_projection).svg</a>
        <!-- CAMERUN -->
        <div class="testo-bianco-sporco">CAMERUN</div>
        <a href="https://it.wikipedia.org/wiki/File:CMR_orthographic.svg">https://it.wikipedia.org/wiki/File:CMR_orthographic.svg</a>
        <!-- CAPO VERDE -->
        <div class="testo-bianco-sporco">CAPO VERDE</div>
        <a href="https://en.wikipedia.org/wiki/File:Cape_Verde_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Cape_Verde_(orthographic_projection).svg</a>
        <!-- REPUBBLICA CENTRAFRICANA -->
        <div class="testo-bianco-sporco">REPUBBLICA CENTRAFRICANA</div>
        <a href="https://en.wikipedia.org/wiki/File:Central_African_Republic_(centered_orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Central_African_Republic_(centered_orthographic_projection).svg</a>
        <!-- CIAD -->
        <div class="testo-bianco-sporco">CIAD</div>
        <a href="https://en.wikipedia.org/wiki/File:Chad_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Chad_(orthographic_projection).svg</a>
        <!-- COMORE -->
        <div class="testo-bianco-sporco">COMORE</div>
        <a href="https://en.wikipedia.org/wiki/File:Comoros_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Comoros_(orthographic_projection).svg</a>
        <!-- COSTA D'AVORIO -->
        <div class="testo-bianco-sporco">COSTA D'AVORIO</div>
        <a href="https://en.wikipedia.org/wiki/File:C%C3%B4te_d%27Ivoire_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:C%C3%B4te_d%27Ivoire_(orthographic_projection).svg</a>
        <!-- REPUBBLICA DEMOCRATICA DEL CONGO -->
        <div class="testo-bianco-sporco">REPUBBLICA DEMOCRATICA DEL CONGO</div>
        <a href="https://en.wikipedia.org/wiki/File:Democratic_Republic_of_the_Congo_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Democratic_Republic_of_the_Congo_(orthographic_projection).svg</a>
        <!-- REPUBBLICA DEL CONGO -->
        <div class="testo-bianco-sporco">REPUBBLICA DEL CONGO</div>
        <a href="https://en.wikipedia.org/wiki/File:Republic_of_the_Congo_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Republic_of_the_Congo_(orthographic_projection).svg</a>
        <!-- EGITTO -->
        <div class="testo-bianco-sporco">EGITTO</div>
        <a href="https://en.wikipedia.org/wiki/File:EGY_orthographic.svg">https://en.wikipedia.org/wiki/File:EGY_orthographic.svg</a>
        <!-- ERITREA -->
        <div class="testo-bianco-sporco">ERITREA</div>
        <a href="https://en.wikipedia.org/wiki/File:Eritrea_(Africa_orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Eritrea_(Africa_orthographic_projection).svg</a>
        <!-- ETIOPIA -->
        <div class="testo-bianco-sporco">ETIOPIA</div>
        <a href="https://it.wikipedia.org/wiki/File:LocationEthiopia.svg">https://it.wikipedia.org/wiki/File:LocationEthiopia.svg</a>
        <!-- GABON -->
        <div class="testo-bianco-sporco">GABON</div>
        <a href="https://en.wikipedia.org/wiki/File:Gabon_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Gabon_(orthographic_projection).svg</a>
        <!-- GAMBIA -->
        <div class="testo-bianco-sporco">GAMBIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Gambia_(orthographic_projection_with_inset).svg">https://en.wikipedia.org/wiki/File:Gambia_(orthographic_projection_with_inset).svg</a>
        <!-- GHANA -->
        <div class="testo-bianco-sporco">GHANA</div>
        <a href="https://en.wikipedia.org/wiki/File:Ghana_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Ghana_(orthographic_projection).svg</a>
        <!-- GIBUTI -->
        <div class="testo-bianco-sporco">GIBUTI</div>
        <a href="https://en.wikipedia.org/wiki/File:Djibouti_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Djibouti_(orthographic_projection).svg</a>
        <!-- GUINEA -->
        <div class="testo-bianco-sporco">GUINEA</div>
        <a href="https://it.wikipedia.org/wiki/File:Guinea_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:Guinea_(orthographic_projection).svg</a>
        <!-- GUINEA BISSAU -->
        <div class="testo-bianco-sporco">GUINEA BISSAU</div>
        <a href="https://en.wikipedia.org/wiki/File:Location_Guinea_Bissau_AU_Africa.svg">https://en.wikipedia.org/wiki/File:Location_Guinea_Bissau_AU_Africa.svg</a>
        <!-- GUINEA EQUATORIALE -->
        <div class="testo-bianco-sporco">GUINEA EQUATORIALE</div>
        <a href="https://en.wikipedia.org/wiki/File:GNQ_orthographic.svg">https://en.wikipedia.org/wiki/File:GNQ_orthographic.svg</a>
        <!-- KENYA -->
        <div class="testo-bianco-sporco">KENYA</div>
        <a href="https://en.wikipedia.org/wiki/File:Kenya_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Kenya_(orthographic_projection).svg</a>
        <!-- LESOTHO -->
        <div class="testo-bianco-sporco">LESOTHO</div>
        <a href="https://en.wikipedia.org/wiki/File:Lesotho_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Lesotho_(orthographic_projection).svg</a>
        <!-- LIBERIA -->
        <div class="testo-bianco-sporco">LIBERIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Liberia_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Liberia_(orthographic_projection).svg</a>
        <!-- LIBIA -->
        <div class="testo-bianco-sporco">LIBIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Libya_(centered_orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Libya_(centered_orthographic_projection).svg</a>
        <!-- MADAGASCAR -->
        <div class="testo-bianco-sporco">MADAGASCAR</div>
        <a href="https://en.wikipedia.org/wiki/File:Madagascar_(centered_orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Madagascar_(centered_orthographic_projection).svg</a>
        <!-- MALAWI -->
        <div class="testo-bianco-sporco">MALAWI</div>
        <a href="https://en.wikipedia.org/wiki/File:Malawi_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Malawi_(orthographic_projection).svg</a>
        <!-- MALI -->
        <div class="testo-bianco-sporco">MALI</div>
        <a href="https://en.wikipedia.org/wiki/File:Mali_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Mali_(orthographic_projection).svg</a>
        <!-- MAURITANIA -->
        <div class="testo-bianco-sporco">MAURITANIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Mauritania_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Mauritania_(orthographic_projection).svg</a>
        <!-- MAURITIUS -->
        <div class="testo-bianco-sporco">MAURITIUS</div>
        <a href="https://en.wikipedia.org/wiki/File:Mauritius_(orthographic_projection_with_inset).svg">https://en.wikipedia.org/wiki/File:Mauritius_(orthographic_projection_with_inset).svg</a>
        <!-- MAROCCO -->
        <div class="testo-bianco-sporco">MAROCCO</div>
        <a href="https://en.wikipedia.org/wiki/File:Morocco_(orthographic_projection,_WS_claimed).svg">https://en.wikipedia.org/wiki/File:Morocco_(orthographic_projection,_WS_claimed).svg</a>
        <!-- MOZAMBICO -->
        <div class="testo-bianco-sporco">MOZAMBICO</div>
        <a href="https://en.wikipedia.org/wiki/File:Mozambique_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Mozambique_(orthographic_projection).svg</a>
        <!-- NAMIBIA -->
        <div class="testo-bianco-sporco">NAMIBIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Namibia_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Namibia_(orthographic_projection).svg</a>
        <!-- NIGER -->
        <div class="testo-bianco-sporco">NIGER</div>
        <a href="https://en.wikipedia.org/wiki/File:Niger_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Niger_(orthographic_projection).svg</a>
        <!-- NIGERIA -->
        <div class="testo-bianco-sporco">NIGERIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Nigeria_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Nigeria_(orthographic_projection).svg</a>
        <!-- RUANDA -->
        <div class="testo-bianco-sporco">RUANDA</div>
        <a href="https://it.wikipedia.org/wiki/File:Rwanda_on_the_globe_(Zambia_centered).svg">https://it.wikipedia.org/wiki/File:Rwanda_on_the_globe_(Zambia_centered).svg</a>
        <!-- SAO TOME E PRINCIPE -->
        <div class="testo-bianco-sporco">SAO TOME E PRINCIPE</div>
        <a href="https://it.wikipedia.org/wiki/File:Sao_Tome_and_Principe_on_the_globe_(Africa_centered).svg">https://it.wikipedia.org/wiki/File:Sao_Tome_and_Principe_on_the_globe_(Africa_centered).svg</a>    
        <!-- SENEGAL -->
        <div class="testo-bianco-sporco">SENEGAL</div>
        <a href="https://en.wikipedia.org/wiki/File:Senegal_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Senegal_(orthographic_projection).svg</a>
        <!-- SEYCHELLES -->
        <div class="testo-bianco-sporco">SEYCHELLES</div>
        <a href="https://it.wikipedia.org/wiki/File:SYC_orthographic.svg">https://it.wikipedia.org/wiki/File:SYC_orthographic.svg</a>
        <!-- SIERRA LEONE -->
        <div class="testo-bianco-sporco">SIERRA LEONE</div>
        <a href="https://en.wikipedia.org/wiki/File:Sierra_Leone_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Sierra_Leone_(orthographic_projection).svg</a>
        <!-- SOMALIA -->
        <div class="testo-bianco-sporco">SOMALIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Somalia_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Somalia_(orthographic_projection).svg</a>
        <!-- SUDAFRICA -->
        <div class="testo-bianco-sporco">SUDAFRCIA</div>
        <a href="https://en.wikipedia.org/wiki/File:ZAF_orthographic.svg">https://en.wikipedia.org/wiki/File:ZAF_orthographic.svg</a>
        <!-- SUDAN -->
        <div class="testo-bianco-sporco">SUDAN</div>
        <a href="https://it.wikipedia.org/wiki/File:Sudan_(orthographic_projection)_highlighted.svg">https://it.wikipedia.org/wiki/File:Sudan_(orthographic_projection)_highlighted.svg</a>
        <!-- SUDAN DEL SUD -->
        <div class="testo-bianco-sporco">SUDAN DEL SUD</div>
        <a href="https://en.wikipedia.org/wiki/File:South_Sudan_(orthographic_projection)_highlighted.svg">https://en.wikipedia.org/wiki/File:South_Sudan_(orthographic_projection)_highlighted.svg</a>
        <!-- ESWATINI -->
        <div class="testo-bianco-sporco">ESWATINI</div>
        <a href="https://en.wikipedia.org/wiki/File:Eswatini_on_the_globe_(special_marker)_(Madagascar_centered).svg">https://en.wikipedia.org/wiki/File:Eswatini_on_the_globe_(special_marker)_(Madagascar_centered).svg</a>
        <!-- TANZANIA -->
        <div class="testo-bianco-sporco">TANZANIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Tanzania_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Tanzania_(orthographic_projection).svg</a>
        <!-- TOGO -->
        <div class="testo-bianco-sporco">TOGO</div>
        <a href="https://it.wikipedia.org/wiki/File:Togo_on_the_globe_(Africa_centered).svg">https://it.wikipedia.org/wiki/File:Togo_on_the_globe_(Africa_centered).svg</a>
        <!-- TUNISIA -->
        <div class="testo-bianco-sporco">TUNISIA</div>
        <a href="https://it.wikipedia.org/wiki/File:Tunisia_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:Tunisia_(orthographic_projection).svg</a>
        <!-- UGANDA -->
        <div class="testo-bianco-sporco">UGANDA</div>
        <a href="https://it.wikipedia.org/wiki/File:Uganda_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:Uganda_(orthographic_projection).svg</a>
        <!-- ZAMBIA -->
        <div class="testo-bianco-sporco">ZAMBIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Zambia_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Zambia_(orthographic_projection).svg</a>
        <!-- ZIMBABWE -->
        <div class="testo-bianco-sporco">ZIMBABWE</div>
        <a href="https://en.wikipedia.org/wiki/File:Zimbabwe_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Zimbabwe_(orthographic_projection).svg</a>
    </div>

    <!-- ASIA -->
    <h2 class="pt-5 text-warning">ASIA</h2>

    <div class="d-flex">
        <p class="testo-bianco-sporco pe-2">Asia:</p>
        <a href="https://it.wikipedia.org/wiki/File:Asia_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:Asia_(orthographic_projection).svg</a>
    </div>

    <div class="pt-2">
        <!-- AFGHANISTAN -->
        <div class="testo-bianco-sporco">AFGHANISTAN</div>
        <a href="https://en.wikipedia.org/wiki/File:Afghanistan_(orthographic_projection).svg">hhttps://en.wikipedia.org/wiki/File:Afghanistan_(orthographic_projection).svg</a>
        <!-- ARABIA SAUDITA -->
        <div class="testo-bianco-sporco">ARABIA SAUDITA</div>
        <a href="https://en.wikipedia.org/wiki/File:Saudi_Arabia_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Saudi_Arabia_(orthographic_projection).svg</a>
        <!-- ARMENIA -->
        <div class="testo-bianco-sporco">ARMENIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Armenia_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Armenia_(orthographic_projection).svg</a>
        <!-- AZERBAIGIAN -->
        <div class="testo-bianco-sporco">AZERBAIGIAN</div>
        <a href="https://en.wikipedia.org/wiki/File:Azerbaijan_orthographic_projection.svg">https://en.wikipedia.org/wiki/File:Azerbaijan_orthographic_projection.svg</a>
        <!-- BAHREIN -->
        <div class="testo-bianco-sporco">BAHREIN</div>
        <a href="https://en.wikipedia.org/wiki/File:Map_of_Bahrain.svg">https://en.wikipedia.org/wiki/File:Map_of_Bahrain.svg</a>
        <!-- BANGLADESH -->
        <div class="testo-bianco-sporco">BANGLADESH</div>
        <a href="https://en.wikipedia.org/wiki/File:Bangladesh_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Bangladesh_(orthographic_projection).svg</a>
        <!-- BIRMANIA -->
        <div class="testo-bianco-sporco">BIRMANIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Myanmar_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Myanmar_(orthographic_projection).svg</a>
        <!-- BHUTAN -->
        <div class="testo-bianco-sporco">BHUTAN</div>
        <a href="https://en.wikipedia.org/wiki/File:Bhutan_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Bhutan_(orthographic_projection).svg</a>
        <!-- BRUNEI -->
        <div class="testo-bianco-sporco">BRUNEI</div>
        <a href="https://en.wikipedia.org/wiki/File:Brunei_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Brunei_(orthographic_projection).svg</a>
        <!-- CAMBOGIA -->
        <div class="testo-bianco-sporco">CAMBOGIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Cambodia_on_the_globe_(Cambodia_centered).svg">https://en.wikipedia.org/wiki/File:Cambodia_on_the_globe_(Cambodia_centered).svg</a>
        <!-- CINA -->
        <div class="testo-bianco-sporco">CINA</div>
        <a href="https://en.wikipedia.org/wiki/File:CHN_orthographic.svg">https://en.wikipedia.org/wiki/File:CHN_orthographic.svg</a>
        <!-- COREA DEL NORD -->
        <div class="testo-bianco-sporco">COREA DEL NORD</div>
        <a href="https://upload.wikimedia.org/wikipedia/commons/7/7b/North_Korea_%28orthographic_projection%29.svg">https://upload.wikimedia.org/wikipedia/commons/7/7b/North_Korea_%28orthographic_projection%29.svg</a>
        <!-- COREA DEL SUD -->
        <div class="testo-bianco-sporco">COREA DEL SUD</div>
        <a href="https://upload.wikimedia.org/wikipedia/commons/9/95/South_Korea_%28orthographic_projection%29.svg">https://upload.wikimedia.org/wikipedia/commons/9/95/South_Korea_%28orthographic_projection%29.svg</a>
        <!-- EMIRATI ARABI UNITI -->
        <div class="testo-bianco-sporco">EMIRATI ARABI UNITI</div>
        <a href="https://en.wikipedia.org/wiki/File:United_Arab_Emirates_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:United_Arab_Emirates_(orthographic_projection).svg</a>
        <!-- FILIPPINE -->
        <div class="testo-bianco-sporco">FILIPPINE</div>
        <a href="https://en.wikipedia.org/wiki/File:PHL_orthographic.svg">https://en.wikipedia.org/wiki/File:PHL_orthographic.svg</a>
        <!-- GEORGIA -->
        <div class="testo-bianco-sporco">GEORGIA</div>
        <a href="https://it.wikipedia.org/wiki/File:Europe_Georgia.svg">https://it.wikipedia.org/wiki/File:Europe_Georgia.svg</a>
        <!-- GIAPPONE -->
        <div class="testo-bianco-sporco">GIAPPONE</div>
        <a href="https://en.wikipedia.org/wiki/File:Japan_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Japan_(orthographic_projection).svg</a>
        <!-- GIORDANIA -->
        <div class="testo-bianco-sporco">GIORDANIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Jordan_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Jordan_(orthographic_projection).svg</a>
        <!-- INDIA -->
        <div class="testo-bianco-sporco">INDIA</div>
        <a href="https://en.wikipedia.org/wiki/File:India_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:India_(orthographic_projection).svg</a>
        <!-- INDONESIA -->
        <div class="testo-bianco-sporco">INDONESIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Indonesia_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Indonesia_(orthographic_projection).svg</a>
        <!-- IRAN -->
        <div class="testo-bianco-sporco">IRAN</div>
        <a href="https://en.wikipedia.org/wiki/File:Iran_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Iran_(orthographic_projection).svg</a>
        <!-- IRAQ -->
        <div class="testo-bianco-sporco">IRAQ</div>
        <a href="https://en.wikipedia.org/wiki/File:Iraq_(orthographic).svg">https://en.wikipedia.org/wiki/File:Iraq_(orthographic).svg</a>
        <!-- ISRAELE -->
        <div class="testo-bianco-sporco">ISRAELE</div>
        <a href="https://en.wikipedia.org/wiki/File:Israel_(orthographic_projection)_with_occupied_territories.svg">https://en.wikipedia.org/wiki/File:Israel_(orthographic_projection)_with_occupied_territories.svg</a>
        <!-- KAZAKISTAN -->
        <div class="testo-bianco-sporco">KAZAKISTAN</div>
        <a href="https://en.wikipedia.org/wiki/File:Kazakhstan_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Kazakhstan_(orthographic_projection).svg</a>
        <!-- KIRGHIZISTAN -->
        <div class="testo-bianco-sporco">KIRGHIZISTAN</div>
        <a href="https://en.wikipedia.org/wiki/File:Kyrgyzstan_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Kyrgyzstan_(orthographic_projection).svg</a>
        <!-- KUWAIT -->
        <div class="testo-bianco-sporco">KUWAIT</div>
        <a href="https://en.wikipedia.org/wiki/File:KWT_orthographic.svg">https://en.wikipedia.org/wiki/File:KWT_orthographic.svg</a>
        <!-- LAOS -->
        <div class="testo-bianco-sporco">LAOS</div>
        <a href="https://en.wikipedia.org/wiki/File:Laos_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Laos_(orthographic_projection).svg</a>
        <!-- LIBANO -->
        <div class="testo-bianco-sporco">LIBANO</div>
        <a href="https://en.wikipedia.org/wiki/File:Lebanon_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Lebanon_(orthographic_projection).svg</a>
        <!-- MALDIVE -->
        <div class="testo-bianco-sporco">MALDIVE</div>
        <a href="https://en.wikipedia.org/wiki/File:Maldives_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Maldives_(orthographic_projection).svg</a>
        <!-- MALAYSIA -->
        <div class="testo-bianco-sporco">MALAYSIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Malaysia_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Malaysia_(orthographic_projection).svg</a>
        <!-- MONGOLIA -->
        <div class="testo-bianco-sporco">MONGOLIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Mongolia_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Mongolia_(orthographic_projection).svg</a>
        <!-- NEPAL -->
        <div class="testo-bianco-sporco">NEPAL</div>
        <a href="https://en.wikipedia.org/wiki/File:Nepal_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Nepal_(orthographic_projection).svg</a>
        <!-- OMAN -->
        <div class="testo-bianco-sporco">OMAN</div>
        <a href="https://en.wikipedia.org/wiki/File:Oman_(better)_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Oman_(better)_(orthographic_projection).svg</a>
        <!-- PAKISTAN -->
        <div class="testo-bianco-sporco">PAKISTAN</div>
        <a href="https://en.wikipedia.org/wiki/File:PAK_orthographic.svg">https://en.wikipedia.org/wiki/File:PAK_orthographic.svg</a>
        <!-- PALESTINA -->
        <div class="testo-bianco-sporco">PALESTINA</div>
        <a href="https://en.wikipedia.org/wiki/File:State_of_Palestine_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:State_of_Palestine_(orthographic_projection).svgg</a>
        <!-- QATAR -->
        <div class="testo-bianco-sporco">QATAR</div>
        <a href="https://en.wikipedia.org/wiki/File:QAT_orthographic.svg">https://en.wikipedia.org/wiki/File:QAT_orthographic.svg</a>
        <!-- RUSSIA -->
        <div class="testo-bianco-sporco">RUSSIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Russian_Federation_(orthographic_projection)_-_All_Territorial_Disputes.svg">https://en.wikipedia.org/wiki/File:Russian_Federation_(orthographic_projection)_-_All_Territorial_Disputes.svg</a>
        <!-- SINGAPORE -->
        <div class="testo-bianco-sporco">SINGAPORE</div>
        <a href="https://en.wikipedia.org/wiki/File:Singapore_on_the_globe_(Southeast_Asia_centered)_zoom.svg">https://en.wikipedia.org/wiki/File:Singapore_on_the_globe_(Southeast_Asia_centered)_zoom.svg</a>
        <!-- SIRIA -->
        <div class="testo-bianco-sporco">SIRIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Syria_(orthographic_projection)_disputed.svg">https://en.wikipedia.org/wiki/File:Syria_(orthographic_projection)_disputed.svg</a>
        <!-- SRI LANKA -->
        <div class="testo-bianco-sporco">SRI LANKA</div>
        <a href="https://en.wikipedia.org/wiki/File:Sri_Lanka_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Sri_Lanka_(orthographic_projection).svg</a>
        <!-- TAGIKISTAN -->
        <div class="testo-bianco-sporco">TAGIKISTAN</div>
        <a href="https://en.wikipedia.org/wiki/File:Tajikistan_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Tajikistan_(orthographic_projection).svg</a>
        <!-- TAIWAN -->
        <div class="testo-bianco-sporco">TAIWAN</div>
        <a href="https://en.wikipedia.org/wiki/File:ROC_Taiwan_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:ROC_Taiwan_(orthographic_projection).svg</a>
        <!-- THAILANDIA -->
        <div class="testo-bianco-sporco">THAILANDIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Thailand_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Thailand_(orthographic_projection).svg</a>
        <!-- TIMOR EST -->
        <div class="testo-bianco-sporco">TIMOR EST</div>
        <a href="https://en.wikipedia.org/wiki/File:Timor_Leste_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Timor_Leste_(orthographic_projection).svg</a>
        <!-- TURKMENISTAN -->
        <div class="testo-bianco-sporco">TURKMENISTAN</div>
        <a href="https://en.wikipedia.org/wiki/File:Turkmenistan_on_the_globe_(Afro-Eurasia_centered).svg">https://en.wikipedia.org/wiki/File:Turkmenistan_on_the_globe_(Afro-Eurasia_centered).svg</a>
        <!-- UZBEKISTAN -->
        <div class="testo-bianco-sporco">UZBEKISTAN</div>
        <a href="https://en.wikipedia.org/wiki/File:Uzbekistan_(centered_orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Uzbekistan_(centered_orthographic_projection).svg</a>
        <!-- VIETNAM -->
        <div class="testo-bianco-sporco">VIETNAM</div>
        <a href="https://en.wikipedia.org/wiki/File:Vietnam_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Vietnam_(orthographic_projection).svg</a>
        <!-- YEMEN -->
        <div class="testo-bianco-sporco">YEMEN</div>
        <a href="https://en.wikipedia.org/wiki/File:Yemen_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Yemen_(orthographic_projection).svg</a>
    </div>

    <!-- EUROPA -->
    <h2 class="pt-5 text-warning">EUROPA</h2>

    <div class="d-flex">
        <p class="testo-bianco-sporco pe-2">Europa:</p>
        <a href="https://it.wikipedia.org/wiki/File:Europe_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:Europe_(orthographic_projection).svg</a>
    </div>
    
    <div class="pt-2">
        <!-- ALBANIA -->
        <div class="testo-bianco-sporco">ALBANIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Location_Albania_Europe.png">https://en.wikipedia.org/wiki/File:Location_Albania_Europe.png</a>
        <!-- ANDORRA -->
        <div class="testo-bianco-sporco">ANDORRA</div>
        <a href="https://en.wikipedia.org/wiki/File:Location_Andorra_Europe.png">https://en.wikipedia.org/wiki/File:Location_Andorra_Europe.png</a>
        <!-- AUSTRIA -->
        <div class="testo-bianco-sporco">AUSTRIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Austria_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:EU-Austria_(orthographic_projection).svg</a>
        <!-- BELGIO -->
        <div class="testo-bianco-sporco">BELGIO</div>
        <a href="https://en.wikipedia.org/wiki/File:Belgium_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Belgium_(orthographic_projection).svg</a>
        <!-- BIELORUSSIA -->
        <div class="testo-bianco-sporco">BIELORUSSIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Europe-Belarus_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Europe-Belarus_(orthographic_projection).svg</a>
        <!-- BOSNIA ED ERZEGOVINA -->
        <div class="testo-bianco-sporco">BOSNIA ED ERZEGOVINA</div>
        <a href="https://en.wikipedia.org/wiki/File:Europe-Bosnia_and_Herzegovina.svg">https://en.wikipedia.org/wiki/File:Europe-Bosnia_and_Herzegovina.svg</a>
        <!-- BULGARIA -->
        <div class="testo-bianco-sporco">BULGARIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Bulgaria.svg">https://en.wikipedia.org/wiki/File:EU-Bulgaria.svg</a>
        <!-- CIPRO -->
        <div class="testo-bianco-sporco">CIPRO</div>
        <a href="https://en.wikipedia.org/wiki/File:Republic_of_Cyprus_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Republic_of_Cyprus_(orthographic_projection).svg</a>
        <!-- CITTA DEL VATICANO -->
        <div class="testo-bianco-sporco">CITTA DEL VATICANO</div>
        <a href="https://en.wikipedia.org/wiki/File:Location_of_the_Vatican_City_in_Europe.svg">https://en.wikipedia.org/wiki/File:Location_of_the_Vatican_City_in_Europe.svg</a>
        <!-- CROAZIA -->
        <div class="testo-bianco-sporco">CROAZIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Croatia_(orthographic_projection).png">https://en.wikipedia.org/wiki/File:EU-Croatia_(orthographic_projection).png</a>
        <!-- DANIMARCA -->
        <div class="testo-bianco-sporco">DANIMARCA</div>
        <a href="https://en.wikipedia.org/wiki/File:Denmark_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Denmark_(orthographic_projection).svg</a>
        <!-- ESTONIA -->
        <div class="testo-bianco-sporco">ESTONIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Estonia.svg">https://en.wikipedia.org/wiki/File:EU-Estonia.svg</a>
        <!-- FINLANDIA -->
        <div class="testo-bianco-sporco">FINLANDIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Finland_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:EU-Finland_(orthographic_projection).svg</a>
        <!-- FRANCIA -->
        <div class="testo-bianco-sporco">FRANCIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-France_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:EU-France_(orthographic_projection).svg</a>
        <!-- GERMANIA -->
        <div class="testo-bianco-sporco">GERMANIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Germany_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:EU-Germany_(orthographic_projection).svg</a>
        <!-- GRECIA -->
        <div class="testo-bianco-sporco">GRECIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Greece_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:EU-Greece_(orthographic_projection).svg</a>
        <!-- IRLANDA -->
        <div class="testo-bianco-sporco">IRLANDA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Ireland.svg">https://en.wikipedia.org/wiki/File:EU-Ireland.svg</a>
        <!-- ISLANDA -->
        <div class="testo-bianco-sporco">ISLANDA</div>
        <a href="https://en.wikipedia.org/wiki/File:Iceland_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Iceland_(orthographic_projection).svg</a>
        <!-- ITALIA -->
        <div class="testo-bianco-sporco">ITALIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Italy_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:EU-Italy_(orthographic_projection).svg</a>
        <!-- LETTONIA -->
        <div class="testo-bianco-sporco">LETTONIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Latvia.svg">https://en.wikipedia.org/wiki/File:EU-Latvia.svg</a>
        <!-- LIECHTENSTEIN -->
        <div class="testo-bianco-sporco">LIECHTENSTEIN</div>
        <a href="https://en.wikipedia.org/wiki/File:Europe-Liechtenstein.svg">https://en.wikipedia.org/wiki/File:Europe-Liechtenstein.svg</a>
        <!-- LITUANIA -->
        <div class="testo-bianco-sporco">LITUANIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Lithuania.svg">https://en.wikipedia.org/wiki/File:EU-Lithuania.svg</a>
        <!-- LUSSEMBURGO -->
        <div class="testo-bianco-sporco">LUSSEMBURGO</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Luxembourg.svg">https://en.wikipedia.org/wiki/File:EU-Luxembourg.svg</a>
        <!-- MACEDONIA -->
        <div class="testo-bianco-sporco">MACEDONIA DEL NORD</div>
        <a href="https://en.wikipedia.org/wiki/File:Location_Macedonia_Europe.png">https://en.wikipedia.org/wiki/File:Location_Macedonia_Europe.png</a>
        <!-- MALTA -->
        <div class="testo-bianco-sporco">MALTA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Malta.svg">https://en.wikipedia.org/wiki/File:EU-Malta.svg</a>
        <!-- MOLDAVIA -->
        <div class="testo-bianco-sporco">MOLDAVIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Location_Moldova_Europe.png">https://en.wikipedia.org/wiki/File:Location_Moldova_Europe.png</a>
        <!-- MONACO -->
        <div class="testo-bianco-sporco">MONACO</div>
        <a href="https://en.wikipedia.org/wiki/File:Location_Monaco_Europe.png">https://en.wikipedia.org/wiki/File:Location_Monaco_Europe.png</a>
        <!-- MONTENEGRO -->
        <div class="testo-bianco-sporco">MONTENEGRO</div>
        <a href="https://en.wikipedia.org/wiki/File:Europe-Montenegro.svg">https://en.wikipedia.org/wiki/File:Europe-Montenegro.svg</a>
        <!-- NORVEGIA -->
        <div class="testo-bianco-sporco">NORVEGIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Europe-Norway_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Europe-Norway_(orthographic_projection).svg</a>
        <!-- PAESI BASSI -->
        <div class="testo-bianco-sporco">PAESI BASSI</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Netherlands.svg">https://en.wikipedia.org/wiki/File:EU-Netherlands.svg</a>
        <!-- POLONIA -->
        <div class="testo-bianco-sporco">POLONIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Poland_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:EU-Poland_(orthographic_projection).svg</a>
        <!-- PORTOGALLO -->
        <div class="testo-bianco-sporco">PORTOGALLO</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Portugal_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:EU-Portugal_(orthographic_projection).svg</a>
        <!-- REGNO UNITO -->
        <div class="testo-bianco-sporco">REGNO UNITO</div>
        <a href="https://en.wikipedia.org/wiki/File:Europe-UK_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Europe-UK_(orthographic_projection).svg</a>
        <!-- REPUBBLICA CECA -->
        <div class="testo-bianco-sporco">REPUBBLICA CECA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Czech_Republic.svg">https://en.wikipedia.org/wiki/File:EU-Czech_Republic.svg</a>
        <!-- ROMANIA -->
        <div class="testo-bianco-sporco">ROMANIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Romania_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:EU-Romania_(orthographic_projection).svg</a>     
        <!-- SAN MARINO -->
        <div class="testo-bianco-sporco">SAN MARINO</div>
        <a href="https://en.wikipedia.org/wiki/File:Location_San_Marino_Europe.png">https://en.wikipedia.org/wiki/File:Location_San_Marino_Europe.png</a>
        <!-- SERBIA -->
        <div class="testo-bianco-sporco">SERBIA</div>
        <a href="https://it.wikipedia.org/wiki/File:Map_of_Europe_and_Serbia_(2006%E2%80%932008).svg">https://it.wikipedia.org/wiki/File:Map_of_Europe_and_Serbia_(2006%E2%80%932008).svg</a>
        <!-- SLOVACCHIA -->
        <div class="testo-bianco-sporco">SLOVACCHIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Slovakia.svg">https://en.wikipedia.org/wiki/File:EU-Slovakia.svg</a>
        <!-- SLOVENIA -->
        <div class="testo-bianco-sporco">SLOVENIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Slovenia.svg">https://en.wikipedia.org/wiki/File:EU-Slovenia.svg</a>
        <!-- SPAGNA -->
        <div class="testo-bianco-sporco">SPAGNA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Spain_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:EU-Spain_(orthographic_projection).svg</a>   
        <!-- SVEZIA -->
        <div class="testo-bianco-sporco">SVEZIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Sweden_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:EU-Sweden_(orthographic_projection).svg</a>
        <!-- SVIZZERA -->
        <div class="testo-bianco-sporco">SVIZZERA</div>
        <a href="https://en.wikipedia.org/wiki/File:Europe-Switzerland.svg">https://en.wikipedia.org/wiki/File:Europe-Switzerland.svg</a>
        <!-- TURCHIA -->
        <div class="testo-bianco-sporco">TURCHIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Turkey_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Turkey_(orthographic_projection).svg</a>
        <!-- UCRAINA -->
        <div class="testo-bianco-sporco">UCRAINA</div>
        <a href="https://en.wikipedia.org/wiki/File:Ukraine_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Ukraine_(orthographic_projection).svg</a>
        <!-- UNGHERIA -->
        <div class="testo-bianco-sporco">UNGHERIA</div>
        <a href="https://en.wikipedia.org/wiki/File:EU-Hungary.svg">https://en.wikipedia.org/wiki/File:EU-Hungary.svg</a>
    </div>

    <!-- NORD AMERICA -->
    <h2 class="pt-5 text-warning">NORD AMERICA</h2>

    <div class="d-flex">
        <p class="testo-bianco-sporco pe-2">Nord America:</p>
        <a href="https://it.wikipedia.org/wiki/File:Location_North_America.svg">https://it.wikipedia.org/wiki/File:Location_North_America.svg</a>
    </div>

    <div class="pt-2">
        <!-- ANTIGUA E BARBUDA -->
        <div class="testo-bianco-sporco">ANTIGUA E BARBUDA</div>
        <a href="https://en.wikipedia.org/wiki/File:ATG_orthographic.svg">https://en.wikipedia.org/wiki/File:ATG_orthographic.svg</a>
        <!-- BAHAMAS -->
        <div class="testo-bianco-sporco">BAHAMAS</div>
        <a href="https://en.wikipedia.org/wiki/File:The_Bahamas_on_the_globe_(Americas_centered).svg">https://en.wikipedia.org/wiki/File:The_Bahamas_on_the_globe_(Americas_centered).svg</a>
        <!-- BARBADOS -->
        <div class="testo-bianco-sporco">BARBADOS</div>
        <a href="https://en.wikipedia.org/wiki/File:BRB_orthographic.svg">https://en.wikipedia.org/wiki/File:BRB_orthographic.svg</a>
        <!-- BELIZE -->
        <div class="testo-bianco-sporco">BELIZE</div>
        <a href="https://en.wikipedia.org/wiki/File:BLZ_orthographic.svg">https://en.wikipedia.org/wiki/File:BLZ_orthographic.svg</a>
        <!-- CANADA -->
        <div class="testo-bianco-sporco">CANADA</div>
        <a href="https://en.wikipedia.org/wiki/File:CAN_orthographic.svg">https://en.wikipedia.org/wiki/File:CAN_orthographic.svg</a>
        <!-- COSTA RICA -->
        <div class="testo-bianco-sporco">COSTA RICA</div>
        <a href="https://en.wikipedia.org/wiki/File:CRI_orthographic.svg">https://en.wikipedia.org/wiki/File:CRI_orthographic.svg</a>
        <!-- CUBA -->
        <div class="testo-bianco-sporco">CUBA</div>
        <a href="https://en.wikipedia.org/wiki/File:CUB_orthographic.svg">https://en.wikipedia.org/wiki/File:CUB_orthographic.svg</a>
        <!-- DOMINICA -->
        <div class="testo-bianco-sporco">DOMINICA</div>
        <a href="https://en.wikipedia.org/wiki/File:Dominica_on_the_globe_(Americas_centered).svg">https://en.wikipedia.org/wiki/File:Dominica_on_the_globe_(Americas_centered).svg</a>
        <!-- EL SALVADOR -->
        <div class="testo-bianco-sporco">EL SALVADOR</div>
        <a href="https://en.wikipedia.org/wiki/File:El_Salvador_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:El_Salvador_(orthographic_projection).svg</a>
        <!-- GIAMAICA -->
        <div class="testo-bianco-sporco">GIAMAICA</div>
        <a href="https://en.wikipedia.org/wiki/File:Jamaica_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Jamaica_(orthographic_projection).svg</a>
        <!-- GRENADA -->
        <div class="testo-bianco-sporco">GRENADA</div>
        <a href="https://en.wikipedia.org/wiki/File:Grenada_on_the_globe_(Americas_centered).svg">https://en.wikipedia.org/wiki/File:Grenada_on_the_globe_(Americas_centered).svg</a>
        <!-- GUATEMALA -->
        <div class="testo-bianco-sporco">GUATEMALA</div>
        <a href="https://en.wikipedia.org/wiki/File:Guatemala_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Guatemala_(orthographic_projection).svg</a>
        <!-- HAITI -->
        <div class="testo-bianco-sporco">HAITI</div>
        <a href="https://en.wikipedia.org/wiki/File:Haiti_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Haiti_(orthographic_projection).svg</a>
        <!-- HONDURAS -->
        <div class="testo-bianco-sporco">HONDURAS</div>
        <a href="https://en.wikipedia.org/wiki/File:HND_orthographic.svg">https://en.wikipedia.org/wiki/File:HND_orthographic.svg</a>
        <!-- MESSICO -->
        <div class="testo-bianco-sporco">MESSICO</div>
        <a href="https://en.wikipedia.org/wiki/File:MEX_orthographic.svg">https://en.wikipedia.org/wiki/File:MEX_orthographic.svg</a>
        <!-- NICARAGUA -->
        <div class="testo-bianco-sporco">NICARAGUA</div>
        <a href="https://en.wikipedia.org/wiki/File:NIC_orthographic.svg">https://en.wikipedia.org/wiki/File:NIC_orthographic.svg</a>
        <!-- PANAMA -->
        <div class="testo-bianco-sporco">PANAMA</div>
        <a href="https://en.wikipedia.org/wiki/File:PAN_orthographic.svg">https://en.wikipedia.org/wiki/File:PAN_orthographic.svg</a>
        <!-- REPUBBLICA DOMINICANA -->
        <div class="testo-bianco-sporco">REPUBBLICA DOMINICANA</div>
        <a href="https://en.wikipedia.org/wiki/File:Dominican_Republic_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Dominican_Republic_(orthographic_projection).svg</a>
        <!-- SAINT KITTIS E NEVIS -->
        <div class="testo-bianco-sporco">SAINT KITTIS E NEVIS</div>
        <a href="https://en.wikipedia.org/wiki/File:KNA_orthographic.svg">https://en.wikipedia.org/wiki/File:KNA_orthographic.svg</a>
        <!-- SAINT VINCENT E GRENADINE -->
        <div class="testo-bianco-sporco">SAINT VINCENT E GRENADINE</div>
        <a href="https://en.wikipedia.org/wiki/File:VCT_orthographic.svg">https://en.wikipedia.org/wiki/File:VCT_orthographic.svg</a>
        <!-- SAINT LUCIA -->
        <div class="testo-bianco-sporco">SAINT LUCIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Saint_Lucia_on_the_globe_(Americas_centered).svg">https://en.wikipedia.org/wiki/File:Saint_Lucia_on_the_globe_(Americas_centered).svg</a>
        <!-- USA -->
        <div class="testo-bianco-sporco">USA</div>
        <a href="https://en.wikipedia.org/wiki/File:USA_orthographic.svg">https://en.wikipedia.org/wiki/File:USA_orthographic.svg</a>
        <!-- TRINIDAD E TOBAGO -->
        <div class="testo-bianco-sporco">TRINIDAD E TOBAGO</div>
        <a href="https://en.wikipedia.org/wiki/File:Trinidad_and_Tobago_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Trinidad_and_Tobago_(orthographic_projection).svg</a>
    </div>

    <!-- OCEANIA -->
    <h2 class="pt-5 text-warning">OCEANIA</h2>

    <div class="d-flex">
        <p class="testo-bianco-sporco pe-2">Oceania:</p>
        <a href="https://it.wikipedia.org/wiki/File:Oceania_on_the_globe_(red)_(Polynesia_centered).svg">https://it.wikipedia.org/wiki/File:Oceania_on_the_globe_(red)_(Polynesia_centered).svg</a>
    </div>

    <div class="pt-2">
        <!-- AUSTRALIA -->
        <div class="testo-bianco-sporco">AUSTRALIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Australia_with_AAT_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Australia_with_AAT_(orthographic_projection).svg</a>
        <!-- NUOVA ZELANDA -->
        <div class="testo-bianco-sporco">NUOVA ZELANDA</div>
        <a href="https://en.wikipedia.org/wiki/File:NZL_orthographic_NaturalEarth.svg">https://en.wikipedia.org/wiki/File:NZL_orthographic_NaturalEarth.svg</a>  
        <!-- PAPUA NUOVA GUINEA -->
        <div class="testo-bianco-sporco">PAPUA NUOVA GUINEA</div>
        <a href="https://en.wikipedia.org/wiki/File:PNG_orthographic.svg">https://en.wikipedia.org/wiki/File:PNG_orthographic.svg</a>
        <!-- FIGI -->
        <div class="testo-bianco-sporco">FIGI</div>
        <a href="https://en.wikipedia.org/wiki/File:Fiji_on_the_globe_(small_islands_magnified)_(Polynesia_centered).svg">https://en.wikipedia.org/wiki/File:Fiji_on_the_globe_(small_islands_magnified)_(Polynesia_centered).svg</a>
        <!-- ISOLE SALOMONE -->
        <div class="testo-bianco-sporco">ISOLE SALOMONE</div>
        <a href="https://en.wikipedia.org/wiki/File:Solomon_Islands_on_the_globe_(Oceania_centered).svg">https://en.wikipedia.org/wiki/File:Solomon_Islands_on_the_globe_(Oceania_centered).svg</a>
        <!-- VANUATU -->
        <div class="testo-bianco-sporco">VANUATU</div>
        <a href="https://en.wikipedia.org/wiki/File:Vanuatu_on_the_globe_(Polynesia_centered).svg">https://en.wikipedia.org/wiki/File:Vanuatu_on_the_globe_(Polynesia_centered).svg</a>
        <!-- MICRONESIA -->
        <div class="testo-bianco-sporco">MICRONESIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Micronesia_on_the_globe_(small_islands_magnified)_(Polynesia_centered).svg">https://en.wikipedia.org/wiki/File:Micronesia_on_the_globe_(small_islands_magnified)_(Polynesia_centered).svg</a>
        <!-- KIRIBATI -->
        <div class="testo-bianco-sporco">KIRIBATI</div>
        <a href="https://en.wikipedia.org/wiki/File:Kiribati_on_the_globe_(Polynesia_centered).svg">https://en.wikipedia.org/wiki/File:Kiribati_on_the_globe_(Polynesia_centered).svg</a>
        <!-- ISOLE MARSHALL -->
        <div class="testo-bianco-sporco">ISOLE MARSHALL</div>
        <a href="https://en.wikipedia.org/wiki/File:Marshall_Islands_on_the_globe_(small_islands_magnified)_(Polynesia_centered).svg">https://en.wikipedia.org/wiki/File:Marshall_Islands_on_the_globe_(small_islands_magnified)_(Polynesia_centered).svg</a>    
        <!-- PALAU -->
        <div class="testo-bianco-sporco">PALAU</div>
        <a href="https://en.wikipedia.org/wiki/File:Palau_on_the_globe_(Southeast_Asia_centered)_(small_islands_magnified).svg">https://en.wikipedia.org/wiki/File:Palau_on_the_globe_(Southeast_Asia_centered)_(small_islands_magnified).svg</a>
        <!-- NAURU -->
        <div class="testo-bianco-sporco">NAURU</div>
        <a href="https://en.wikipedia.org/wiki/File:NRU_orthographic.svg">https://en.wikipedia.org/wiki/File:NRU_orthographic.svg</a>
        <!-- SAMOA -->
        <div class="testo-bianco-sporco">SAMOA</div>
        <a href="https://en.wikipedia.org/wiki/File:Samoa_on_the_globe_(Polynesia_centered).svg">https://en.wikipedia.org/wiki/File:Samoa_on_the_globe_(Polynesia_centered).svg</a>
        <!-- TONGA -->
        <div class="testo-bianco-sporco">TONGA</div>
        <a href="https://en.wikipedia.org/wiki/File:Tonga_on_the_globe_(Polynesia_centered).svg">https://en.wikipedia.org/wiki/File:Tonga_on_the_globe_(Polynesia_centered).svg</a>
        <!-- TUVALU -->
        <div class="testo-bianco-sporco">TUVALU</div>
        <a href="https://en.wikipedia.org/wiki/File:Tuvalu_on_the_globe_(Polynesia_centered).svg">https://en.wikipedia.org/wiki/File:Tuvalu_on_the_globe_(Polynesia_centered).svg</a>
    </div>

    <!-- SUD AMERICA -->
    <h2 class="pt-5 text-warning">SUD AMERICA</h2>

    <div class="d-flex">
        <p class="testo-bianco-sporco pe-2">Sud America:</p>
        <a href="https://it.wikipedia.org/wiki/File:South_America_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:South_America_(orthographic_projection).svg</a>
    </div>

    <div class="pt-2">           
        <!-- ARGENTINA -->
        <div class="testo-bianco-sporco">ARGENTINA</div>
        <a href="https://en.wikipedia.org/wiki/File:ARG_orthographic_(%2Ball_claims).svg">https://en.wikipedia.org/wiki/File:ARG_orthographic_(%2Ball_claims).svg</a>
        <!-- BOLIVIA -->
        <div class="testo-bianco-sporco">BOLIVIA</div>
        <a href="https://en.wikipedia.org/wiki/File:BOL_orthographic.svg">https://en.wikipedia.org/wiki/File:BOL_orthographic.svg</a>
        <!-- BRASILE -->
        <div class="testo-bianco-sporco">BRASILE</div>
        <a href="hhttps://en.wikipedia.org/wiki/File:BRA_orthographic.svg">hhttps://en.wikipedia.org/wiki/File:BRA_orthographic.svg</a>
        <!-- CILE -->
        <div class="testo-bianco-sporco">CILE</div>
        <a href="https://en.wikipedia.org/wiki/File:CHL_orthographic_(%2Ball_claims).svg">https://en.wikipedia.org/wiki/File:CHL_orthographic_(%2Ball_claims).svg</a>
        <!-- COLOMBIA -->
        <div class="testo-bianco-sporco">COLOMBIA</div>
        <a href="https://en.wikipedia.org/wiki/File:COL_orthographic_(San_Andr%C3%A9s_and_Providencia_special).svg">https://en.wikipedia.org/wiki/File:COL_orthographic_(San_Andr%C3%A9s_and_Providencia_special).svg</a>
        <!-- ECUADOR -->
        <div class="testo-bianco-sporco">ECUADOR</div>
        <a href="https://en.wikipedia.org/wiki/File:ECU_orthographic.svg">https://en.wikipedia.org/wiki/File:ECU_orthographic.svg</a>
        <!-- GUYANA -->
        <div class="testo-bianco-sporco">GUYANA</div>
        <a href="https://en.wikipedia.org/wiki/File:Guyana_(orthographic_projection).svg">https://en.wikipedia.org/wiki/File:Guyana_(orthographic_projection).svg</a>
        <!-- PARAGUAY -->
        <div class="testo-bianco-sporco">PARAGUAY</div>
        <a href="https://en.wikipedia.org/wiki/File:PRY_orthographic.svg">https://en.wikipedia.org/wiki/File:PRY_orthographic.svg</a>
        <!-- PERU -->
        <div class="testo-bianco-sporco">PERU</div>
        <a href="https://en.wikipedia.org/wiki/File:PER_orthographic.svg">https://en.wikipedia.org/wiki/File:PER_orthographic.svg</a>
        <!-- SURINAME -->
        <div class="testo-bianco-sporco">SURINAME</div>
        <a href="https://en.wikipedia.org/wiki/File:SURINAME_orthographic.svg">https://en.wikipedia.org/wiki/File:SURINAME_orthographic.svg</a>
        <!-- URUGUAY -->
        <div class="testo-bianco-sporco">URUGUAY</div>
        <a href="https://en.wikipedia.org/wiki/File:URY_orthographic.svg">https://en.wikipedia.org/wiki/File:URY_orthographic.svg</a>
        <!-- VENEZUELA -->
        <div class="testo-bianco-sporco">VENEZUELA</div>
        <a href="https://en.wikipedia.org/wiki/File:Venezuela_Orthographic_Map.svg">https://en.wikipedia.org/wiki/File:Venezuela_Orthographic_Map.svg</a>
    </div>

    <!-- USA -->
    <h2 class="pt-5 text-warning">USA</h2>

    <div class="d-flex">
        <p class="testo-bianco-sporco pe-2">U.s.a.:</p>
        <a href="https://it.wikipedia.org/wiki/File:United_States_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:United_States_(orthographic_projection).svg</a>
    </div>

    <div class="pt-2">
        <!-- CALIFORNIA -->
        <div class="testo-bianco-sporco">CALIFRONIA</div>
        <a href="https://en.wikipedia.org/wiki/File:California_in_United_States.svg">https://en.wikipedia.org/wiki/File:California_in_United_States.svg</a>
        <!-- TEXAS -->
        <div class="testo-bianco-sporco">TEXAS</div>
        <a href="https://en.wikipedia.org/wiki/File:Texas_in_United_States.svg">https://en.wikipedia.org/wiki/File:Texas_in_United_States.svg</a>
        <!-- FLORIDA -->
        <div class="testo-bianco-sporco">FLORIDA</div>
        <a href="https://it.wikipedia.org/wiki/File:Florida_in_United_States.svg">https://it.wikipedia.org/wiki/File:Florida_in_United_States.svg</a>
        <!-- NEW YORK -->
        <div class="testo-bianco-sporco">NEW YORK</div>
        <a href="https://it.wikipedia.org/wiki/File:New_York_in_United_States.svg">https://it.wikipedia.org/wiki/File:New_York_in_United_States.svg</a>
        <!-- PENNSYLVANIA -->
        <div class="testo-bianco-sporco">PENNSYLVANIA</div>
        <a href="https://en.wikipedia.org/wiki/File:Pennsylvania_in_United_States.svg">https://en.wikipedia.org/wiki/File:Pennsylvania_in_United_States.svg</a>
        <!-- ILLINOIS -->
        <div class="testo-bianco-sporco">ILLINOIS</div>
        <a href="https://it.wikipedia.org/wiki/File:Illinois_in_United_States.svg">https://it.wikipedia.org/wiki/File:Illinois_in_United_States.svg</a>
        <!-- OHIO -->
        <div class="testo-bianco-sporco">OHIO</div>
        <a href="https://en.wikipedia.org/wiki/File:Ohio_in_United_States.svg">https://en.wikipedia.org/wiki/File:Ohio_in_United_States.svg</a>
        <!-- GEORGIA -->
        <div class="testo-bianco-sporco">GEORGIA</div>
        <a href="https://it.wikipedia.org/wiki/File:Georgia_in_United_States.svg">https://it.wikipedia.org/wiki/File:Georgia_in_United_States.svg</a>
        <!-- CAROLINA DEL NORD -->
        <div class="testo-bianco-sporco">CAROLINA DEL NORD</div>
        <a href="https://it.wikipedia.org/wiki/File:North_Carolina_in_United_States.svg">https://it.wikipedia.org/wiki/File:North_Carolina_in_United_States.svg</a>
        <!-- MICHIGAN -->
        <div class="testo-bianco-sporco">MICHIGAN</div>
        <a href="https://en.wikipedia.org/wiki/File:Michigan_in_United_States.svg">https://en.wikipedia.org/wiki/File:Michigan_in_United_States.svg</a>
        <!-- NEW JERSEY -->
        <div class="testo-bianco-sporco">NEW JERSEY</div>
        <a href="https://en.wikipedia.org/wiki/File:New_Jersey_in_United_States_(zoom).svg">https://en.wikipedia.org/wiki/File:New_Jersey_in_United_States_(zoom).svg</a>
        <!-- VIRGINIA -->
        <div class="testo-bianco-sporco">VIRGINIA</div>
        <a href="https://it.wikipedia.org/wiki/File:Virginia_in_United_States.svg">https://it.wikipedia.org/wiki/File:Virginia_in_United_States.svg</a>
        <!-- WASHINGTON -->
        <div class="testo-bianco-sporco">WASHINGTON></div>
        <a href="https://it.wikipedia.org/wiki/File:Washington_in_United_States.svg">https://it.wikipedia.org/wiki/File:Washington_in_United_States.svg</a>
        <!-- ARIZONA -->
        <div class="testo-bianco-sporco">ARIZONA</div>
        <a href="https://en.wikipedia.org/wiki/File:Arizona_in_United_States.svg">https://en.wikipedia.org/wiki/File:Arizona_in_United_States.svg</a>
        <!-- MASSACHUSSETTS -->
        <div class="testo-bianco-sporco">MASSACHUSSETTS</div>
        <a href="https://en.wikipedia.org/wiki/File:Massachusetts_in_United_States.svg">https://en.wikipedia.org/wiki/File:Massachusetts_in_United_States.svg</a>
        <!-- TENNESSEE -->
        <div class="testo-bianco-sporco">TENNESSEE</div>
        <a href="https://en.wikipedia.org/wiki/File:Tennessee_in_United_States.svg">https://en.wikipedia.org/wiki/File:Tennessee_in_United_States.svg</a>
        <!-- INDIANA -->
        <div class="testo-bianco-sporco">INDIANA</div>
        <a href="https://en.wikipedia.org/wiki/File:Indiana_in_United_States.svg">https://en.wikipedia.org/wiki/File:Indiana_in_United_States.svg</a>
        <!-- MARYLAND -->
        <div class="testo-bianco-sporco">MARYLAND</div>
        <a href="https://it.wikipedia.org/wiki/File:Maryland_in_United_States.svg">https://it.wikipedia.org/wiki/File:Maryland_in_United_States.svg</a>
        <!-- MISSOURI -->
        <div class="testo-bianco-sporco">MISSOURI</div>
        <a href="https://it.wikipedia.org/wiki/File:Missouri_in_United_States.svg">https://it.wikipedia.org/wiki/File:Missouri_in_United_States.svg</a>
        <!-- WISCONSIN -->
        <div class="testo-bianco-sporco">WISCONSIN</div>
        <a href="https://en.wikipedia.org/wiki/File:Wisconsin_in_United_States.svg">https://en.wikipedia.org/wiki/File:Wisconsin_in_United_States.svg</a>
        <!-- COLORADO -->
        <div class="testo-bianco-sporco">COLORADO</div>
        <a href="https://en.wikipedia.org/wiki/File:Colorado_in_United_States.svg">https://en.wikipedia.org/wiki/File:Colorado_in_United_States.svg</a>
        <!-- MINNESOTA -->
        <div class="testo-bianco-sporco">MINNESOTA</div>
        <a href="https://commons.wikimedia.org/wiki/File:Minnesota_in_United_States.svg">https://commons.wikimedia.org/wiki/File:Minnesota_in_United_States.svg</a>
        <!-- SUD CAROLINA -->
        <div class="testo-bianco-sporco">SUD CAROLINA</div>
        <a href="https://it.wikipedia.org/wiki/File:South_Carolina_in_United_States.svg">https://it.wikipedia.org/wiki/File:South_Carolina_in_United_States.svg</a>
        <!-- ALABAMA -->
        <div class="testo-bianco-sporco">ALABAMA</div>
        <a href="https://commons.wikimedia.org/wiki/File:Alabama_in_United_States.svg">https://commons.wikimedia.org/wiki/File:Alabama_in_United_States.svg</a>
        <!-- LOUSIANA -->
        <div class="testo-bianco-sporco">LOUSIANA</div>
        <a href="https://it.wikipedia.org/wiki/File:Louisiana_in_United_States.svg">https://it.wikipedia.org/wiki/File:Louisiana_in_United_States.svg</a>
        <!-- KENTUCKY -->
        <div class="testo-bianco-sporco">KENTUCKY</div>
        <a href="https://commons.wikimedia.org/wiki/File:Kentucky_in_United_States.svg">https://commons.wikimedia.org/wiki/File:Kentucky_in_United_States.svg</a>
        <!-- OREGON -->
        <div class="testo-bianco-sporco">OREGON</div>
        <a href="https://it.wikipedia.org/wiki/File:Oregon_in_United_States.svg">https://it.wikipedia.org/wiki/File:Oregon_in_United_States.svg</a>
        <!-- OKLAHOMA -->
        <div class="testo-bianco-sporco">OKLAHOMA</div>
        <a href="https://en.wikipedia.org/wiki/File:Oklahoma_in_United_States.svg">https://en.wikipedia.org/wiki/File:Oklahoma_in_United_States.svg</a>
        <!-- CONNECTICUT -->
        <div class="testo-bianco-sporco">CONNECTICUT</div>
        <a href="https://en.wikipedia.org/wiki/File:Connecticut_in_United_States_(zoom).svg">https://en.wikipedia.org/wiki/File:Connecticut_in_United_States_(zoom).svg</a>
        <!-- UTAH -->
        <div class="testo-bianco-sporco">UTAH</div>
        <a href="https://en.wikipedia.org/wiki/File:Utah_in_United_States.svg">https://en.wikipedia.org/wiki/File:Utah_in_United_States.svg</a>
        <!-- IOWA -->
        <div class="testo-bianco-sporco">IOWA</div>
        <a href="https://en.wikipedia.org/wiki/File:Iowa_in_United_States.svg">https://en.wikipedia.org/wiki/File:Iowa_in_United_States.svg</a>
        <!-- NEVADA -->
        <div class="testo-bianco-sporco">NEVADA</div>
        <a href="https://it.wikipedia.org/wiki/File:Nevada_in_United_States.svg">https://it.wikipedia.org/wiki/File:Nevada_in_United_States.svg</a>
        <!-- ARKANSAS -->
        <div class="testo-bianco-sporco">ARKANSAS</div>
        <a href="https://commons.wikimedia.org/wiki/File:Arkansas_in_United_States.svg">https://commons.wikimedia.org/wiki/File:Arkansas_in_United_States.svg</a>
        <!-- MISSISSIPPI -->
        <div class="testo-bianco-sporco">MISSISSIPPI</div>
        <a href="https://it.wikipedia.org/wiki/File:Mississippi_in_United_States.svg">https://it.wikipedia.org/wiki/File:Mississippi_in_United_States.svg</a>
        <!-- KANSAS -->
        <div class="testo-bianco-sporco">KANSAS</div>
        <a href="https://it.wikipedia.org/wiki/File:Kansas_in_United_States.svg">https://it.wikipedia.org/wiki/File:Kansas_in_United_States.svg</a>
        <!--NEW MESSICO  -->
        <div class="testo-bianco-sporco">NEW MESSICO</div>
        <a href="https://it.wikipedia.org/wiki/File:New_Mexico_in_United_States.svg">https://it.wikipedia.org/wiki/File:New_Mexico_in_United_States.svg</a>
        <!-- NEBRASKA -->
        <div class="testo-bianco-sporco">NEBRASKA</div>
        <a href="https://it.wikipedia.org/wiki/File:Nebraska_in_United_States.svg">https://it.wikipedia.org/wiki/File:Nebraska_in_United_States.svg</a>
        <!-- IDAHO -->
        <div class="testo-bianco-sporco">IDAHO</div>
        <a href="https://it.wikipedia.org/wiki/File:Idaho_in_United_States.svg">https://it.wikipedia.org/wiki/File:Idaho_in_United_States.svg</a>
        <!-- WEST VIRGINIA -->
        <div class="testo-bianco-sporco">WEST VIRGINIA</div>
        <a href="https://it.wikipedia.org/wiki/File:West_Virginia_in_United_States.svg">https://it.wikipedia.org/wiki/File:West_Virginia_in_United_States.svg</a>
        <!-- HAWAII -->
        <div class="testo-bianco-sporco">HAWAII</div>
        <a href="https://it.wikipedia.org/wiki/File:Hawaii_in_United_States_(special_marker)_(US50).svg">https://it.wikipedia.org/wiki/File:Hawaii_in_United_States_(special_marker)_(US50).svg</a>
        <!--NEW HAMPSHIRE  -->
        <div class="testo-bianco-sporco">NEW HAMPSHIRE</div>
        <a href="https://en.wikipedia.org/wiki/File:New_Hampshire_in_United_States.svg">https://en.wikipedia.org/wiki/File:New_Hampshire_in_United_States.svg</a>
        <!-- MAINE -->
        <div class="testo-bianco-sporco">MAINE</div>
        <a href="https://commons.wikimedia.org/wiki/File:Maine_in_United_States.svg">https://commons.wikimedia.org/wiki/File:Maine_in_United_States.svg</a>
        <!-- RHODE ISLAND -->
        <div class="testo-bianco-sporco">RHODE ISLAND</div>
        <a href="https://en.wikipedia.org/wiki/File:Rhode_Island_in_United_States_(zoom)_(extra_close).svg">https://en.wikipedia.org/wiki/File:Rhode_Island_in_United_States_(zoom)_(extra_close).svg</a>
        <!-- MONTANA -->
        <div class="testo-bianco-sporco">MONTANA</div>
        <a href="https://commons.wikimedia.org/wiki/File:Montana_in_United_States.svg">https://commons.wikimedia.org/wiki/File:Montana_in_United_States.svg</a>
        <!-- DELAWARE -->
        <div class="testo-bianco-sporco">DELAWARE</div>
        <a href="https://en.wikipedia.org/wiki/File:Delaware_in_United_States_(zoom).svg">https://en.wikipedia.org/wiki/File:Delaware_in_United_States_(zoom).svg</a>
        <!-- DAKOTA DEL SUD -->
        <div class="testo-bianco-sporco">DAKOTA DEL SUD</div>
        <a href="https://it.wikipedia.org/wiki/File:South_Dakota_in_United_States.svg">https://it.wikipedia.org/wiki/File:South_Dakota_in_United_States.svg</a>
        <!-- DAKOTA DEL NORD -->
        <div class="testo-bianco-sporco">DAKOTA DEL NORD</div>
        <a href="https://it.wikipedia.org/wiki/File:North_Dakota_in_United_States.svg">https://it.wikipedia.org/wiki/File:North_Dakota_in_United_States.svg</a>
        <!-- ALASKA -->
        <div class="testo-bianco-sporco">ALASKA</div>
        <a href="https://it.wikipedia.org/wiki/File:Alaska_in_United_States_(US50).svg">https://it.wikipedia.org/wiki/File:Alaska_in_United_States_(US50).svg</a>
        <!-- VERMONT -->
        <div class="testo-bianco-sporco">VERMONT</div>
        <a href="https://it.wikipedia.org/wiki/File:Vermont_in_United_States.svg">https://it.wikipedia.org/wiki/File:Vermont_in_United_States.svg</a>
        <!-- WYOMING -->
        <div class="testo-bianco-sporco">WYOMING</div>
        <a href="https://en.wikipedia.org/wiki/File:Wyoming_in_United_States.svg">https://en.wikipedia.org/wiki/File:Wyoming_in_United_States.svg</a>
        <!-- DISTRETTO DI COLUMBIA -->
        <div class="testo-bianco-sporco">DISTRETTO DI COLUMBIA</div>
        <a href="https://it.wikipedia.org/wiki/File:DC_locator_map_with_state_names.jpg">https://it.wikipedia.org/wiki/File:DC_locator_map_with_state_names.jpg</a>
        <!-- PORTO RICO -->
        <div class="testo-bianco-sporco">PORTO RICO</div>
        <a href="https://it.wikipedia.org/wiki/File:Puerto_Rico_(orthographic_projection).svg">https://it.wikipedia.org/wiki/File:Puerto_Rico_(orthographic_projection).svg</a>
        <!-- ISOLE VERGINI AMERICANE -->
        <div class="testo-bianco-sporco">ISOLE VERGINI AMERICANE</div>
        <a href="https://it.wikipedia.org/wiki/File:United_States_Virgin_Islands_on_the_globe_(Americas_centered).svg">https://it.wikipedia.org/wiki/File:United_States_Virgin_Islands_on_the_globe_(Americas_centered).svg</a>
        <!-- SAMOA AMERICANE -->
        <div class="testo-bianco-sporco">SAMOA AMERICANE</div>
        <a href="https://it.wikipedia.org/wiki/File:American_Samoa_on_the_globe_(Polynesia_centered).svg">https://it.wikipedia.org/wiki/File:American_Samoa_on_the_globe_(Polynesia_centered).svg</a>
        <!-- GUAM -->
        <div class="testo-bianco-sporco">GUAM</div>
        <a href="https://en.wikipedia.org/wiki/File:Guam_on_the_globe_(Southeast_Asia_centered)_(small_islands_magnified).svg">https://en.wikipedia.org/wiki/File:Guam_on_the_globe_(Southeast_Asia_centered)_(small_islands_magnified).svg</a>
        <!-- ISOLE MARIANNE SETTENTRIONALI -->
        <div class="testo-bianco-sporco">ISOLE MARIANNE SETTENTRIONALI</div>
        <a href="https://it.wikipedia.org/wiki/File:Northern_Mariana_Islands_on_the_globe_(Southeast_Asia_centered)_(small_islands_magnified).svg">https://it.wikipedia.org/wiki/File:Northern_Mariana_Islands_on_the_globe_(Southeast_Asia_centered)_(small_islands_magnified).svg</a>
    </div>

</template>

<style>
.testo-bianco-sporco{
    color: antiquewhite;
}
.text-azzurrino{
    color: rgba(189, 220, 249, 0.69);
    padding-top: 100px;
}
</style>

<script>

export default{
    name: 'linkWikipediaView',
    //importiamo la lingua
    props: ['lingua'],

}

</script>