<template class="template">

  <body class="img-sfondo-indovina position-sticky">

    <div class="container centramento-contenuto">

      <!-- TITOLI -->
      <div class="row align-items-center justify-content-center margin-title-gioco">   
        <div class="col card-titoli">
          <div class="row text-center pe-4 ps-4">

            <!-- ITALIANO -->
            <div v-if="this.lingua == this.italiano" class="text-dark">
              <!-- titolo -->
              <div v-if="this.continente == this.EUROPA">
                <h1  class="text-dark">INDOVINA CHE STATO EUROPEO È</h1>
              </div>
              <div v-else-if="this.continente == this.ASIA">
                <h1  class="text-dark">INDOVINA CHE STATO ASIATICO È</h1>
              </div> 
              <div v-else-if="this.continente == this.AFRICA">
                <h1  class="text-dark">INDOVINA CHE STATO AFRICANO È</h1>
              </div> 
              <div v-else-if="this.continente == this.NORD_AMERICA">
                <h1  class="text-dark">INDOVINA CHE STATO NORD O CENTRO AMERICANO È</h1>
              </div> 
              <div v-else-if="this.continente == this.OCEANIA">
                <h1  class="text-dark">INDOVINA CHE STATO DELL'OCEANIA È</h1>
              </div> 
              <div v-else-if="this.continente == this.SUD_AMERICA">
                <h1  class="text-dark">INDOVINA CHE STATO SUD AMERICANO È</h1>
              </div> 
              <div v-else-if="this.continente == this.USA">
                <h1  class="text-dark">INDOVINA CHE STATO O TERRITORIO DEGLI U.S.A. È</h1>
              </div>            
              <!-- sotto titolo -->
              <h4>Devi indovinare lo stato avendo a disposizione la mappa</h4>
            </div>

            <!-- INGLESE -->
            <div v-else-if="this.lingua == this.inglese">
              <!-- titolo -->
              <div v-if="this.continente == this.EUROPA">
                <h1  class="text-dark">GUESS WHAT EUROPEAN COUNTRY IT IS</h1>
              </div>
              <div v-else-if="this.continente == this.ASIA">
                <h1  class="text-dark">GUESS WHAT ASIAN COUNTRY IT IS</h1>
              </div> 
              <div v-else-if="this.continente == this.AFRICA">
                <h1  class="text-dark">GUESS WHAT AFRICAN COUNTRY IT IS</h1>
              </div> 
              <div v-else-if="this.continente == this.NORD_AMERICA">
                <h1  class="text-dark">GUESS WHAT COUNTRY FROM CENTRAL OR NORD AMERICA IT IS</h1>
              </div> 
              <div v-else-if="this.continente == this.OCEANIA">
                <h1  class="text-dark">GUESS WHAT COUNTRY FROM OCEANIA IT IS</h1>
              </div> 
              <div v-else-if="this.continente == this.SUD_AMERICA">
                <h1  class="text-dark">GUESS WHAT SOUTH AMERICAN COUNTRY IT IS</h1>
              </div> 
              <div v-else-if="this.continente == this.USA">
                <h1  class="text-dark">GUESS WHAT STATE OR TERRITORY OF U.S.A. IT IS</h1>
              </div>            
              <!-- sotto titolo -->
              <h4>You have to guess the country having the map available</h4>
            </div>

            <!-- FINLANDESE -->
            <div v-else-if="this.lingua == this.finlandese">
              <!-- titolo -->
              <div v-if="this.continente == this.EUROPA">
                <h1  class="text-dark">ARVAVAA MIKÄ EUROOPAN MAA SE ON</h1>
              </div>
              <div v-else-if="this.continente == this.ASIA">
                <h1  class="text-dark">ARVAVAA MIKÄ AASIAAN MAA SE ON</h1>
              </div> 
              <div v-else-if="this.continente == this.AFRICA">
                <h1  class="text-dark">ARVAVAA MIKÄ AFRIKAN MAA SE ON</h1>
              </div> 
              <div v-else-if="this.continente == this.NORD_AMERICA">
                <h1  class="text-dark">ARVAVAA MIKÄ MAA KESKI- TAI POHJOIS-AMERIKASTA SE ON</h1>
              </div> 
              <div v-else-if="this.continente == this.OCEANIA">
                <h1  class="text-dark">ARVAVAA MIKÄ MAA OSEANISTA SE ON</h1>
              </div> 
              <div v-else-if="this.continente == this.SUD_AMERICA">
                <h1  class="text-dark">ARVAVAA MITÄ ETELÄ-AMERIKAN VALTA ON</h1>
              </div> 
              <div v-else-if="this.continente == this.USA">
                <h1  class="text-dark">ARVAVAA MIKÄ YHDYSVALTAIN OSAVALTIO TAI ALUE SE ON</h1>
              </div>         
              <!-- sotto titolo -->
              <h4>Sinun on arvattava maa, jolla on kartta oleva pääoma</h4>
            </div>

          </div>
        </div>
      </div>

      <!-- PARTE INTERATTIVA -->
      <div class="row align-items-center justify-content-center pe-5 ps-5">
        <div class="col d-flex justify-content-center card-gioco align-items-center text-center mt-5 pt-5 pb-5 me-5 ms-5">

          <!-- BOTTONE per INIZIARE -->
          <div class="row pt-5 pb-5 justify-content-center" v-if="this.a < 1">
    
            <!-- ITALIANO -->
            <div v-if="this.lingua == this.italiano" class="row align-items-center sperim">
              <button v-on:click="numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> INIZIA </button>
            </div>

            <!-- INGLESE -->
            <div v-else-if="this.lingua == this.inglese" class="row align-items-center sperim">
              <button v-on:click="numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> START </button>
            </div>
            
            <!-- FINLANDESE -->
            <div v-else-if="this.lingua == this.finlandese" class="row align-items-center sperim">
              <button v-on:click="numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> ALKAA </button>
            </div>
      
          </div>

          <!-- CARD PAGINA FINALE -->
          <div class="row justify-content-center pt-5 pb-5" v-else-if="this.a == this.final_page">

            <!-- ITALIANO -->
            <div class="col" v-if="this.lingua == this.italiano">
              <h5>VALUTAZIONE FINALE</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Punteggio finale: </strong> {{ score }}/{{ total }} </p>
                  <p  class="score-margin"><strong>Totale risposte giuste:</strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p  class="score-margin"><strong>Livello di conoscienza:</strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>Come minchia è possibile che non conosci nemmeno uno stato!?!? Sei proprio ignorante... Vai a studiare che e meglio...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>Sei un ignorante!! Vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>Sai qualcosina, ma ancora troppo poco, vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>Non sei arrivato neanche a meta, vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Meh, sei arrivatə a metà, ma non ancora alla sufficienza...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>Hai una conoscienza basica della geografia, ma hai ancora molto da imparare</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Discreto, puoi fare di meglio!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Bravo! Hai una buona conoscienza della geografia, ma puoi fare ancora meglio!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Complimentissimi!! Hai un ottima conoscienza della geografia!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Bravissimə!! Conosci tutte gli stati di questo continente, Ottimo lavoro!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> rincomincia </button>
              </div> 
            </div>
      
            <!-- INGLESE -->
            <div class="col" v-else-if="this.lingua == this.inglese">
              <h5>FINAL EVALUATION</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Final score: </strong> {{ score }}/{{ total }} </p>
                  <p class="score-margin"><strong>Total right answers: </strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p class="score-margin"><strong>Level of knowledge: </strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>How is it possible that you don't even know a country?!? You're really ignorant... Go study, it's better...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>You are ignorant!! Go study!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>You know a little, but still too little, go study!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>You haven't even reached your destination, go study!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Meh, you're halfway there, but not enough yet...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>You have a basic understanding of geography, but you still have a lot to learn</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Decent, you can do better!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Good! You have a good understanding of geography, but you can do even better!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Congratulations!! You have an excellent knowledge of geography!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Well done!! You know all the countries of this continent, Great job!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> start again </button>
              </div>
            </div>

            <!-- FINLANDESE -->
            <div class="col" v-else-if="this.lingua == this.finlandese">
              <h5>LOPPUARVIOINTI</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Lopullinen tulos: </strong> {{ score }}/{{ total }} </p>
                  <p class="score-margin"><strong>Oikeat vastaukset yhteensä: </strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p class="score-margin"><strong>Tiedon taso: </strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>Miten on mahdollista, ettet tiedä edes maa!?!? Olet todella tietämätön... Mene opiskelemaan, se on parempi...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>Olet tietämätön!! Mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>Tiedät vähän, mutta silti liian vähän, mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>Et ole edes saavuttanut määränpäätäsi, mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Mh, olet puolivälissä, mutta ei vielä tarpeeksi...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>Sinulla on perustiedot maantiedosta, mutta sinulla on vielä paljon opittavaa</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Kunnollinen, voit tehdä paremmin!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Hyvä! Ymmärrät maantiedon hyvin, mutta pystyt vielä parempaan!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Onnittelut!! Sinulla on erinomainen maantieteen taito!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Hyvin tehty!! Tiedät kaikki tämän mantereen maat, hienoa työtä!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> aloittaa uudelleen </button>
              </div>
            </div>

          </div>

          <!-- GIOCO -->
          <div v-else class="container-fluid text-center pt-5 pb-5 justify-content-center">

            <!-- CARD da compilare -->
            <div class="row align-items-center justify-content-center" v-if="this.cp == true">

              <!-- EUROPA -->
              <div v-if="this.continente == this.EUROPA">

                <!-- ITALIANO -->
                <div v-if="this.lingua == this.italiano">
                  <div v-for="stato in europaIT" class="col text-center">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">

                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeEuropa">                          
                            <div class="bordo-chiaro" v-if="mappa.id == this.id_casuale">
                              <img class="mappa-size" :src="mappa.imgMappa" alt="immagine della mappa di uno stato europeo">
                            </div>                           
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in europaEN" class="col text-center">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">

                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeEuropa">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="image of the map of a European state">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in europaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">

                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeEuropa">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="kuva Euroopan valtion kartasta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <!-- ASIA -->
              <div v-else-if="this.continente == this.ASIA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in asiaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeAsia">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="immagine della mappa di uno stato asiatico">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in asiaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeAsia">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="image of the map of an Asian country">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in asiaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeAsia">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="kuva Aasian maan kartasta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <!-- AFRICA -->
              <div v-else-if="this.continente == this.AFRICA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in africaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeAfrica">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="immagine della mappa di uno stato africano">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in africaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeAfrica">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="image of the map of an African country">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in africaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeAfrica">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="kuva Afrikan maan kartasta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- NORD AMERICA -->
              <div v-else-if="this.continente == this.NORD_AMERICA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in nordAmericaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeNordAmerica">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="immagine della mappa di uno stato nord o centro americano">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in nordAmericaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeNordAmerica">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="image of the map of a North or Central American state">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in nordAmericaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeNordAmerica">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="kuva Pohjois- tai Keski-Amerikan osavaltion kartasta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- OCEANIA -->
              <div v-else-if="this.continente == this.OCEANIA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in oceaniaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeOceania">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="immagine della mappa di uno stato dell'oceania">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in oceaniaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeOceania">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="image of the map of a state of oceania">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in oceaniaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeOceania">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="kuva valtameren osavaltion kartasta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- SUD AMERICA -->
              <div v-else-if="this.continente == this.SUD_AMERICA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in sudAmericaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeSudAmerica">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="immagine della mappa di uno stato sud americano">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in sudAmericaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeSudAmerica">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="image of the map of a South American state">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in sudAmericaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeSudAmerica">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="kuva Etelä-Amerikan osavaltion kartasta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- USA -->
              <div v-else-if="this.continente == this.USA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in USAIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeUSA">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="immagine della mappa di uno degli stati degli U.S.A.">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in USAEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeUSA">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="image of the map of one of the U.S.A. states.">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in USAFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- img mappa -->
                        <div class="mb-4" v-for="mappa in mappeUSA">
                          <div v-if="mappa.id == this.id_casuale">
                            <img class="mappa-size" :src="mappa.imgMappa" alt="kuva yhden Yhdysvaltain osavaltion kartasta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
      
            <!-- CARD di soluzione -->
            <div class="row align-items-center justify-content-center" v-else>

              <!-- SOLUZIONE -->
              <div class="text-center container-fluid">

                <!-- ITALIANO -->
                <div v-if="this.lingua == this.italiano">
                  <div class="orientamento-soluzione pt-3 pb-3">
                    <h5 class="me-2">SOLUZIONE: </h5>
                    <p> {{ Stato }}</p>
                  </div>
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6">complimenti la risposta era corretta!</span>
                    <p class="pt-3">punti + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Ignorante! La risposta era sbagliata!</span>
                    <p class="pt-3">punti -5</p>
                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div class="orientamento-soluzione pt-3 pb-3">
                    <h5 class="me-2">CORRECT ANSWER: </h5>
                    <p> {{ Stato }}</p>
                  </div>
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6 ">congratulations, the answer was correct!</span>
                    <p class="pt-3">points + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Ignorant! The answer was wrong!</span>
                    <p class="pt-3">points -5</p>
                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div class="orientamento-soluzione pt-3 pb-3">
                    <h5 class="me-2">OIKEA VASTAUS: </h5>
                    <p> {{ Stato }}</p>
                  </div>
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6 ">onnittelut, vastaus oli oikea!</span>
                    <p class="pt-3">pisteitä + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Tietämätön! Vastaus oli väärä!</span>
                    <p class="pt-3">pisteitä -5</p>
                  </div>
                </div>
                
              </div>

              <!-- SCORE -->
              <div class="d-flex justify-content-center align-items-center text-center ">

                <!-- ITALIANO -->
                <div class="score-border" v-if="this.lingua == this.italiano">
                  <p class="score-margin">punteggio totale: {{ score }}</p>
                  <p class="score-margin">totale risposte giuste: {{ conoscenza }}</p>
                </div>

                <!-- INGLESE -->
                <div class="score-border" v-else-if="this.lingua == this.inglese">
                  <p class="score-margin">total score: {{ score }}</p>
                  <p class="score-margin">total correct answers: {{ conoscenza }}</p>
                </div>

                <!-- FINLANDESE -->
                <div class="score-border" v-else-if="this.lingua == this.finlandese">
                  <p class="score-margin">kokonaispisteet: {{ score }}</p>
                  <p class="score-margin">täysin oikeat vastaukset: {{ conoscenza }}</p>
                </div>
                
              </div>

              <!-- BOTTONE per CAMBIARE STATO / FINE -->
              <div>
                <!-- CAMBIA STATO -->
                <div v-if="this.a < this.n_stati">
                  <!-- ITALIANO -->
                  <div v-if="this.lingua == this.italiano">
                    <button class="btn-custom mb-4 mt-4" v-on:click="numeroCasuale(); changeState();" type="submit"> prossimo stato </button>
                  </div>
                  <!-- INGLESE -->
                  <div v-else-if="this.lingua == this.inglese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="numeroCasuale(); changeState();" type="submit"> next country </button>
                  </div>
                  <!-- FINLANDESE -->
                  <div v-else-if="this.lingua == this.finlandese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="numeroCasuale(); changeState();" type="submit"> seuraava maa </button>
                  </div>
                </div>
                <!-- FINE -->
                <div v-else>
                  <!-- ITALIANO -->
                  <div v-if="this.lingua == this.italiano">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> fine </button>
                  </div>
                  <!-- INGLESE -->
                  <div v-else-if="this.lingua == this.inglese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> end </button>
                  </div>
                  <!-- FINLANDESE -->
                  <div v-else-if="this.lingua == this.finlandese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> loppu </button>
                  </div>
                </div>
              </div>
              
            </div>

            <!-- A QUALE STATO SIAMO -->
            <div class="row">
              <div class="text-center container-fluid">
                <p>{{ a }}/{{ n_stati }}</p>
              </div>
            </div>
            
          </div>

        </div>
      </div>

    </div>

  </body>

</template>

<script>

  //importo i file json
    import statiItaliano from '/statiItalianoJson.json'
    import statiInglese from '/statiIngleseJson.json'
    import statiFinlandese from '/statiFinlandeseJson.json'
  //

  //importiamo le immagini delle mappe
    //AFRICA
    import imgAlgeria from "../../assets/stati/img-africa/algeria-mappa.png"
    import imgAngola from "../../assets/stati/img-africa/angola-mappa.png"
    import imgBenin from "../../assets/stati/img-africa/benin-mappa.png"
    import imgBotswana from "../../assets/stati/img-africa/botswana-mappa.png"
    import imgBurkinaFaso from "../../assets/stati/img-africa/burkina-faso-mappa.png"
    import imgBurundi from "../../assets/stati/img-africa/burundi-mappa.png"
    import imgCamerun from "../../assets/stati/img-africa/camerun-mappa.png"
    import imgCapoVerde from "../../assets/stati/img-africa/capo-verde-mappa.png"
    import imgCiad from "../../assets/stati/img-africa/ciad-mappa.png"
    import imgComore from "../../assets/stati/img-africa/comore-mappa.png"
    import imgCongo from "../../assets/stati/img-africa/repubblica-del-congo-mappa.png"
    import imgCostaDAvorio from "../../assets/stati/img-africa/costa-d-avorio-mappa.png"
    import imgEgitto from "../../assets/stati/img-africa/egitto-mappa.png"
    import imgEritrea from "../../assets/stati/img-africa/eritrea-mappa.png"
    import imgEtiopia from "../../assets/stati/img-africa/etiopia-mappa.png"
    import imgGabon from "../../assets/stati/img-africa/gabon-mappa.png"
    import imgGambia from "../../assets/stati/img-africa/gambia-mappa.png"
    import imgGhana from "../../assets/stati/img-africa/ghana-mappa.png"
    import imgGibuti from "../../assets/stati/img-africa/gibuti-mappa.png"
    import imgGuinea from "../../assets/stati/img-africa/guinea-mappa.png"
    import imgGuineaBissau from "../../assets/stati/img-africa/guinea-bissau-mappa.png"
    import imgGuineaEquatoriale from "../../assets/stati/img-africa/guinea-equatoriale-mappa.png"
    import imgKenia from "../../assets/stati/img-africa/kenia-mappa.png"
    import imgLesotho from "../../assets/stati/img-africa/lesotho-mappa.png"
    import imgLiberia from "../../assets/stati/img-africa/liberia-mappa.png"
    import imgLibia from "../../assets/stati/img-africa/libia-mappa.png"
    import imgMadagascar from "../../assets/stati/img-africa/madagascar-mappa.png"
    import imgMalawi from "../../assets/stati/img-africa/malawi-mappa.png"
    import imgMali from "../../assets/stati/img-africa/mali-mappa.png"
    import imgMarocco from "../../assets/stati/img-africa/marocco-mappa.png"
    import imgMauritania from "../../assets/stati/img-africa/mauritania-mappa.png"
    import imgMauritius from "../../assets/stati/img-africa/mauritius-mappa.png"
    import imgMozambico from "../../assets/stati/img-africa/mozambico-mappa.png"
    import imgNamibia from "../../assets/stati/img-africa/namibia-mappa.png"
    import imgNiger from "../../assets/stati/img-africa/niger-mappa.png"
    import imgNigeria from "../../assets/stati/img-africa/nigeria-mappa.png"
    import imgRepubblicaCentrafricana from "../../assets/stati/img-africa/repubblica-centro-africana-mappa.png"
    import imgRepubblicaDemocraticaDelCongo from "../../assets/stati/img-africa/repubblica-democratica-del-congo-mappa.png"
    import imgRuanda from "../../assets/stati/img-africa/ruanda-mappa.png"
    import imgSaoTomeEPrincipe from "../../assets/stati/img-africa/sao-tome-e-principe-mappa.png"
    import imgSenegal from "../../assets/stati/img-africa/senegal-mappa.png"
    import imgSeychelles from "../../assets/stati/img-africa/seychelles-mappa.png"
    import imgSierraLeone from "../../assets/stati/img-africa/sierra-leone-mappa.png"
    import imgSomalia from "../../assets/stati/img-africa/somalia-mappa.png"
    import imgSudAfrica from "../../assets/stati/img-africa/sud-africa-mappa.png"
    import imgSudan from "../../assets/stati/img-africa/sudan-mappa.png"
    import imgSudanDelSud from "../../assets/stati/img-africa/sud-sudan-mappa.png"
    import imgEswatini from "../../assets/stati/img-africa/eswatini-mappa.png"
    import imgTanzania from "../../assets/stati/img-africa/tanzania-mappa.png"
    import imgTogo from "../../assets/stati/img-africa/togo-mappa.png"
    import imgTunisia from "../../assets/stati/img-africa/tunisia-mappa.png"
    import imgUganda from "../../assets/stati/img-africa/uganda-mappa.png"
    import imgZambia from "../../assets/stati/img-africa/zambia-mappa.png"
    import imgZimbabwe from "../../assets/stati/img-africa/zimbabwe-mappa.png"

    //ASIA
    import imgAfghanistan from "../../assets/stati/img-asia/afghanistan-mappa.png"
    import imgArabiaSaudita from "../../assets/stati/img-asia/arabia-saudita-mappa.png"
    import imgArmenia from "../../assets/stati/img-asia/armenia-mappa.png"
    import imgAzeraigian from "../../assets/stati/img-asia/azerbaigian-mappa.png"
    import imgBahrein from "../../assets/stati/img-asia/bahrein-mappa.png"
    import imgBangladesh from "../../assets/stati/img-asia/bangladesh-mappa.png"
    import imgBhutan from "../../assets/stati/img-asia/bhutan-mappa.png"
    import imgBirmania from "../../assets/stati/img-asia/birmania-mappa.png"
    import imgBrunei from "../../assets/stati/img-asia/brunei-mappa.png"
    import imgCambogia from "../../assets/stati/img-asia/cambogia-mappa.png"
    import imgCina from "../../assets/stati/img-asia/cina-mappa.png"
    import imgCoreaDelNord from "../../assets/stati/img-asia/nord-corea-mappa.png"
    import imgCoreaDelSud from "../../assets/stati/img-asia/sud-corea-mappa.png"
    import imgEmiratiArabiUniti from "../../assets/stati/img-asia/emirati-arabi-uniti-mappa.png"
    import imgFilippine from "../../assets/stati/img-asia/filippine-mappa.png"
    import imgGeorgia from "../../assets/stati/img-asia/georgia-mappa.png"
    import imgGiappone from "../../assets/stati/img-asia/giappone-mappa.png"
    import imgGiordania from "../../assets/stati/img-asia/giordania-mappa.png"
    import imgIndia from "../../assets/stati/img-asia/india-mappa.png"
    import imgIndonesia from "../../assets/stati/img-asia/indonesia-mappa.png"
    import imgIran from "../../assets/stati/img-asia/iran-mappa.png"
    import imgIraq from "../../assets/stati/img-asia/iraq-mappa.png"
    import imgIsraele from "../../assets/stati/img-asia/israele-mappa.png"
    import imgKazakistan from "../../assets/stati/img-asia/kazakistan-mappa.png"
    import imgKirghizistan from "../../assets/stati/img-asia/kirgikistan-mappa.png"
    import imgKuwait from "../../assets/stati/img-asia/kuwait-mappa.png"
    import imgLaos from "../../assets/stati/img-asia/laos-mappa.png"
    import imgLibano from "../../assets/stati/img-asia/libano-mappa.png"
    import imgMalaysia from "../../assets/stati/img-asia/malasia-mappa.png"
    import imgMaldive from "../../assets/stati/img-asia/maldive-mappa.png"
    import imgMongolia from "../../assets/stati/img-asia/mongolia-mappa.png"
    import imgNepal from "../../assets/stati/img-asia/nepal-mappa.png"
    import imgOman from "../../assets/stati/img-asia/oman-mappa.png"
    import imgPakistan from "../../assets/stati/img-asia/pakistan-mappa.png"
    import imgPalestina from "../../assets/stati/img-asia/palestina-mappa.png"
    import imgQatar from "../../assets/stati/img-asia/qatar-mappa.png"
    import imgRussia from "../../assets/stati/img-asia/russia-mappa.png"
    import imgSingapore from "../../assets/stati/img-asia/singapore-mappa.png"
    import imgSiria from "../../assets/stati/img-asia/siria-mappa.png"
    import imgSriLanka from "../../assets/stati/img-asia/sri-lanka-mappa.png"
    import imgTagikistan from "../../assets/stati/img-asia/tagikistan-mappa.png"
    import imgTaiwan from "../../assets/stati/img-asia/taiwan-mappa.png"
    import imgThailandia from "../../assets/stati/img-asia/thailandia-mappa.png"
    import imgTimorEst from "../../assets/stati/img-asia/timor-est-mappa.png"
    import imgTurkmenistan from "../../assets/stati/img-asia/turkmenistan-mappa.png"
    import imgUzbekistan from "../../assets/stati/img-asia/uzbekistan-mappa.png"
    import imgVietnam from "../../assets/stati/img-asia/vietnam-mappa.png"
    import imgYemen from "../../assets/stati/img-asia/yemen-mappa.png"

    //EUROPA
    import imgAlbania from "../../assets/stati/img-europa/albania-mappa.png"
    import imgAndorra from "../../assets/stati/img-europa/andorra-mappa.png"
    import imgAustria from "../../assets/stati/img-europa/austria-mappa.png"
    import imgBelarus from "../../assets/stati/img-europa/bielorussia-mappa.png"
    import imgBelgio from "../../assets/stati/img-europa/belgio-mappa.png"
    import imgBosniaEdErzegovina from "../../assets/stati/img-europa/bosnia-mappa.png"
    import imgBulgaria from "../../assets/stati/img-europa/bulgaria-mappa.png"
    import imgCipro from "../../assets/stati/img-europa/cipro-mappa.png"
    import imgCittaDelVaticano from "../../assets/stati/img-europa/citta-del-vaticano-mappa.png"
    import imgCroazia from "../../assets/stati/img-europa/croazia-mappa.png"
    import imgDanimarca from "../../assets/stati/img-europa/danimarca-mappa.png"
    import imgEstonia from "../../assets/stati/img-europa/estonia-mappa.png"
    import imgFinlandia from "../../assets/stati/img-europa/finlandia-mappa.png"
    import imgFrancia from "../../assets/stati/img-europa/francia-mappa.png"
    import imgGermania from "../../assets/stati/img-europa/germania-mappa.png"
    import imgGrecia from "../../assets/stati/img-europa/grecia-mappa.png"
    import imgIrlanda from "../../assets/stati/img-europa/irlanda-mappa.png"
    import imgIslanda from "../../assets/stati/img-europa/islanda-mappa.png"
    import imgItalia from "../../assets/stati/img-europa/italia-mappa.png"
    import imgLettonia from "../../assets/stati/img-europa/lettonia-mappa.png"
    import imgLiechtenstein from "../../assets/stati/img-europa/liechtenstein-mappa.png"
    import imgLituania from "../../assets/stati/img-europa/lituania-mappa.png"
    import imgLussemburgo from "../../assets/stati/img-europa/lussemburgo-mappa.png"
    import imgMacedonia from "../../assets/stati/img-europa/macedonia-mappa.png"
    import imgMalta from "../../assets/stati/img-europa/malta-mappa.png"
    import imgMoldova from "../../assets/stati/img-europa/moldavia-mappa.png"
    import imgMonaco from "../../assets/stati/img-europa/monaco-mappa.png"
    import imgMontenegro from "../../assets/stati/img-europa/montenegro-mappa.png"
    import imgNorvegia from "../../assets/stati/img-europa/norvegia-mappa.png"
    import imgPaesiBassi from "../../assets/stati/img-europa/paesi-bassi-mappa.png"
    import imgPolonia from "../../assets/stati/img-europa/polonia-mappa.png"
    import imgPortogallo from "../../assets/stati/img-europa/portogallo-mappa.png"
    import imgRegnoUnito from "../../assets/stati/img-europa/UK-mappa.png"
    import imgRepubblicaCeca from "../../assets/stati/img-europa/repubblica-ceca-mappa.png"
    import imgRomania from "../../assets/stati/img-europa/romania-mappa.png"
    import imgSanMarino from "../../assets/stati/img-europa/san-marino-mappa.png"
    import imgSerbia from "../../assets/stati/img-europa/serbia-mappa.png"
    import imgSlovacchia from "../../assets/stati/img-europa/slovacchia-mappa.png"
    import imgSlovenia from "../../assets/stati/img-europa/slovenia-mappa.png"
    import imgSpagna from "../../assets/stati/img-europa/spagna-mappa.png"
    import imgSvezia from "../../assets/stati/img-europa/svezia-mappa.png"
    import imgSvizzera from "../../assets/stati/img-europa/svizzera-mappa.png"
    import imgTurchia from "../../assets/stati/img-europa/turchia-mappa.png"
    import imgUcraina from "../../assets/stati/img-europa/ucraina-mappa.png"
    import imgUngheria from "../../assets/stati/img-europa/ungheria-mappa.png"
    
    //NORD AMERICA
    import imgAntiguaEBarbuda from "../../assets/stati/img-nord-america/antigua-e-barbuda-mappa.png"
    import imgBahamas from "../../assets/stati/img-nord-america/bahamas-mappa.png"
    import imgBarbados from "../../assets/stati/img-nord-america/barbados-mappa.png"
    import imgBelize from "../../assets/stati/img-nord-america/belize-mappa.png"
    import imgCanada from "../../assets/stati/img-nord-america/canada-mappa.png"
    import imgCostaRica from "../../assets/stati/img-nord-america/costa-rica-mappa.png"
    import imgCuba from "../../assets/stati/img-nord-america/cuba-mappa.png"
    import imgDominica from "../../assets/stati/img-nord-america/dominica-mappa.png"
    import imgElSalvador from "../../assets/stati/img-nord-america/el-salvador-mappa.png"
    import imgGiamaica from "../../assets/stati/img-nord-america/giamaica-mappa.png"
    import imgGrenada from "../../assets/stati/img-nord-america/grenada-mappa.png"
    import imgGuatemala from "../../assets/stati/img-nord-america/guatemala-mappa.png"
    import imgHaiti from "../../assets/stati/img-nord-america/haiti-mappa.png"
    import imgHonduras from "../../assets/stati/img-nord-america/honduras-mappa.png"
    import imgMessico from "../../assets/stati/img-nord-america/messico-mappa.png"
    import imgNicaragua from "../../assets/stati/img-nord-america/nicaragua-mappa.png"
    import imgPanama from "../../assets/stati/img-nord-america/panama-mappa.png"
    import imgRepubblicaDominicana from "../../assets/stati/img-nord-america/repubblica-dominicana-mappa.png"
    import imgSaintKittisENevis from "../../assets/stati/img-nord-america/saint-kittis-and-nevis-mappa.png"
    import imgSaintVincentEGrenadine from "../../assets/stati/img-nord-america/saint-vincent-e-grenadine-mappa.png"
    import imgSantaLucia from "../../assets/stati/img-nord-america/saint-lucia-mappa.png"
    import imgTrinidadETobago from "../../assets/stati/img-nord-america/trinidad-e-tobago-mappa.png"
    import imgUsa from "../../assets/stati/img-nord-america/usa-mappa.png"
  
    //OCEANIA
    import imgAustralia from "../../assets/stati/img-oceania/australia-mappa.png"
    import imgFigi from "../../assets/stati/img-oceania/figi-mappa.png"
    import imgIsoleMarshall from "../../assets/stati/img-oceania/isole-marshall-mappa.png"
    import imgIsoleSalomone from "../../assets/stati/img-oceania/isole-salomone-mappa.png"
    import imgKiribati from "../../assets/stati/img-oceania/kiribati-mappa.png"
    import imgMicronesia from "../../assets/stati/img-oceania/micronesia-mappa.png"
    import imgNauru from "../../assets/stati/img-oceania/nauru-mappa.png"
    import imgNuovaZelanda from "../../assets/stati/img-oceania/nuova-zelanda-mappa.png"
    import imgPalau from "../../assets/stati/img-oceania/palau-mappa.png"
    import imgPapuaNuovaGuinea from "../../assets/stati/img-oceania/papua-nuova-guinea-mappa.png"
    import imgSamoa from "../../assets/stati/img-oceania/samoa-mappa.png"
    import imgTonga from "../../assets/stati/img-oceania/tonga-mappa.png"
    import imgTuvalu from "../../assets/stati/img-oceania/tuvalu-mappa.png"
    import imgVanuatu from "../../assets/stati/img-oceania/vanuatu-mappa.png"

    //SUD AMERICA
    import imgArgentina from "../../assets/stati/img-sud-america/argentina-mappa.png"
    import imgBolivia from "../../assets/stati/img-sud-america/bolivia-mappa.png"
    import imgBrasile from "../../assets/stati/img-sud-america/brasile-mappa.png"
    import imgCile from "../../assets/stati/img-sud-america/cile-mappa.png"
    import imgColombia from "../../assets/stati/img-sud-america/colombia-mappa.png"
    import imgEcuador from "../../assets/stati/img-sud-america/ecuador-mappa.png"
    import imgGuyana from "../../assets/stati/img-sud-america/guyana-mappa.png"
    import imgParaguay from "../../assets/stati/img-sud-america/paraguay-mappa.png"
    import imgPeru from "../../assets/stati/img-sud-america/peru-mappa.png"
    import imgSuriname from "../../assets/stati/img-sud-america/suriname-mappa.png"
    import imgUruguay from "../../assets/stati/img-sud-america/uruguay-mappa.png"
    import imgVenezuela from "../../assets/stati/img-sud-america/venezuela-mappa.png"

    //USA
    import imgAlabama from "../../assets/stati/stati-usa/alabama-mappa.png"
    import imgAlaska from "../../assets/stati/stati-usa/alaska-mappa.png"
    import imgArizona from "../../assets/stati/stati-usa/arizona-mappa.png"
    import imgArkansas from "../../assets/stati/stati-usa/arkansas-mappa.png"
    import imgCalifornia from "../../assets/stati/stati-usa/california-mappa.png"
    import imgNordCarolina from "../../assets/stati/stati-usa/carolina-del-nord-mappa.png"
    import imgColorado from "../../assets/stati/stati-usa/colorado-mappa.png"
    import imgConnecticut from "../../assets/stati/stati-usa/connecticut-mappa.png"
    import imgNordDakota from "../../assets/stati/stati-usa/dakota-del-nord-mappa.png"
    import imgSudDakota from "../../assets/stati/stati-usa/dakota-del-sud-mappa.png"
    import imgDelaware from "../../assets/stati/stati-usa/delaware-mappa.png"
    import imgDistrettoDiColumbia from "../../assets/stati/stati-usa/distretto-di-columbia-mappa.jpg"
    import imgFlorida from "../../assets/stati/stati-usa/florida-mappa.png"
    import imgGeorgiaUsa from "../../assets/stati/stati-usa/georgia-mappa.png"
    import imgGuam from "../../assets/stati/stati-usa/guam-mappa.png"
    import imgHawaii from "../../assets/stati/stati-usa/hawaii-mappa.png"
    import imgIdaho from "../../assets/stati/stati-usa/idaho-mappa.png"
    import imgIllinois from "../../assets/stati/stati-usa/illinois-mappa.png"
    import imgIndiana from "../../assets/stati/stati-usa/indiana-mappa.png"
    import imgIowa from "../../assets/stati/stati-usa/iowa-mappa.png"
    import imgIsoleMarianne from "../../assets/stati/stati-usa/isole-marianne-mappa.png"
    import imgIsoleVerginiAmericane from "../../assets/stati/stati-usa/isole-vergini-americane-mappa.png"
    import imgKansas from "../../assets/stati/stati-usa/kansas-mappa.png"
    import imgKentucky from "../../assets/stati/stati-usa/kentucky-mappa.png"
    import imgLousiana from "../../assets/stati/stati-usa/lousiana-mappa.png"
    import imgMaine from "../../assets/stati/stati-usa/maine-mappa.png"
    import imgMaryland from "../../assets/stati/stati-usa/maryland-mappa.png"
    import imgMassachussets from "../../assets/stati/stati-usa/massachussets-mappa.png"
    import imgMichigan from "../../assets/stati/stati-usa/michigan-mappa.png"
    import imgMinnesota from "../../assets/stati/stati-usa/minnesota-mappa.png"
    import imgMississippi from "../../assets/stati/stati-usa/mississippi-mappa.png"
    import imgMissouri from "../../assets/stati/stati-usa/missouri-mappa.png"
    import imgMontana from "../../assets/stati/stati-usa/montana-mappa.png"
    import imgNebraska from "../../assets/stati/stati-usa/nebraska-mappa.png"
    import imgNevada from "../../assets/stati/stati-usa/nevada-mappa.png"
    import imgNewHampshire from "../../assets/stati/stati-usa/new-hampshire-mappa.png"
    import imgNewJersey from "../../assets/stati/stati-usa/new-jersey-mappa.png"
    import imgNewMessico from "../../assets/stati/stati-usa/new-messico-mappa.png"
    import imgNewYork from "../../assets/stati/stati-usa/new-york-mappa.png"
    import imgOhio from "../../assets/stati/stati-usa/ohio-mappa.png"
    import imgOklahoma from "../../assets/stati/stati-usa/oklahoma-mappa.png"
    import imgOregon from "../../assets/stati/stati-usa/oregon-mappa.png"
    import imgPennsylvania from "../../assets/stati/stati-usa/pennsylvania-mappa.png"
    import imgPortoRico from "../../assets/stati/stati-usa/porto-rico-mappa.png"
    import imgRhodeIsland from "../../assets/stati/stati-usa/rhode-island-mappa.png"
    import imgSamoaAmericane from "../../assets/stati/stati-usa/samoa-americane-mappa.png"
    import imgSudCarolina from "../../assets/stati/stati-usa/sud-carolina-mappa.png"
    import imgTennessee from "../../assets/stati/stati-usa/tennessee-mappa.png"
    import imgTexas from "../../assets/stati/stati-usa/texas-mappa.png"
    import imgUtah from "../../assets/stati/stati-usa/utah-mappa.png"
    import imgVermont from "../../assets/stati/stati-usa/vermont-mappa.png"
    import imgVirginia from "../../assets/stati/stati-usa/virginia-mappa.png"
    import imgVirginiaOccidentale from "../../assets/stati/stati-usa/virginia-occidentale-mappa.png"
    import imgWashington from "../../assets/stati/stati-usa/washington-mappa.png"
    import imgWisconsin from "../../assets/stati/stati-usa/wisconsin-mappa.png"
    import imgWyoming from "../../assets/stati/stati-usa/wyoming-mappa.png"
  //

  export default{
    name: 'IndovinaMappaView',
    //importiamo la lingua
    props: ['lingua'],

    data(){
      return{

        //IMPORTIAMO LE IMMAGINI DELLE MAPPE
        mappeEuropa: [
          {id:1, imgMappa:imgAlbania},
          {id:2, imgMappa:imgAndorra},
          {id:3, imgMappa:imgAustria},
          {id:4, imgMappa:imgBelgio},
          {id:5, imgMappa:imgBelarus},
          {id:6, imgMappa:imgBosniaEdErzegovina},
          {id:7, imgMappa:imgBulgaria},
          {id:8, imgMappa:imgCipro},
          {id:9, imgMappa:imgCittaDelVaticano},
          {id:10, imgMappa:imgCroazia},
          {id:11, imgMappa:imgDanimarca},
          {id:12, imgMappa:imgEstonia},
          {id:13, imgMappa:imgFinlandia},
          {id:14, imgMappa:imgFrancia},
          {id:15, imgMappa:imgGermania},
          {id:16, imgMappa:imgGrecia},
          {id:17, imgMappa:imgIrlanda},
          {id:18, imgMappa:imgIslanda},
          {id:19, imgMappa:imgItalia},
          {id:20, imgMappa:imgLettonia},
          {id:21, imgMappa:imgLiechtenstein},
          {id:22, imgMappa:imgLituania},
          {id:23, imgMappa:imgLussemburgo},
          {id:24, imgMappa:imgMacedonia},
          {id:25, imgMappa:imgMalta},
          {id:26, imgMappa:imgMoldova},
          {id:27, imgMappa:imgMonaco},
          {id:28, imgMappa:imgMontenegro},
          {id:29, imgMappa:imgNorvegia},
          {id:30, imgMappa:imgPaesiBassi},
          {id:31, imgMappa:imgPolonia},
          {id:32, imgMappa:imgPortogallo},
          {id:33, imgMappa:imgRegnoUnito},
          {id:34, imgMappa:imgRepubblicaCeca},
          {id:35, imgMappa:imgRomania},
          {id:36, imgMappa:imgSanMarino},
          {id:37, imgMappa:imgSerbia},
          {id:38, imgMappa:imgSlovacchia},
          {id:39, imgMappa:imgSlovenia},
          {id:40, imgMappa:imgSpagna},
          {id:41, imgMappa:imgSvezia},
          {id:42, imgMappa:imgSvizzera},
          {id:43, imgMappa:imgTurchia},
          {id:44, imgMappa:imgUcraina},
          {id:45, imgMappa:imgUngheria},
        ],
        mappeAsia: [
          {id:1, imgMappa:imgAfghanistan},
          {id:2, imgMappa:imgArabiaSaudita},
          {id:3, imgMappa:imgArmenia},
          {id:4, imgMappa:imgAzeraigian},
          {id:5, imgMappa:imgBahrein},
          {id:6, imgMappa:imgBangladesh},
          {id:7, imgMappa:imgBirmania},
          {id:8, imgMappa:imgBhutan},
          {id:9, imgMappa:imgBrunei},
          {id:10, imgMappa:imgCambogia},
          {id:11, imgMappa:imgCina},
          {id:12, imgMappa:imgCoreaDelNord},
          {id:13, imgMappa:imgCoreaDelSud},
          {id:14, imgMappa:imgEmiratiArabiUniti},
          {id:15, imgMappa:imgFilippine},
          {id:16, imgMappa:imgGeorgia},
          {id:17, imgMappa:imgGiappone},
          {id:18, imgMappa:imgGiordania},
          {id:19, imgMappa:imgIndia},
          {id:20, imgMappa:imgIndonesia},
          {id:21, imgMappa:imgIran},
          {id:22, imgMappa:imgIraq},
          {id:23, imgMappa:imgIsraele},
          {id:24, imgMappa:imgKazakistan},
          {id:25, imgMappa:imgKirghizistan},
          {id:26, imgMappa:imgKuwait},
          {id:27, imgMappa:imgLaos},
          {id:28, imgMappa:imgLibano},
          {id:29, imgMappa:imgMaldive},
          {id:30, imgMappa:imgMalaysia},
          {id:31, imgMappa:imgMongolia},
          {id:32, imgMappa:imgNepal},
          {id:33, imgMappa:imgOman},
          {id:34, imgMappa:imgPakistan},
          {id:35, imgMappa:imgPalestina},
          {id:36, imgMappa:imgQatar},
          {id:37, imgMappa:imgRussia},
          {id:38, imgMappa:imgSingapore},
          {id:39, imgMappa:imgSiria},
          {id:40, imgMappa:imgSriLanka},
          {id:41, imgMappa:imgTagikistan},
          {id:42, imgMappa:imgTaiwan},
          {id:43, imgMappa:imgThailandia},
          {id:44, imgMappa:imgTimorEst},
          {id:45, imgMappa:imgTurkmenistan},
          {id:46, imgMappa:imgUzbekistan},
          {id:47, imgMappa:imgVietnam},
          {id:48, imgMappa:imgYemen},
        ],
        mappeAfrica: [
          {id:1, imgMappa:imgAlgeria},
          {id:2, imgMappa:imgAngola},
          {id:3, imgMappa:imgBenin},
          {id:4, imgMappa:imgBotswana},
          {id:5, imgMappa:imgBurkinaFaso},
          {id:6, imgMappa:imgBurundi},
          {id:7, imgMappa:imgCamerun},
          {id:8, imgMappa:imgCapoVerde},
          {id:9, imgMappa:imgRepubblicaCentrafricana},
          {id:10, imgMappa:imgCiad},
          {id:11, imgMappa:imgComore},
          {id:12, imgMappa:imgCostaDAvorio},
          {id:13, imgMappa:imgRepubblicaDemocraticaDelCongo},
          {id:14, imgMappa:imgCongo},
          {id:15, imgMappa:imgEgitto},
          {id:16, imgMappa:imgEritrea},
          {id:17, imgMappa:imgEtiopia},
          {id:18, imgMappa:imgGabon},
          {id:19, imgMappa:imgGambia},
          {id:20, imgMappa:imgGhana},
          {id:21, imgMappa:imgGibuti},
          {id:22, imgMappa:imgGuinea},
          {id:23, imgMappa:imgGuineaBissau},
          {id:24, imgMappa:imgGuineaEquatoriale},
          {id:25, imgMappa:imgKenia},
          {id:26, imgMappa:imgLesotho},
          {id:27, imgMappa:imgLiberia},
          {id:28, imgMappa:imgLibia},
          {id:29, imgMappa:imgMadagascar},
          {id:30, imgMappa:imgMalawi},
          {id:31, imgMappa:imgMali},
          {id:32, imgMappa:imgMauritania},
          {id:33, imgMappa:imgMauritius},
          {id:34, imgMappa:imgMarocco},
          {id:35, imgMappa:imgMozambico},
          {id:36, imgMappa:imgNamibia},
          {id:37, imgMappa:imgNiger},
          {id:38, imgMappa:imgNigeria},
          {id:39, imgMappa:imgRuanda},
          {id:40, imgMappa:imgSaoTomeEPrincipe},
          {id:41, imgMappa:imgSenegal},
          {id:42, imgMappa:imgSeychelles},
          {id:43, imgMappa:imgSierraLeone},
          {id:44, imgMappa:imgSomalia},
          {id:45, imgMappa:imgSudAfrica},
          {id:46, imgMappa:imgSudan},
          {id:47, imgMappa:imgSudanDelSud},
          {id:48, imgMappa:imgEswatini},
          {id:49, imgMappa:imgTanzania},
          {id:50, imgMappa:imgTogo},
          {id:51, imgMappa:imgTunisia},
          {id:52, imgMappa:imgUganda},
          {id:53, imgMappa:imgZambia},
          {id:54, imgMappa:imgZimbabwe},
        ],
        mappeNordAmerica: [
          {id:1, imgMappa:imgAntiguaEBarbuda},
          {id:2, imgMappa:imgBahamas},
          {id:3, imgMappa:imgBarbados},
          {id:4, imgMappa:imgBelize},
          {id:5, imgMappa:imgCanada},
          {id:6, imgMappa:imgCostaRica},
          {id:7, imgMappa:imgCuba},
          {id:8, imgMappa:imgDominica},
          {id:9, imgMappa:imgElSalvador},
          {id:10, imgMappa:imgGiamaica},
          {id:11, imgMappa:imgGrenada},
          {id:12, imgMappa:imgGuatemala},
          {id:13, imgMappa:imgHaiti},
          {id:14, imgMappa:imgHonduras},
          {id:15, imgMappa:imgMessico},
          {id:16, imgMappa:imgNicaragua},
          {id:17, imgMappa:imgPanama},
          {id:18, imgMappa:imgRepubblicaDominicana},
          {id:19, imgMappa:imgSaintKittisENevis},
          {id:20, imgMappa:imgSaintVincentEGrenadine},
          {id:21, imgMappa:imgSantaLucia},
          {id:22, imgMappa:imgTrinidadETobago},
          {id:23, imgMappa:imgUsa}
        ],
        mappeOceania: [
          {id:1, imgMappa:imgAustralia},
          {id:2, imgMappa:imgNuovaZelanda},
          {id:3, imgMappa:imgPapuaNuovaGuinea},
          {id:4, imgMappa:imgFigi},
          {id:5, imgMappa:imgIsoleSalomone},
          {id:6, imgMappa:imgVanuatu},
          {id:7, imgMappa:imgMicronesia},
          {id:8, imgMappa:imgKiribati},
          {id:9, imgMappa:imgIsoleMarshall},
          {id:10, imgMappa:imgPalau},
          {id:11, imgMappa:imgNauru},
          {id:12, imgMappa:imgSamoa},
          {id:13, imgMappa:imgTonga},
          {id:14, imgMappa:imgTuvalu}
        ],
        mappeSudAmerica: [
          {id:1, imgMappa:imgArgentina},
          {id:2, imgMappa:imgBolivia},
          {id:3, imgMappa:imgBrasile},
          {id:4, imgMappa:imgCile},
          {id:5, imgMappa:imgColombia},
          {id:6, imgMappa:imgEcuador},
          {id:7, imgMappa:imgGuyana},
          {id:8, imgMappa:imgParaguay},
          {id:9, imgMappa:imgPeru},
          {id:10, imgMappa:imgSuriname},
          {id:11, imgMappa:imgUruguay},
          {id:12, imgMappa:imgVenezuela}
        ],
        mappeUSA: [
          {id:1, imgMappa:imgCalifornia},
          {id:2, imgMappa:imgTexas},
          {id:3, imgMappa:imgFlorida},
          {id:4, imgMappa:imgNewYork},
          {id:5, imgMappa:imgPennsylvania},
          {id:6, imgMappa:imgIllinois},
          {id:7, imgMappa:imgOhio},
          {id:8, imgMappa:imgGeorgiaUsa},
          {id:9, imgMappa:imgNordCarolina},
          {id:10, imgMappa:imgMichigan},
          {id:11, imgMappa:imgNewJersey},
          {id:12, imgMappa:imgVirginia},
          {id:13, imgMappa:imgWashington},
          {id:14, imgMappa:imgArizona},
          {id:15, imgMappa:imgMassachussets},
          {id:16, imgMappa:imgTennessee},
          {id:17, imgMappa:imgIndiana},
          {id:18, imgMappa:imgMaryland},
          {id:19, imgMappa:imgMissouri},
          {id:20, imgMappa:imgWisconsin},
          {id:21, imgMappa:imgColorado},
          {id:22, imgMappa:imgMinnesota},
          {id:23, imgMappa:imgSudCarolina},
          {id:24, imgMappa:imgAlabama},
          {id:25, imgMappa:imgLousiana},
          {id:26, imgMappa:imgKentucky},
          {id:27, imgMappa:imgOregon},
          {id:28, imgMappa:imgOklahoma},
          {id:29, imgMappa:imgConnecticut},
          {id:30, imgMappa:imgUtah},
          {id:31, imgMappa:imgIowa},
          {id:32, imgMappa:imgNevada},
          {id:33, imgMappa:imgArkansas},
          {id:34, imgMappa:imgMississippi},
          {id:35, imgMappa:imgKansas},
          {id:36, imgMappa:imgNewMessico},
          {id:37, imgMappa:imgNebraska},
          {id:38, imgMappa:imgIdaho},
          {id:39, imgMappa:imgVirginiaOccidentale},
          {id:40, imgMappa:imgHawaii},
          {id:41, imgMappa:imgNewHampshire},
          {id:42, imgMappa:imgMaine},
          {id:43, imgMappa:imgRhodeIsland},
          {id:44, imgMappa:imgMontana},
          {id:45, imgMappa:imgDelaware},
          {id:46, imgMappa:imgSudDakota},
          {id:47, imgMappa:imgNordDakota},
          {id:48, imgMappa:imgAlaska},
          {id:49, imgMappa:imgVermont},
          {id:50, imgMappa:imgWyoming},
          {id:51, imgMappa:imgDistrettoDiColumbia},
          {id:52, imgMappa:imgPortoRico},
          {id:53, imgMappa:imgGuam},
          {id:54, imgMappa:imgIsoleVerginiAmericane},
          {id:55, imgMappa:imgSamoaAmericane},
          {id:56, imgMappa:imgIsoleMarianne},
        ],

        //LINGUE
        inglese: "EN",
        italiano: "IT",
        finlandese: "FI",

        //per intrappolare il parametro delle mappe dalla rotta
        bandiere: this.$route.params.bandiere,
        
        //DETERMINAZIONE del CONTINENTE
          //CICLARE i dati di ogni CONTINENTE
            //ITALIANO
            africaIT: statiItaliano.AFRICA,
            asiaIT: statiItaliano.ASIA,
            europaIT: statiItaliano.EUROPA,
            nordAmericaIT: statiItaliano.NORD_AMERICA,
            oceaniaIT: statiItaliano.OCEANIA,
            sudAmericaIT: statiItaliano.SUD_AMERICA,
            USAIT: statiItaliano.USA,
            //INGLESE
            africaEN: statiInglese.AFRICA,
            asiaEN: statiInglese.ASIA,
            europaEN: statiInglese.EUROPA,
            nordAmericaEN: statiInglese.NORD_AMERICA,
            oceaniaEN: statiInglese.OCEANIA,
            sudAmericaEN: statiInglese.SUD_AMERICA,
            USAEN: statiInglese.USA,
            //FINLANDESE
            africaFI: statiFinlandese.AFRICA,
            asiaFI: statiFinlandese.ASIA,
            europaFI: statiFinlandese.EUROPA,
            nordAmericaFI: statiFinlandese.NORD_AMERICA,
            oceaniaFI: statiFinlandese.OCEANIA,
            sudAmericaFI: statiFinlandese.SUD_AMERICA,
            USAFI: statiFinlandese.USA,
          //intrappolare il parametro del continente dal file json
          AFRICA: statiItaliano.AFRICA[0].continente,
          ASIA: statiItaliano.ASIA[0].continente,
          EUROPA: statiItaliano.EUROPA[0].continente,
          NORD_AMERICA: statiItaliano.NORD_AMERICA[0].continente,
          OCEANIA: statiItaliano.OCEANIA[0].continente,
          SUD_AMERICA: statiItaliano.SUD_AMERICA[0].continente,
          USA: statiItaliano.USA[0].continente,
          //per intrappolare il parametro del continente dalle rotte
          continente: this.$route.params.continente,
        //

        //variabile per far vedere una sola card alla volta
        a: 0,

        //variabile per mostrare la pagina finale
        final_page: 0,

        //questa variabile racchiuderà il numero di stati del continente che stiamo usando
        n_stati: 0,

        //array vuoto per inserire gli id degli stati usciti.
        check_state_array_vuoto: [], 

        //PER SALVARE IL NUMERO CASUALE
        numero_casuale: 0,

        //PUNTEGGIO
          //variabile per mostrare il punteggio
          score: 0,
          //variabile per mostrare il punteggio totale
          total: 0,
          //per dire se è giusto o sbagliato
          guess: "",
          //varibile per tenere il conto di quante risposte sono giusto
          conoscenza: 0,
          //calcola la percentuale di risposte giuste
          percentuale: 0,
        //

        //stato presa dall'input
        tempStato: "",

        //stato presa dall'oggetto
        stato: "",
        Stato: "",
        STATO: "",
        stato_speciale_1: "",
        stato_speciale_2: "",
        stato_speciale_3: "",
        stato_speciale_4: "",
        stato_speciale_5: "",
        stato_speciale_6: "",
        stato_speciale_7: "",
        stato_speciale_8: "",
        stato_speciale_9: "",

        //controlla/prossimo stato
        cp: true,

        //per avviso nel caso in cui non inserisco niente
        passa: false,
        
      }
    },

    //metodi - funzioni
    methods: {

      //CONTROLLA se la stato inserito è corretto e segna il punteggio
      checkState(){

        //fa partire la funzione solo se il campo dell'imput contiene qualcosa
        if(this.tempStato != ''){

          //controlla se la Stato inserito è uguale a tempStato (che è uguale alla Stato dello stato in questione)
          if(this.tempStato == this.stato || this.tempStato == this.Stato || this.tempStato == this.STATO || this.tempStato == this.stato_speciale_1 || this.tempStato == this.stato_speciale_2 || this.tempStato == this.stato_speciale_3 || this.tempStato == this.stato_speciale_4 || this.tempStato == this.stato_speciale_5 || this.tempStato == this.stato_speciale_6 || this.tempStato == this.stato_speciale_7 || this.tempStato == this.stato_speciale_8 || this.tempStato == this.stato_speciale_9){

            this.guess = true
          }
          else{
            this.guess = false
          } 
          
          //aggiunge i punteggi 
          if(this.guess == true){
            this.score = this.score +10
            this.conoscenza = this.conoscenza +1
          }
          else{
            this.score = this.score - 5
          }

          //cambia la variabile pc, quindi porta alla schermata per vedere la soluzione che comprende il pulsante per cambiare stato
          this.cp = !this.cp

          //si assicura che l'avviso di input vuoto non venga visualizzato
          this.passa = false
        }
        //avviso in cui l'imput sia vuoto
        else{
          this.passa = true
        }
    
      },

      //CAMBIA STATO
      changeState(){

        //per cambiare il valore di a e far scorrere gli stati 
        this.a = this.a + 1

        //cose che cambiano in base al continente assegnato
        if(this.continente == this.ASIA){
          //per dare uno Stato a stato, Stato e STATO 
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.ASIA[this.id_casuale].Stato
            this.stato = statiItaliano.ASIA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.ASIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.ASIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.ASIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.ASIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.ASIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.ASIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.ASIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.ASIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.ASIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.ASIA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.ASIA[this.id_casuale].Stato
            this.stato = statiInglese.ASIA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.ASIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.ASIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.ASIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.ASIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.ASIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.ASIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.ASIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.ASIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.ASIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.ASIA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato= statiFinlandese.ASIA[this.id_casuale].Stato
            this.stato= statiFinlandese.ASIA[this.id_casuale].Stato.toLowerCase()
            this.STATO= statiFinlandese.ASIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.ASIA.length - 1
        }
        else if(this.continente == this.AFRICA){
          //per dare uno stato a stato, Stato STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.AFRICA[this.id_casuale].Stato
            this.stato = statiItaliano.AFRICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.AFRICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.AFRICA[this.id_casuale].Stato
            this.Stato = statiInglese.AFRICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.AFRICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.AFRICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.AFRICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.AFRICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.AFRICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.AFRICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.AFRICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.AFRICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.AFRICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.AFRICA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.AFRICA[this.id_casuale].Stato
            this.stato = statiFinlandese.AFRICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiFinlandese.AFRICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.AFRICA.length - 1
        }
        else if(this.continente == this.EUROPA){
          //per dare uno stato a stato, Stato e STATO 
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.EUROPA[this.id_casuale].Stato
            this.stato = statiItaliano.EUROPA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.EUROPA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.EUROPA[this.id_casuale].Stato
            this.stato = statiInglese.EUROPA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.EUROPA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.EUROPA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.EUROPA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.EUROPA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.EUROPA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.EUROPA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.EUROPA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.EUROPA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.EUROPA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.EUROPA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.EUROPA[this.id_casuale].Stato
            this.stato = statiFinlandese.EUROPA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiFinlandese.EUROPA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.EUROPA.length - 1
        }
        else if(this.continente == this.NORD_AMERICA){
          //per dare uno stato a stato, Stato e STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.NORD_AMERICA[this.id_casuale].Stato
            this.stato = statiItaliano.NORD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.NORD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.NORD_AMERICA[this.id_casuale].Stato
            this.stato = statiInglese.NORD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.NORD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.NORD_AMERICA[this.id_casuale].Stato
            this.stato = statiFinlandese.NORD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiFinlandese.NORD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.NORD_AMERICA.length - 1
        }
        else if(this.continente == this.OCEANIA){
          //per dare uno stato a stato, Stato e STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.OCEANIA[this.id_casuale].Stato
            this.stato = statiItaliano.OCEANIA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.OCEANIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.OCEANIA[this.id_casuale].Stato
            this.stato = statiInglese.OCEANIA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.OCEANIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.OCEANIA[this.id_casuale].Stato
            this.stato = statiFinlandese.OCEANIA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiFinlandese.OCEANIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.OCEANIA.length - 1
        }
        else if(this.continente == this.SUD_AMERICA){
          //per dare uno stato a stato, Stato e STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.SUD_AMERICA[this.id_casuale].Stato
            this.stato = statiItaliano.SUD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.SUD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.SUD_AMERICA[this.id_casuale].Stato
            this.stato = statiInglese.SUD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.SUD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.SUD_AMERICA[this.id_casuale].Stato
            this.stato = statiFinlandese.SUD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiFinlandese.SUD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.SUD_AMERICA.length - 1
        }
        else if(this.continente == this.USA){
          //per dare uno stato a stato, Stato e STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.USA[this.id_casuale].Stato
            this.stato = statiItaliano.USA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.USA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.USA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.USA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.USA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.USA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.USA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.USA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.USA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.USA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.USA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.USA[this.id_casuale].Stato
            this.stato = statiInglese.USA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.USA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.USA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.USA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.USA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.USA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.USA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.USA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.USA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.USA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.USA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.USA[this.id_casuale].Stato
            this.stato = statiFinlandese.USA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiFinlandese.USA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.USA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.USA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.USA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.USA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.USA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.USA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.USA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.USA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.USA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.USA.length - 1
        }
        
        //cambia la variabile pc, quindi non fa vedere la schermata della soluzione fino a quando non si cliccherà di nuovo il pulsante controlla
        if(this.a > 1){
          this.cp = !this.cp
        }
        
        //salva temporaneamente la capitale inserita dall'utente
        this.tempStato = ""

      },

      //NUMERO CASUALE
      numeroCasuale(){

        //cose che cambiano in base al continente assegnato
        if(this.continente == this.ASIA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.ASIA.length - 1
        }
        else if(this.continente == this.AFRICA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.AFRICA.length - 1
        }
        else if(this.continente == this.EUROPA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.EUROPA.length - 1
        }
        else if(this.continente == this.NORD_AMERICA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.NORD_AMERICA.length - 1
        }
        else if(this.continente == this.OCEANIA){  
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.OCEANIA.length - 1
        }
        else if(this.continente == this.SUD_AMERICA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.SUD_AMERICA.length - 1
        }
        else if(this.continente == this.USA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.USA.length - 1
        }
        
        //mi da un numero casuale tra 1 e la lunghezza dell'array con gli stati (6)
        this.numero_casuale = Math.ceil(Math.random() * ( (this.n_stati) - (1-1)) + (1-1));

        //se l'array dove abbiamo inserito gli id degli stati usciti, include il numero casuale appena uscito allora rifacciamo partire la funzione
        if(this.check_state_array_vuoto.includes(this.numero_casuale)){
          console.log('è gia presente ritira')
          return this.numeroCasuale();
        }
        //se il numero uscito non è contenuto all'interno dell'array allora lo andiamo ad iserire
        else{
          
          //inserisco l'id dello stato estratto nell'array check_state
          this.check_state_array_vuoto.push(this.numero_casuale);
          //console.log(this.check_state_array_vuoto.sort());
          this.id_casuale = this.numero_casuale

        }

      },

      //FINIRE GIOCO
      endGame(){  
        this.stato = ""
        this.Stato = ""
        this.STATO = ""
        this.stato_speciale_1 = ""
        this.stato_speciale_2 = ""
        this.stato_speciale_3 = ""
        this.stato_speciale_4 = ""
        this.stato_speciale_5 = ""
        this.stato_speciale_6 = ""
        this.stato_speciale_7 = ""
        this.stato_speciale_8 = ""
        this.stato_speciale_9 = ""
        this.guess = ""
        this.cp = true
        this.a = this.a + 1
        //per dare un valore a final_page in modo da mostrare solo la card finale
        this.final_page = this.n_stati + 1
        //per salvare il punteggio totale
        this.total = this.n_stati * 10
        //per dare il valore ppercentuale delle risposte giuste
        this.percentuale = ((this.conoscenza/this.n_stati)*100).toFixed(0)
      },

      //RINCOMINCIA
      rincomincia(){
        this.a = 0
        this.score = 0
        this.conoscenza = 0
        this.final_page = 0
        this.total = 0
        //ripulisce l'array per controllare gli stati
        this.check_state_array_vuoto = [];
      },


      //funzione per evitare l'aggiornamento della pagina quando si cambia stato
      preventEvent(e){
        e.preventDefault();
      }
    },
    
  }

</script>

<style>

/* IMMAGINE DI SFONDO E CENTRAMENTO CONTENUTO */
body{
  margin:0;
  padding:0;
  height: max-content;
}
.img-sfondo-indovina{
  background-image: url("../../assets/carta-da-parti-mondoblu-spazio-531.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.centramento-contenuto{
  padding-top: 7%;
  padding-bottom: 7%;
  padding-left: 5%;
  padding-right: 5%;

}

/* CARD TITOLI */
.card-titoli{ 
  background-color: rgba(189, 220, 249, 0.69);
  border: 5px solid black;
  border-radius: 70px;
  filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
  display: flex;
  justify-content: center;
}

/* PARTE GIOCABILE */
  
  /* PULSANTE INIZIA */
  .btn-custom-inizia{
    background-color: transparent;
    color: rgba(189, 220, 249, 0.69);
    border: 2px solid rgba(189, 220, 249, 0.69);
    backdrop-filter: blur(10px);
    font-size: 30px;
    border-radius: 100px;
    transition: 0.2s;
    min-width:max-content;
    font-weight: bold;
  }
  .btn-custom-inizia:hover{
    backdrop-filter: blur(10px);
    background-color: rgba(189, 220, 249, 0.69);
    border: 2px solid black;
    color: black; 
  }
  .sperim{
    min-width:max-content;
  }

  /* CARD GIOCO */
  .card-gioco{
    color:  rgba(189, 220, 249, 0.69);
    border: 5px solid rgba(189, 220, 249, 0.69);
    border-radius: 60px;
    filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
    backdrop-filter: blur(10px);
    background-color: rgb(0,0,0,0.6);
    width: 100%;
    height: 570px;
  }

  /* dimensioni immagine mappa */
  .mappa-size{
    width: 100px;
    border-radius: 50px;
    border: 5px solid rgba(189, 220, 249, 0.69);
  }
  

  /* INPUT */
  .input-custom{
    background-color: transparent;
    border:none;
    border-bottom: 2px solid rgba(189, 220, 249, 0.69);;
    color:rgba(189, 220, 249,0.8);
    width:80%;
    text-align: center;
  }
  .input-custom::placeholder{
    color:rgba(189, 220, 249, 0.4);  
  }
  .input-custom:focus-visible{
    outline:none;
  }

  /* PULSANTI CONTROLLA, PROSSIMO STATO, FINE e RICOMINCIA */
  .btn-custom{
    background-color: transparent;
    color: rgba(189, 220, 249, 0.69);
    border: 2px solid rgba(189, 220, 249, 0.69);
    backdrop-filter: blur(10px);
    font-size: 20px;
    border-radius: 100px;
    transition: 0.2s;
    min-width:max-content;
    padding-right: 3%;
    padding-left: 3%;
  }
  .btn-custom:hover{
    backdrop-filter: blur(10px);
    background-color: rgba(189, 220, 249, 0.69);
    border: 2px solid black;
    color: black; 
  }

  /* AVVISO di CONTROLLO */
  .advertise-custom{
    background-color: rgba(252, 107, 107, 0.45);
    backdrop-filter: blur(10px);
    padding-bottom: 5px;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 50px;
    border: 2px solid rgba(189, 220, 249, 0.69);
  }

  /* scritta soluzione */
  .orientamento-soluzione{
    display: block;
    justify-content: center;
  }

  /* AVVISO RISPOSTA CORRETTA O SBAGLIATA */
  .correct-answer{
    background-color: rgba(13, 255, 0, 0.284); 
    padding: 5px 10px 5px 10px;
  }
  .wrong-answer{
    background-color: rgba(254, 6, 2, 0.311); 
    padding: 5px 10px 5px 10px;
  }

  /* bordo punteggi */
  .score-border{
    border: 2px solid rgba(189, 220, 249, 0.69);
    max-width: max-content;
    border-radius: 50px;
  }
  .score-margin{
    margin: 10px 15px 10px 15px;
  }
/*  */


@media (min-width: 270px) { 
  .card-gioco{
  height: 530px;
  }
}
@media (min-width: 310px) {  
  .margin-title-gioco{
  padding-top: 23%;
  }
  .mappa-size{
    width: 150px;
  }
}
@media (min-width: 400px) { 
  .card-gioco{
    width: 80%;
  }
  .margin-title-gioco{
    padding-top: 20%;
  }
  .card-titoli{
    border-radius: 100px;
  }
}
@media (min-width: 470px) { 
  .margin-title-gioco{
    padding-top: 15%;
  }
  .mappa-size{
    width: 200px;
  }
}
@media (min-width: 520px) { 
  .card-gioco{
    height: 430px;
    border-radius: 100px;
  }
  .centramento-contenuto{
    padding-left: 10%;
    padding-right: 10%;
  }
  .advertise-custom{
    border-radius: 100px;
  }
}
@media (min-width: 570px) { 
  .margin-title-gioco{
    padding-top: 10%;
  }
}
@media (min-width: 600px) { 
  .centramento-contenuto{
    padding-left: 5%;
    padding-right: 5%;
  }
  .orientamento-soluzione{
    display: flex;
  }
}
@media (min-width: 770px) { 
  .centramento-contenuto{
    padding-left: 10%;
    padding-right: 10%;
  } 
  .margin-title-gioco{
    padding-top: 8%;
  }
  .card-titoli{
    border-radius: 80px;
  }
}
@media (min-width: 992px) { 
  .margin-title-gioco{
    padding-top: 3%;
  }
  .card-titoli{
    border-radius: 100px;
  }
}
@media (min-width: 1000px) { 
  .centramento-contenuto{
    padding-left: 15%;
    padding-right: 15%;
  }
}


</style>