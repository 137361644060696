<template>

    <body class="img-sfondo-europa position-sticky">

        <!-- TITOLO -->
        <div v-if="this.lingua == 'IT'" class="margin-title-c d-flex justify-content-center">
            <h1 class="continente-title text-center">ASIA</h1>
        </div>
        <div v-else-if="this.lingua == 'EN'" class="margin-title-c d-flex justify-content-center">
            <h1 class="continente-title text-center">ASIA</h1>
        </div>
        <div v-else-if="this.lingua == 'FI'" class="margin-title-c d-flex justify-content-center">
            <h1 class="continente-title text-center">AASIA</h1>
        </div>


        <div class="container m-footer mt-4">       
            <div class="row larghezza-grande">

                <!-- CARD GIOCO -->
                <div class="col me-3 ms-3 mb-5">
                    <div class="row card-custom justify-content-center align-items-center">

                        <!-- titolo card -->
                        <div v-if="this.lingua == 'IT'" class="pt-5 d-flex justify-content-center align-items-center">
                            <h2 class="colore-gioco">GIOCO</h2>
                        </div>
                        <div v-else-if="this.lingua == 'EN'" class="pt-5 d-flex justify-content-center align-items-center">
                            <h2 class="colore-gioco">GAME</h2>
                        </div>
                        <div v-else-if="this.lingua == 'FI'" class="pt-5 d-flex justify-content-center align-items-center">
                            <h2 class="colore-gioco">PELI</h2>
                        </div>

                        <!-- giochi card -->
                        <div class="pt-3 pb-5 d-flex justify-content-center">
                            <ul>

                                <!-- capitale da stato -->
                                <li class="d-flex align-items-center text-center mt-3">
                                    <router-link v-if="this.lingua == 'IT'" class="me-3 sottolineamento" :to="{ name: 'indovinaCapitale', params:{continente: continente} }">Indovina le capitali dallo stato</router-link>
                                    <router-link v-else-if="this.lingua == 'EN'" class="me-3 sottolineamento" :to="{ name: 'indovinaCapitale', params:{continente: continente} }">Guess the capitals by country</router-link>
                                    <router-link v-else-if="this.lingua == 'FI'" class="me-3 sottolineamento" :to="{ name: 'indovinaCapitale', params:{continente: continente} }">Arvaa pääkaupungit maittain</router-link>
                                    <router-link class="freccia text-ceter" :to="{ name: 'indovinaCapitale', params:{continente: continente} }">🡱</router-link>
                                </li>

                                <!-- stato da capitale -->
                                <li class="d-flex align-items-center text-center mt-3">
                                    <router-link v-if="this.lingua == 'IT'" class="me-3 sottolineamento" :to="{ name: 'indovinaStato', params:{continente: continente} }">Indovina lo stato dalla capitale</router-link> 
                                    <router-link v-else-if="this.lingua == 'EN'" class="me-3 sottolineamento" :to="{ name: 'indovinaStato', params:{continente: continente} }">Guess the country from the capital</router-link> 
                                    <router-link v-else-if="this.lingua == 'FI'" class="me-3 sottolineamento" :to="{ name: 'indovinaStato', params:{continente: continente} }">Arvaa maa pääkaupungista</router-link> 
                                    <router-link class="freccia text-ceter" :to="{ name: 'indovinaStato', params:{continente: continente} }">🡱</router-link>
                                </li>

                                <!-- stato da bandiera -->
                                <li class="d-flex align-items-center text-center mt-3">
                                    <router-link v-if="this.lingua == 'IT'" class="me-3 sottolineamento" :to="{ name: 'indovinaBandiera', params:{continente: continente} }">Indovina lo stato dalla bandiera</router-link>
                                    <router-link v-else-if="this.lingua == 'EN'" class="me-3 sottolineamento" :to="{ name: 'indovinaBandiera', params:{continente: continente} }">Guess the country from the flag</router-link>
                                    <router-link v-else-if="this.lingua == 'FI'" class="me-3 sottolineamento" :to="{ name: 'indovinaBandiera', params:{continente: continente} }">Arvaa maa lipusta</router-link> 
                                    <router-link class="freccia text-ceter" :to="{ name: 'indovinaBandiera', params:{continente: continente} }">🡱</router-link>
                                </li>  

                                <!-- stato da mappa -->
                                <li class="d-flex align-items-center text-center mt-3">
                                    <router-link v-if="this.lingua == 'IT'" class="me-3 sottolineamento" :to="{ name: 'indovinaMappa', params:{continente: continente} }">Indovina lo stato dalla mappa</router-link> 
                                    <router-link v-else-if="this.lingua == 'EN'" class="me-3 sottolineamento" :to="{ name: 'indovinaMappa', params:{continente: continente} }">Guess the country from the map</router-link> 
                                    <router-link v-else-if="this.lingua == 'FI'" class="me-3 sottolineamento" :to="{ name: 'indovinaMappa', params:{continente: continente} }">Arvaa maa kartalta</router-link> 
                                    <router-link class="freccia text-ceter" :to="{ name: 'indovinaMappa', params:{continente: continente} }">🡱</router-link>
                                </li>    

                            </ul>
                        </div>

                    </div>
                </div>

                <!-- card immagine -->
                <div class="col me-3 ms-3 mb-5">
                    <div class="row altezza-img">
                        <div class="col d-flex card-custom justify-content-center align-items-center">
                            <img class="img-europe-custom" src="../assets/stati/asia-mappa.png" alt="immagine dell'europa di wikipwdia">
                        </div>   
                    </div>                   
                </div>
                
            </div>
        </div> 
    </body>   

</template>

<script>

    export default{
        name: 'AsiaView',
        //importiamo la lingua
        props: ['lingua'],

        data(){
            return{
                continente: "ASIA",
            }
        }
    }

</script>

<style>

/* IMMAGINE DI SFONDO E CENTRAMENTO CONTENUTO */
body{
  margin:0;
  padding:0;
  height: max-content;
}
.img-sfondo-europa{
  background-image: url("../assets/map_world_sfondo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.m-footer{
  padding-bottom: 8%;
}
.larghezza-grande{
    width: 100%;
    padding-right: 10px;
    flex-direction: column;
   
}

/* TITOLO */
.margin-title-c{
  padding-top: 28%;
  padding-bottom: 5%;
}
.continente-title{
    color: black;
    background-color: rgba(189, 220, 249, 0.69);
    backdrop-filter: blur(10px);
    border: 5px solid black;
    border-radius: 100px;
    font-weight: bold;
    font-size: 20px;
    width: max-content;
    filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
    padding: 10px 40px 10px 40px;
}

/* CARD GIOCHI */
.colore-gioco{
    color: rgba(189, 220, 249, 0.69);
    border-bottom: 2px solid rgba(189, 220, 249, 0.69);
}
.sottolineamento{
  color: rgba(189, 220, 249, 0.69);
  text-decoration: none;
  border-bottom: 3px solid transparent;
}
.sottolineamento:hover{
  border-bottom: 3px solid rgba(189, 220, 249, 0.69);
  transition: 0.3s;  
}

/* icona freccia */
.freccia{
    color: rgba(189, 220, 249, 0.69);
    background-color: black;
    font-size: 12px;    
    text-decoration: none;
    border: 2px solid rgba(189, 220, 249, 0.69);
    border-radius: 100%;
    width: 20px;
    height: 20px;
}
.freccia:hover{
    color: black;
    border: 2px solid rgba(189, 220, 249, 0.69);
    background-color: rgba(189, 220, 249, 0.69);
    font-weight: bold;
    
}


/* IMMAGINE */
.altezza-img{
    height: 100%;
}
.card-custom{
    background-color: rgba(0, 0, 0, 0.77);
    border: 5px solid rgba(189, 220, 249, 0.69);
    padding: 50px 50px;
    border-radius: 100px;
    backdrop-filter: blur(10px);
    height: 100%;
    color: rgba(189, 220, 249, 0.69);
}
.img-europe-custom{ 
    border-radius: 70px;
    max-height: 100%; 
    max-width: 100%;  
}

/* media query */
@media (min-width: 300px) {  
  .continente-title{
    font-size: 30px;
    padding: 10px 50px 10px 50px;
  }
  .margin-title-c{
  padding-top: 23%;
  }
}
@media (min-width: 390px) {  
  .continente-title{
    font-size: 35px;
    padding: 10px 60px 10px 60px;
  }
}
@media (min-width: 414px) { 
  .margin-title-c{
    padding-top: 20%;
  }
  .continente-title{
    font-size: 40px;
    padding: 10px 70px 10px 70px;
  }
}
@media (min-width: 470px) { 
  .margin-title-c{
    padding-top: 18%;
  }
}
@media (min-width: 570px) { 
  .margin-title-c{
    padding-top: 15%;
  }
}
@media (min-width: 500px) { 
  .continente-title{
    font-size: 55px;
    padding: 10px 80px 10px 80px;
  }
}
@media (min-width: 768px){
  .continente-custom{
    padding: 20px 10px 20px 10px;  
  }
  .continente-title{
    font-size: 45px;
    padding: 10px 90px 10px 90px;
  }
  .margin-title-c{
    padding-top: 15%;
    padding-bottom: 5%;
  }
  .m-footer{
    padding-bottom: 20%;
  }
}
@media (min-width: 992px) { 
  .margin-title-c{
    padding-top: 13%;
    padding-bottom: 5%;
  }
  .continente-title{
    padding: 10px 100px 10px 100px;
  }
  .m-footer{
    padding-bottom: 23%;
  }
  .larghezza-grande{
    display: flex;
    flex-direction: row;
}
}
@media (min-width: 1200px) { 
  .continente-title{
    font-size: 50px;
  }
  .margin-title-c{
    padding-top: 8%;
    padding-bottom: 2%;
  }
  .m-footer{
    padding-bottom: 15%;
  }
}
@media (min-width: 1400px) { 
  .continente-custom{
    padding: 20px 30px 20px 30px;
  }
  .m-footer{
    padding-bottom: 10%;
  }
}
</style>