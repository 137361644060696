<template>
  
  <!-- NAVBAR -->
  <Navbar :lingua="lingua" @invia="(lenguage)=>Changelanguage(lenguage)"/>
  
  <!-- VISTE -->
  <div class="htm">
    <router-view :lingua="lingua"/>
    
  </div>
  
  <!-- FOOTER -->
  <Footer  :lingua="lingua"/>
  
  
</template>

<script>

//importiamo le componenti
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'



export default{
  name: 'App',

  components: {Navbar,Footer},

  data(){
    return{
      //lingua base
      lingua: "IT",

    }
  },

  methods:{
    Changelanguage(lenguage){
      this.lingua = lenguage
      
    }
  }
}

</script>

<style>

.htm{
  height: max-content;
}

</style>
