<template>

    <h1 class="text-azzurrino">LINK IMMAGINI BANDIERE</h1>
    
    <!-- EUROPA -->
    <h2 class="pt-3 text-warning">EUROPA</h2>

    <div class="testo-bianco-sporco">
        <!-- ALBANIA -->
        <div class="testo-bianco-sporco">ALBANIA</div>
        <a target="_blank" href="https://icons8.com/icon/Mi9i4LBfu5zA/albania">Albania</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ANDORRA -->
        <div class="testo-bianco-sporco">ANDORRA</div>
        <a target="_blank" href="https://icons8.com/icon/YFtWCXqYnWpi/andorra">Andorra</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- AUSTRIA -->
        <div class="testo-bianco-sporco">AUSTRIA</div>
        <a target="_blank" href="https://icons8.com/icon/Rc5UwnPA4ni0/austria">Austria</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BELGIO -->
        <div class="testo-bianco-sporco">BELGIO</div>
        <a target="_blank" href="https://icons8.com/icon/15514/belgium">Belgio</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BIELORUSS -->
        <div class="testo-bianco-sporco">BIELORUSS</div>
        <a target="_blank" href="https://icons8.com/icon/cHlyCvLE0qpn/belarus">Belarus</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BOSNIA ED ERZEGOVINA -->
        <div class="testo-bianco-sporco">BOSNIA ED ERZEGOVINA</div>
        <a target="_blank" href="https://icons8.com/icon/dE2eruTsVNST/bosnia-and-herzegovina">Bosnia ed Erzegovina</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BULGARIA -->
        <div class="testo-bianco-sporco">BULGARIA</div>
        <a target="_blank" href="https://icons8.com/icon/I8kIf7aJ1Ukq/bulgaria">Bulgaria</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- CIPRO -->
        <div class="testo-bianco-sporco">CIPRO</div>
        <a target="_blank" href="https://icons8.com/icon/rDbaa1t59szw/cyprus">Cipro</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- CITTA DEL VATICANO -->
        <div class="testo-bianco-sporco">CITTA DEL VATICANO</div>
        <a target="_blank" href="https://icons8.com/icon/XmETIGO3b1kj/citt%C3%A0-del-vaticano">Città del Vaticano</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- CROAZIA -->
        <div class="testo-bianco-sporco">CROAZIA</div>
        <a target="_blank" href="https://icons8.com/icon/3NROwLFY9kxm/croatia">Croazia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- DANIMARCA -->
        <div class="testo-bianco-sporco">DANIMARCA</div>
        <a target="_blank" href="https://icons8.com/icon/pxSNY3XtidTA/denmark">Danimarca</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ESTONIA -->
        <div class="testo-bianco-sporco">ESTONIA</div>
        <a target="_blank" href="https://icons8.com/icon/15500/estonia">Estonia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GRECIA -->
        <div class="testo-bianco-sporco">GRECIA</div>
        <a target="_blank" href="https://icons8.com/icon/XkCNujxBeQ5c/greece">Grecia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- IRLANDA -->
        <div class="testo-bianco-sporco">IRLANDA</div>
        <a target="_blank" href="https://icons8.com/icon/lJ8RHH9w04TP/ireland">Irlanda</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ISLANDA -->
        <div class="testo-bianco-sporco">ISLANDA</div>
        <a target="_blank" href="https://icons8.com/icon/Tj9LcbafdFHy/iceland">Islanda</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- LETTONIA -->
        <div class="testo-bianco-sporco">LETTONIA</div>
        <a target="_blank" href="https://icons8.com/icon/Eyu4Key3vT4y/latvia">Lettonia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- LIECHTENSTEIN -->
        <div class="testo-bianco-sporco">LIECHTENSTEIN</div>
        <a target="_blank" href="https://icons8.com/icon/iXoLCUBStLED/liechtenstein">liechtenstein</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- LITUANIA -->
        <div class="testo-bianco-sporco">LITUANIA</div>
        <a target="_blank" href="https://icons8.com/icon/FXcCoWeK0KSp/lithuania">Lituania</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- LUSSEMBURGO -->
        <div class="testo-bianco-sporco">LUSSEMBURGO</div>
        <a target="_blank" href="https://icons8.com/icon/f2jSCJAqir00/luxembourg">Lussemburgo</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MACEDONIA -->
        <div class="testo-bianco-sporco">MACEEDONIA</div>
        <a target="_blank" href="https://icons8.com/icon/xDSsyEZkErhl/macedonia">Macedonia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MALTA -->
        <div class="testo-bianco-sporco">MALTA</div>
        <a target="_blank" href="https://icons8.com/icon/m9r9VHsFIDGB/malta">Malta</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MOLDAVIA -->
        <div class="testo-bianco-sporco">MOLDAVIA</div>
        <a target="_blank" href="https://icons8.com/icon/0gVmgGByGUNH/moldova">Moldova</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MONACO -->
        <div class="testo-bianco-sporco">MONACO</div>
        <a target="_blank" href="https://icons8.com/icon/xTiFCfqjkrVo/monaco">Monaco</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MONTENEGRO -->
        <div class="testo-bianco-sporco">MONTENEGRO</div>
        <a target="_blank" href="https://icons8.com/icon/3dvBVbTjMkI8/montenegro">montenegro</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- NORVEGIA -->
        <div class="testo-bianco-sporco">NORVEGIA</div>
        <a target="_blank" href="https://icons8.com/icon/bZquDKA9iz44/norway">Norvegia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- PAESI BASSI -->
        <div class="testo-bianco-sporco">PAESI BASSI</div>
        <a target="_blank" href="https://icons8.com/icon/NtGaGXY3BxME/netherlands">Paesi Bassi</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- POLONIA -->
        <div class="testo-bianco-sporco">POLONIA</div>
        <a target="_blank" href="https://icons8.com/icon/EmBRQTNAaD1m/poland">Polonia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- PORTOGALLO -->
        <div class="testo-bianco-sporco">PORTOGALLO</div>
        <a target="_blank" href="https://icons8.com/icon/QLVHfYVwBpgk/portugal">Portogallo</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- REGNO UNITO -->
        <div class="testo-bianco-sporco">REGNO UNITO</div>
        <a target="_blank" href="https://icons8.com/icon/n5cRfdV5J7nI/great-britain">Gran Bretagna</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- REPUBBLICA CECA -->
        <div class="testo-bianco-sporco">REPUBBLICA CECA</div>
        <a target="_blank" href="https://icons8.com/icon/lhpePgIJvGu7/czech-republic">Repubblica Ceca</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ROMANIA -->
        <div class="testo-bianco-sporco">ROMANIA</div>
        <a target="_blank" href="https://icons8.com/icon/eEa9ABmmOmuK/romania">Romania</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SAN MARINO -->
        <div class="testo-bianco-sporco">SAN MARINO</div>
        <a target="_blank" href="https://icons8.com/icon/sLWgOJQbo3CG/san-marino">San Marino</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SERBIA -->
        <div class="testo-bianco-sporco">SERBIA</div>
        <a target="_blank" href="https://icons8.com/icon/MS0StNL4C5Ua/serbia">Serbia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SLOVACCHIA -->
        <div class="testo-bianco-sporco">SLOVACCHIA</div>
        <a target="_blank" href="https://icons8.com/icon/MsDszChg9YnD/slovakia">Slovacchia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SLOVENIA -->
        <div class="testo-bianco-sporco">SLOVENIA</div>
        <a target="_blank" href="https://icons8.com/icon/8eZYuOYTN3FK/slovenia">Slovenia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SVEZIA -->
        <div class="testo-bianco-sporco">SVEZIA</div>
        <a target="_blank" href="https://icons8.com/icon/uDfXl5ZerziL/sweden">Svezia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SVIZZERA -->
        <div class="testo-bianco-sporco">SVIZZERA</div>
        <a target="_blank" href="https://icons8.com/icon/xAAqHs6ucUir/switzerland">Svizzera</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- TURCHIA -->
        <div class="testo-bianco-sporco">TURCHIA</div>
        <a target="_blank" href="https://icons8.com/icon/Lo6X5VXOeFs5/turkey">Turchia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- UCRAINA -->
        <div class="testo-bianco-sporco">UCRAINA</div>
        <a target="_blank" href="https://icons8.com/icon/HIy5xIoBr7lB/ukraine">Ucraina</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- UNGHERIA -->
        <div class="testo-bianco-sporco">UNGHERIA</div>
        <a target="_blank" href="https://icons8.com/icon/15535/hungary">Ungheria</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
    </div>

    <!-- ASIA -->
    <h2 class="pt-3 text-warning">ASIA</h2>

    <div class="testo-bianco-sporco">
        <!-- ARMENIA -->
        <div class="testo-bianco-sporco">ARMENIA</div>
        <a target="_blank" href="https://icons8.com/icon/0Ak0AbJEqqfY/armenia">Armenia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a><!-- AZERBAIGIAN -->
        <div class="testo-bianco-sporco">AZERBAIGIAN</div>
        <a target="_blank" href="https://icons8.com/icon/HTAwrSMrpxAg/azerbaijan">Azerbaigian</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GEORGIA -->
        <div class="testo-bianco-sporco">GEORGIA</div>
        <a target="_blank" href="https://icons8.com/icon/NR1NcawHfx0z/georgia">Georgia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- KAZAKISTAN -->
        <div class="testo-bianco-sporco">KAZAKISTAN</div>
        <a target="_blank" href="https://icons8.com/icon/Swk3RoJD8cSq/kazakhstan">Kazakhstan</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- RUSSIA -->
        <div class="testo-bianco-sporco">RUSSIA</div>
        <a target="_blank" href="https://icons8.com/icon/PmENxlRZQ0o8/russian-federation">Federazione Russa</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ARABIA SAUDITA -->
        <div class="testo-bianco-sporco">ARABIA SAUDITA</div>
        <a target="_blank" href="https://icons8.com/icon/AQgcGUf4faEK/saudi-arabia">Arabia Saudita</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- AFGHANISTAN -->
        <div class="testo-bianco-sporco">AFGHANISTAN</div>
        <a target="_blank" href="https://icons8.com/icon/hTW-QzaCtJ0L/flag-of-afghanistan">afghanistan</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BAHREIN -->
        <div class="testo-bianco-sporco">BAHREIN</div>
        <a target="_blank" href="https://icons8.com/icon/euQ4OidKKNXY/bahrain">bahrein</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BANGLADESH -->
        <div class="testo-bianco-sporco">BANGLADESH</div>
        <a target="_blank" href="https://icons8.com/icon/F0pKkQbyTg9t/bangladesh">Bangladesh</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BIRMANIA -->
        <div class="testo-bianco-sporco">BIRMANIA</div>
        <a target="_blank" href="https://icons8.com/icon/lgzWQZIF9Doj/myanmar">Myanmar</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BHUTAN -->
        <div class="testo-bianco-sporco">BHUTAN</div>
        <a target="_blank" href="https://icons8.com/icon/EG9mOYXZbTQ6/bhutan">bhutan</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BRUNEI -->
        <div class="testo-bianco-sporco">BRUNEI</div>
        <a target="_blank" href="https://icons8.com/icon/j8AsAniVuzYv/brunei-darussalam">Brunei</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- CAMBOGIA -->
        <div class="testo-bianco-sporco">CAMBOGIA</div>
        <a target="_blank" href="https://icons8.com/icon/2ui1n4CYeion/cambodia">Cambogia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a><!-- CINA -->
        <div class="testo-bianco-sporco">CINA</div>
        <a target="_blank" href="https://icons8.com/icon/I3qGquBD7OQj/china">Cina</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- COREA DEL NORD -->
        <div class="testo-bianco-sporco">COREA DEL NORD</div>
        <a target="_blank" href="https://icons8.com/icon/BEOExEGTkjxZ/north-korea">Corea del Nord</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- COREA DEL SUD -->
        <div class="testo-bianco-sporco">COREA DEL SUD</div>
        <a target="_blank" href="https://icons8.com/icon/INp5MY6KIQBZ/south-korea">Corea del Sud</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- EMIRATI ARABI UNITI -->
        <div class="testo-bianco-sporco">EMIRATI ARABI UNITI</div>
        <a target="_blank" href="https://icons8.com/icon/60211/united-arab-emirates">Emirati Arabi Uniti</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- FILIPPINE -->
        <div class="testo-bianco-sporco">FILIPPINE</div>
        <a target="_blank" href="https://icons8.com/icon/qhap6o2QUx6c/philippines">Filippine</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GIAPPONE -->
        <div class="testo-bianco-sporco">GIAPPONE</div>
        <a target="_blank" href="https://icons8.com/icon/bqrynb0SSqae/japan">Giappone</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GIORDANIA -->
        <div class="testo-bianco-sporco">GIORDANIA</div>
        <a target="_blank" href="https://icons8.com/icon/60236/jordan">Giordania</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- INDIA -->
        <div class="testo-bianco-sporco">INDIA</div>
        <a target="_blank" href="https://icons8.com/icon/Kte10XInlZQf/india">India</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- INDONESIA -->
        <div class="testo-bianco-sporco">INDONESIA</div>
        <a target="_blank" href="https://icons8.com/icon/vSwmQIdioTES/indonesia">Indonesia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- IRAN -->
        <div class="testo-bianco-sporco">IRAN</div>
        <a target="_blank" href="https://icons8.com/icon/LodsF7qVq1xk/iran">Iran</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- IRAQ -->
        <div class="testo-bianco-sporco">IRAQ</div>
        <a target="_blank" href="https://icons8.com/icon/60207/iraq">Iraq</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ISRAELE -->
        <div class="testo-bianco-sporco">ISRAELE</div>
        <a target="_blank" href="https://icons8.com/icon/UIpPnPhwPOQi/israel">Israele</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- KIRGHIKISTAN -->
        <div class="testo-bianco-sporco">KIRGHIKISTAN</div>
        <a target="_blank" href="https://icons8.com/icon/6kdoYf8TbsFk/kyrgyzstan">Kirghizistan</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- KUWAIT -->
        <div class="testo-bianco-sporco">KUWAIT</div>
        <a target="_blank" href="https://icons8.com/icon/W7AzLQcBDMTx/kuwait">Kuwait</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- LAOS -->
        <div class="testo-bianco-sporco">LAOS</div>
        <a target="_blank" href="https://icons8.com/icon/5nGOUmHTXSzJ/laos">Laos</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- LIBANO -->
        <div class="testo-bianco-sporco">LIBANO</div>
        <a target="_blank" href="https://icons8.com/icon/MiQNC4QfpRrf/lebanon">Libano</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MALDIVE -->
        <div class="testo-bianco-sporco">MALDIVE</div>
        <a target="_blank" href="https://icons8.com/icon/N9V7DshkSd92/maldives">Maldive</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MALAYSIA -->
        <div class="testo-bianco-sporco">MALAYSIA</div>
        <a target="_blank" href="https://icons8.com/icon/cq5sDOvj501q/malaysia">Malaysia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MONGOLIA -->
        <div class="testo-bianco-sporco">MONGOLIA</div>
        <a target="_blank" href="https://icons8.com/icon/yIJAKcdfhtkD/mongolia">Mongolia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- NEPAL -->
        <div class="testo-bianco-sporco">NEPAL</div>
        <a target="_blank" href="https://icons8.com/icon/H5OZRUzOMxNW/nepal">Nepal</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- OMAN -->
        <div class="testo-bianco-sporco">OMAN</div>
        <a target="_blank" href="https://icons8.com/icon/AfNDIraneE7A/oman">Oman</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- PAKISTAN -->
        <div class="testo-bianco-sporco">PAKISTAN</div>
        <a target="_blank" href="https://icons8.com/icon/Vq16hQORkfr7/pakistan">Pakistan</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- QATAR -->
        <div class="testo-bianco-sporco">QATAR</div>
        <a target="_blank" href="https://icons8.com/icon/hJC38XVGoEp2/qatar">Qatar</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SINGAPORE -->
        <div class="testo-bianco-sporco">SINGAPORE</div>
        <a target="_blank" href="https://icons8.com/icon/vkAJtN8gxqBa/singapore">Singapore</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SIRIA -->
        <div class="testo-bianco-sporco">SIRIA</div>
        <a target="_blank" href="https://icons8.com/icon/60212/syria">Siria</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SRI LANKA -->
        <div class="testo-bianco-sporco">SRI LANKA</div>
        <a target="_blank" href="https://icons8.com/icon/F8sFzLNPZYKj/sri-lanka">Sri Lanka</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- TAGIKISTAN -->
        <div class="testo-bianco-sporco">TAGIKISTAN</div>
        <a target="_blank" href="https://icons8.com/icon/xPLIkniN9QKe/tajikistan">Tagikistan</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- THAILANDIA -->
        <div class="testo-bianco-sporco">THAILANDIA</div>
        <a target="_blank" href="https://icons8.com/icon/MsDF4P6xF7zU/thailand">Thailandia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- TIMOR EST -->
        <div class="testo-bianco-sporco">TIMOR EST</div>
        <a target="_blank" href="https://icons8.com/icon/HBORPBxlAqI6/timor-leste">Timor Leste</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- TURKMENISTAN -->
        <div class="testo-bianco-sporco">TURKMENISTAN</div>
        <a target="_blank" href="https://icons8.com/icon/e6fTnSOdD6T2/turkmenistan">Turkmenistan</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- UZBEKISTAN -->
        <div class="testo-bianco-sporco">UZBEKISTAN</div>
        <a target="_blank" href="https://icons8.com/icon/YkmeWJk45Wfx/uzbekistan">Uzbekistan</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- VIETNAM -->
        <div class="testo-bianco-sporco">VIETNAM</div>
        <a target="_blank" href="https://icons8.com/icon/GyBn50xYeMoV/vietnam">Vietnam</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- YEMEN -->
        <div class="testo-bianco-sporco">YEMEN</div>
        <a target="_blank" href="https://icons8.com/icon/60209/yemen">Yemen</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- PALESTINA -->
        <div class="testo-bianco-sporco">PALESTINA</div>
        <a target="_blank" href="https://icons8.com/icon/56525/palestine">Palestina</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- TAIWAN -->
        <div class="testo-bianco-sporco">TAIWAN</div>
        <a target="_blank" href="https://icons8.com/icon/VmF3IHgXvEIw/country">Paese</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
    </div>

    <!-- AFRICA -->
    <h2 class="pt-3 text-warning">AFRICA</h2>

    <div class="testo-bianco-sporco">
        <!-- ALGERIA -->
        <div class="testo-bianco-sporco">ALGERIA</div>
        <a target="_blank" href="https://icons8.com/icon/ocDCiVKgm2wJ/algeria">Algeria</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a><!-- ANGOLA -->
        <div class="testo-bianco-sporco">ANGOLA</div>
        <a target="_blank" href="https://icons8.com/icon/7h4WB6ZYb2z1/angola">Angola</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BENIN -->
        <div class="testo-bianco-sporco">BENIN</div>
        <a target="_blank" href="https://icons8.com/icon/bOtOpcarqo1e/benin">Benin</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BOTSWANA -->
        <div class="testo-bianco-sporco">BOTSWANA</div>
        <a target="_blank" href="https://icons8.com/icon/hdl1s8QBiOaA/botswana">Botswana</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BURKINA FASO -->
        <div class="testo-bianco-sporco">BURKINA FASO</div>
        <a target="_blank" href="https://icons8.com/icon/xx8SSIDYTLfO/burkina-faso">Burkina Faso</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BURUNDI -->
        <div class="testo-bianco-sporco">BURUNDI</div>
        <a target="_blank" href="https://icons8.com/icon/12C5SR24zNTP/burundi">Burundi</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- CAMERUN -->
        <div class="testo-bianco-sporco">CAMERUN</div>
        <a target="_blank" href="https://icons8.com/icon/5miBpI4Z3s7S/cameroon">Camerun</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- CAPO VERDE -->
        <div class="testo-bianco-sporco">CAPO VERDE</div>
        <a target="_blank" href="https://icons8.com/icon/gMNjM3oVT4Y0/cape-verde">Cape Verde</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- REPUBBLICA CENTRAFRICANA -->
        <div class="testo-bianco-sporco">REPUBBLICA CENTRAFRICANA</div>
        <a target="_blank" href="https://icons8.com/icon/j3Q7wutnaWZK/central-african-republic">repubblica centrafricana</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>>
        <!-- CIAD -->
        <div class="testo-bianco-sporco">CIAD</div>
        <a target="_blank" href="https://icons8.com/icon/9S4Y6eUa4JRY/chad">Chad</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- COMORE -->
        <div class="testo-bianco-sporco">COMORE</div>
        <a target="_blank" href="https://icons8.com/icon/f0GejpzfFgNw/comoros">Comore</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- COSTA D'AVORIO -->
        <div class="testo-bianco-sporco">COSTA D'AVORIO</div>
        <a target="_blank" href="https://icons8.com/icon/6pY8dRFZ7U3y/ivory-coast">Costa d'Avorio</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- REPUBBLICA DEMOCRATICA DEL CONGO -->
        <div class="testo-bianco-sporco">REPUBBLICA DEMOCRATICA DEL CONGO</div>
        <a target="_blank" href="https://icons8.com/icon/TJiAcnTMMuJ5/democratic-republic-of-the-congo">Repubblica Democratica del Congo</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- CONGO -->
        <div class="testo-bianco-sporco">CONGO</div>
        <a target="_blank" href="https://icons8.com/icon/4i8LC9yuFJJB/congo">Congo</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- EGITTO -->
        <div class="testo-bianco-sporco">EGITTO</div>
        <a target="_blank" href="https://icons8.com/icon/15521/egypt">Egitto</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ERITREA -->
        <div class="testo-bianco-sporco">ERITREA</div>
        <a target="_blank" href="https://icons8.com/icon/JB4vR8SfS37F/eritrea">Eritrea</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ETIPIA -->
        <div class="testo-bianco-sporco">ETIPIA</div>
        <a target="_blank" href="https://icons8.com/icon/4DaxT3HMXSyz/ethiopia">Etiopia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GABON -->
        <div class="testo-bianco-sporco">GABON</div>
        <a target="_blank" href="https://icons8.com/icon/DB4RcSpQAs87/gabon">Gabon</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GAMBIA -->
        <div class="testo-bianco-sporco">GAMBIA</div>
        <a target="_blank" href="https://icons8.com/icon/745hpLa5F2lg/gambia">Gambia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GHANA -->
        <div class="testo-bianco-sporco">GHANA</div>
        <a target="_blank" href="https://icons8.com/icon/43EyBS6jzFhL/ghana">Ghana</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GIBUTI -->
        <div class="testo-bianco-sporco">GIBUTI</div>
        <a target="_blank" href="https://icons8.com/icon/w00nB4GhaJc6/djibouti">Gibuti</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GUINEA -->
        <div class="testo-bianco-sporco">GUINEA</div>
        <a target="_blank" href="https://icons8.com/icon/dpTrWGmEWKWs/guinea">Guinea</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GUINEA BISSAU -->
        <div class="testo-bianco-sporco">GUINEA BISSAU</div>
        <a target="_blank" href="https://icons8.com/icon/M2thUhK5CZi9/guinea-bissau">Guinea-Bissau</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GUINEA EQUATORIALE -->
        <div class="testo-bianco-sporco">GUINEA EQUATORIALE</div>
        <a target="_blank" href="https://icons8.com/icon/QWVCess3E22Y/equatorial-guinea">Guinea Equatoriale</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- KENYA -->
        <div class="testo-bianco-sporco">KENYA</div>
        <a target="_blank" href="https://icons8.com/icon/60227/kenya">Kenia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- LESOTHO -->
        <div class="testo-bianco-sporco">LESOTHO</div>
        <a target="_blank" href="https://icons8.com/icon/sTNUIb56DJag/lesotho">Lesotho</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- LIBERIA -->
        <div class="testo-bianco-sporco">LIBERIA</div>
        <a target="_blank" href="https://icons8.com/icon/tqNQI1afhyZs/liberia">Liberia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- LIBIA -->
        <div class="testo-bianco-sporco">LIBIA</div>
        <a target="_blank" href="https://icons8.com/icon/60208/libya">Libia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MADAGASCAR -->
        <div class="testo-bianco-sporco">MADAGASCAR</div>
        <a target="_blank" href="https://icons8.com/icon/bVfXLCEJuR7H/madagascar">Madagascar</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MALAWI -->
        <div class="testo-bianco-sporco">MALAWI</div>
        <a target="_blank" href="https://icons8.com/icon/60251/malawi">Malawi</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MALI -->
        <div class="testo-bianco-sporco">MALI</div>
        <a target="_blank" href="https://icons8.com/icon/3wKNMuJCSwHa/mali">Mali</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MAURITANIA -->
        <div class="testo-bianco-sporco">MAURITANIA</div>
        <a target="_blank" href="https://icons8.com/icon/VF9NK7fHqjg5/mauritania">Mauritania</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MAURITIUS -->
        <div class="testo-bianco-sporco">MAURITIUS</div>
        <a target="_blank" href="https://icons8.com/icon/jG5hXoFJ1FF3/mauritius">Mauritius</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MAROCCO -->
        <div class="testo-bianco-sporco">MAROCCO</div>
        <a target="_blank" href="https://icons8.com/icon/RFTc8dvhF7PG/morocco">Marocco</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MOZAMBICO -->
        <div class="testo-bianco-sporco">MOZAMBICO</div>
        <a target="_blank" href="https://icons8.com/icon/123601/mozambique-flag">Mozambico</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- NAMIBIA -->
        <div class="testo-bianco-sporco">NAMIBIA</div>
        <a target="_blank" href="https://icons8.com/icon/PEFnTmW3fGqk/namibia">Namibia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- NIGER -->
        <div class="testo-bianco-sporco">NIGER</div>
        <a target="_blank" href="https://icons8.com/icon/i3zIhXX7iRZi/niger">Niger</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- NIGERIA -->
        <div class="testo-bianco-sporco">NIGERIA</div>
        <a target="_blank" href="https://icons8.com/icon/tI1B7Tv1cZGB/nigeria-flag">Nigeria Flag</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- RUANDA -->
        <div class="testo-bianco-sporco">RUANDA</div>
        <a target="_blank" href="https://icons8.com/icon/mhY2oeNH4WBC/rwanda">Ruanda</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SAO TOME E PRINCIPE -->
        <div class="testo-bianco-sporco">SAO TOME E PRINCIPE</div>
        <a target="_blank" href="https://icons8.com/icon/74eUWDHe3jmS/sao-tome-and-principe">sao-tome-e-principe</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SENEGAL -->
        <div class="testo-bianco-sporco">SENEGAL</div>
        <a target="_blank" href="https://icons8.com/icon/xXRLL3dOn7Aa/senegal">Senegal</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SEYCHELLES -->
        <div class="testo-bianco-sporco">SEYCHELLES</div>
        <a target="_blank" href="https://icons8.com/icon/fBnT9maqczXO/seychelles">Seychelles</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SIERRA LEONE -->
        <div class="testo-bianco-sporco">SIERRA LEONE</div>
        <a target="_blank" href="https://icons8.com/icon/l1XoQs8DCeIt/sierra-leone">Sierra Leone</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SOMALIA -->
        <div class="testo-bianco-sporco">SOMALIA</div>
        <a target="_blank" href="https://icons8.com/icon/XnJlqpXchm8I/somalia">Somalia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SUD AFRICA -->
        <div class="testo-bianco-sporco">SUD AFRICA</div>
        <a target="_blank" href="https://icons8.com/icon/6GIqidUWyHep/south-africa">Sud Africa</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SUDAN -->
        <div class="testo-bianco-sporco">SUDAN</div>
        <a target="_blank" href="https://icons8.com/icon/qmhWMFH1Znqj/sudan">Sudan</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SUDAN DEL SUD -->
        <div class="testo-bianco-sporco">SUDAN DEL SUD</div>
        <a target="_blank" href="https://icons8.com/icon/60206/south-sudan">Sudan del Sud</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ESWATINI -->
        <div class="testo-bianco-sporco">ESWATINI</div>
        <a target="_blank" href="https://icons8.com/icon/60243/eswatini">Swaziland</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- TANZANIA -->
        <div class="testo-bianco-sporco">TANZANIA</div>
        <a target="_blank" href="https://icons8.com/icon/qmpfO1qeKEL9/tanzania">Tanzania</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- TOGO -->
        <div class="testo-bianco-sporco">TOGO</div>
        <a target="_blank" href="https://icons8.com/icon/WwEunDnGUXTH/togo">Togo</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- TUNISIA -->
        <div class="testo-bianco-sporco">TUNISIA</div>
        <a target="_blank" href="https://icons8.com/icon/T2kgNuFhzoSL/tunisia">Tunisia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- UGANDA -->
        <div class="testo-bianco-sporco">UGANDA</div>
        <a target="_blank" href="https://icons8.com/icon/60235/uganda">Uganda</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ZAMBIA -->
        <div class="testo-bianco-sporco">ZAMBIA</div>
        <a target="_blank" href="https://icons8.com/icon/IuXqrDllIj79/zambia">Zambia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ZIMBABWE -->
        <div class="testo-bianco-sporco">ZIMBABWE</div>
        <a target="_blank" href="https://icons8.com/icon/PKhZQgNdbSRO/zimbabwe">Zimbabwe</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
    </div>

    <!-- NORD AMERICA -->
    <h2 class="pt-3 text-warning">NORD AMERICA</h2>

    <div class="testo-bianco-sporco">
        <!-- ANTIGUA E BARBUDA -->
        <div class="testo-bianco-sporco">ANTIGUA E BARBUDA</div>
        <a target="_blank" href="https://icons8.com/icon/kCkJYh2i4Gsl/antigua-and-barbuda">Antigua e Barbuda</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BAHAMAS -->
        <div class="testo-bianco-sporco">BAHAMAS</div>
        <a target="_blank" href="https://icons8.com/icon/15510/bahamas">Bahamas</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BARBADOS -->
        <div class="testo-bianco-sporco">BARBADOS</div>
        <a target="_blank" href="https://icons8.com/icon/jVJbdYrtHVex/barbados">Barbados</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BELIZE -->
        <div class="testo-bianco-sporco">BELIZE</div>
        <a target="_blank" href="https://icons8.com/icon/ipdh6Y5H5kIl/belize">belize</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- CANDA -->
        <div class="testo-bianco-sporco">CANADA</div>
        <a target="_blank" href="https://icons8.com/icon/waex7OwLlVbE/canada">Canada</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- COSTA RICA -->
        <div class="testo-bianco-sporco">COSTA RICA</div>
        <a target="_blank" href="https://icons8.com/icon/EJoXniUdk5ev/costa-rica">Costa Rica</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- CUBA -->
        <div class="testo-bianco-sporco">CUBA</div>
        <a target="_blank" href="https://icons8.com/icon/OTQxPfie9txt/cuba">Cuba</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- DOMINICA -->
        <div class="testo-bianco-sporco">DOMINICA</div>
        <a target="_blank" href="https://icons8.com/icon/FCOn855aBGcF/dominica">domenica</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- EL SALVADOR -->
        <div class="testo-bianco-sporco">EL SALVADOR</div>
        <a target="_blank" href="https://icons8.com/icon/pvd5tz0fbTes/el-salvador">El Salvador</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- JAMAICA -->
        <div class="testo-bianco-sporco">JAMAICA</div>
        <a target="_blank" href="https://icons8.com/icon/15539/jamaica">Giamaica</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GRENADA -->
        <div class="testo-bianco-sporco">GRENADA</div>
        <a target="_blank" href="https://icons8.com/icon/4naTNEE0OuKM/grenada">Grenada</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GUATEMALA -->
        <div class="testo-bianco-sporco">GUATEMALA</div>
        <a target="_blank" href="https://icons8.com/icon/Gr8KhVYigRVM/guatemala">Guatemala</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- HAITI -->
        <div class="testo-bianco-sporco">HAITI</div>
        <a target="_blank" href="https://icons8.com/icon/37280/haiti-flag">Haiti</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- HONDURAS -->
        <div class="testo-bianco-sporco">HONDURAS</div>
        <a target="_blank" href="https://icons8.com/icon/2G0sLnxc2lOf/honduras">Honduras</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- MESSICO -->
        <div class="testo-bianco-sporco">MESSICO</div>
        <a target="_blank" href="https://icons8.com/icon/VIih4mVskI74/mexico">Messico</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- NICARAGUA -->
        <div class="testo-bianco-sporco">NICARAGUA</div>
        <a target="_blank" href="https://icons8.com/icon/Dkeb0SBZaGBo/nicaragua">Nicaragua</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- PANAMA -->
        <div class="testo-bianco-sporco">PANAMA</div>
        <a target="_blank" href="https://icons8.com/icon/9G54q4Wn1p3F/panama">Panama</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- REPUBBLICA DOMINICANA -->
        <div class="testo-bianco-sporco">REPUBBLICA DOMINICANA</div>
        <a target="_blank" href="https://icons8.com/icon/25ShAn9ntPNB/dominican-republic">Repubblica Dominicana</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SAINT KITTIS E NEVIS -->
        <div class="testo-bianco-sporco">SAINT KITTIS E NEVIS</div>
        <a target="_blank" href="https://icons8.com/icon/7gm7kG7ngQBV/saint-kitts-and-nevis">saint-kitts-e-nevis</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SAINT VINCENT E GRENADINE -->
        <div class="testo-bianco-sporco">SAINT VINCENT E GRENADINE</div>
        <a target="_blank" href="https://icons8.com/icon/M7JNlnWCbgAr/saint-vincent-and-the-grenadines">saint-vincent-e-grenadine</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SAINT LUCIA -->
        <div class="testo-bianco-sporco">SAINT LUCIA</div>
        <a target="_blank" href="https://icons8.com/icon/9kPpcApINb48/saint-lucia">Santa Lucia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- USA -->
        <div class="testo-bianco-sporco">USA</div>
        <a target="_blank" href="https://icons8.com/icon/YTyvOb1dPj7U/usa">Stati Uniti d'America</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- TRINIDAD E TOBAGO -->
        <div class="testo-bianco-sporco">TRINIDAD E TOBAGO</div>
        <a target="_blank" href="https://icons8.com/icon/k7NsHrwcj1Hn/trinidad-and-tobago">Trinidad e Tobago</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
    </div>


    <!-- OCEANIA -->
    <h2 class="pt-3 text-warning">NORD AMERICA</h2>

    <div class="testo-bianco-sporco">
        <!-- AUSTRALIA -->
        <div class="testo-bianco-sporco">AUSTRALIA</div>
        <a target="_blank" href="https://icons8.com/icon/OTvwtoS5lm6y/australia">Australia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- NUOVA ZELANDA -->
        <div class="testo-bianco-sporco">NUOVA ZELANDA</div>
        <a target="_blank" href="https://icons8.com/icon/fwJGdlr0R93B/new-zealand">Nuova Zelanda</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- PAPUA NUOVA GUINEA -->
        <div class="testo-bianco-sporco">PAPUA NUOVA GUINEA</div>
        <a target="_blank" href="https://icons8.com/icon/AVcLFXaHRqRM/papua-new-guinea">Papua Nuova Guinea</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ISOLE FIGI -->
        <div class="testo-bianco-sporco">ISOLE FIGI</div>
        <a target="_blank" href="https://icons8.com/icon/IXbBWmVYEWtS/fiji">Figi</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ISOLE SALOMONE -->
        <div class="testo-bianco-sporco">ISOLE SAOMONE</div>
        <a target="_blank" href="https://icons8.com/icon/5aLM5AhgRidh/solomon-islands">Isole Salomone</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- VANUATU -->
        <div class="testo-bianco-sporco">VANUATU</div>
        <a target="_blank" href="https://icons8.com/icon/xJ7mFG9aDmC4/vanuatu">Vanuatu</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- STATI FEDERALI DI MICRONESIA -->
        <div class="testo-bianco-sporco">STATI FEDERALI DI MICROESIA</div>
        <a target="_blank" href="https://icons8.com/icon/Pcf9BE7ddp2q/micronesia">micronesia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- KIRIBATI -->
        <div class="testo-bianco-sporco">KIRIBATI</div>
        <a target="_blank" href="https://icons8.com/icon/VuDtq0O3SeH9/kiribati">Kiribati</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ISOLE MARSHALL -->
        <div class="testo-bianco-sporco">ISOLE MARSHALL</div>
        <a target="_blank" href="https://icons8.com/icon/NwEJNjRJqy5o/marshall-islands">Isole Marshall</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- PALAU -->
        <a target="_blank" href="https://icons8.com/icon/ieyeUoM5lRta/country">Palau</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- NAURU -->
        <div class="testo-bianco-sporco">NAURU</div>
        <a target="_blank" href="https://icons8.com/icon/CzTjhfKz8hQR/nauru">nauru</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SAMOA -->
        <div class="testo-bianco-sporco">SAMOA</div>
        <a target="_blank" href="https://icons8.com/icon/wsnlIkx8e686/samoa">Samoa</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- TONGA -->
        <div class="testo-bianco-sporco">TONGA</div>
        <a target="_blank" href="https://icons8.com/icon/eWqCTTZl5Pse/tonga">Tonga</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- TUVALU -->
        <div class="testo-bianco-sporco">TUVALU</div>
        <a target="_blank" href="https://icons8.com/icon/V0Vhd6bfO1Yd/tuvalu">tuvalu</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
    </div>

    <!-- SUD AMERICA -->
    <h2 class="pt-3 text-warning">SUD AMERICA</h2>

    <div class="testo-bianco-sporco">
        <!-- ARGENTINA -->
        <div class="testo-bianco-sporco">ARGENTINA</div>
        <a target="_blank" href="https://icons8.com/icon/prjhRaP5o9is/argentina">Argentina</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BOLIVIA -->
        <div class="testo-bianco-sporco">BOLIVIA</div>
        <a target="_blank" href="https://icons8.com/icon/0NrYKmtyLlt3/bolivia">Bolivia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- BRASILE -->
        <div class="testo-bianco-sporco">BRASILE</div>
        <a target="_blank" href="https://icons8.com/icon/p5u8Ay0r7ngq/brazil">Brasile</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- CILE -->
        <div class="testo-bianco-sporco">CILE</div>
        <a target="_blank" href="https://icons8.com/icon/DZFi4iGU8PCp/chile">Cile</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- COLOMBIA -->
        <div class="testo-bianco-sporco">COLOMBIA</div>
        <a target="_blank" href="https://icons8.com/icon/NV56Ghk4IG7d/colombia">Colombia</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- ECUADOR -->
        <div class="testo-bianco-sporco">ECUADOR</div>
        <a target="_blank" href="https://icons8.com/icon/4bTI4mpBpWPa/ecuador">Ecuador</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- GUYANA -->
        <div class="testo-bianco-sporco">GUYANA</div>
        <a target="_blank" href="https://icons8.com/icon/clPEDe4xrZWN/guyana">Guyana</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- PARAGUAY -->
        <div class="testo-bianco-sporco">PARAGUAY</div>
        <a target="_blank" href="https://icons8.com/icon/0s9nNjOlHEKQ/paraguay">paraguay</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- PERU -->
        <div class="testo-bianco-sporco">PERU</div>
        <a target="_blank" href="https://icons8.com/icon/uB1uT2YvCJih/peru">Peru</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- SURINAME -->
        <div class="testo-bianco-sporco">SURINAME</div>
        <a target="_blank" href="https://icons8.com/icon/swyljfWOYj28/suriname">suriname</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- URUGUAY -->
        <div class="testo-bianco-sporco">URUGUAY</div>
        <a target="_blank" href="https://icons8.com/icon/XWdlikTCX75v/uruguay">Uruguay</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
        <!-- VENEZUELA -->
        <div class="testo-bianco-sporco">VENEZUELA</div>
        <a target="_blank" href="https://icons8.com/icon/atOfh5BlcJle/venezuela">Venezuela</a> icona di <a target="_blank" href="https://icons8.com">Icons8</a>
    </div>

    
</template>
<style>
.testo-bianco-sporco{
    color: antiquewhite;
}
.text-azzurrino{
    color: rgba(189, 220, 249, 0.69);
    padding-top: 100px;
}
</style>
<script>

export default{
    name: 'linkWiBandiereView',
    //importiamo la lingua
    props: ['lingua'],

}

</script>