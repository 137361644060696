<template class="template">

  <body class="img-sfondo-indovina position-sticky">

    <div class="container centramento-contenuto">

      <!-- TITOLI -->
      <div class="row align-items-center justify-content-center margin-title-gioco">   
        <div class="col d-flex jusity-content-center card-titoli">
          <div class="row text-center pe-4 ps-4">

            <!-- ITALIANO -->
            <div v-if="this.lingua == this.italiano">
              <!-- titolo -->
              <div v-if="this.continente == this.EUROPA">
                <h1>INDOVINA A QUALE STATO APPARTIENE LA SEGUENTE BANDIERA EUROPEA</h1>
              </div>
              <div v-else-if="this.continente == this.ASIA">
                <h1>INDOVINA A QUALE STATO APPARTIENE LA SEGUENTE BANDIERA ASIATICA</h1>
              </div> 
              <div v-else-if="this.continente == this.AFRICA">
                <h1>INDOVINA A QUALE STATO APPARTIENE LA SEGUENTE BANDIERA AFRICANA</h1>
              </div> 
              <div v-else-if="this.continente == this.NORD_AMERICA">
                <h1>INDOVINA A QUALE STATO NORD O CENTRO AMERICANO APPARTIENE LA SEGUENTE BANDIERA</h1>
              </div> 
              <div v-else-if="this.continente == this.OCEANIA">
                <h1>INDOVINA A QUALE STATO DELL'OCEANIA APPARTIENE LA SEGUENTE BANDIERA</h1>
              </div> 
              <div v-else-if="this.continente == this.SUD_AMERICA">
                <h1>INDOVINA A QUALE STATO APPARTIENE LA SEGUENTE BANDIERA SUD AMERICANA</h1>
              </div> 
              <!-- sotto titolo -->
              <h4>Devi indovinare lo stato avendo a disposizione la bandiera</h4>
            </div>

            <!-- INGLESE -->
            <div v-else-if="this.lingua == this.inglese">
              <!-- titolo -->
              <div v-if="this.continente == this.EUROPA">
                <h1>GUESS WHICH EUROPEAN COUNTRY THE FLAG BELONGS TO</h1>
              </div>
              <div v-else-if="this.continente == this.ASIA">
                <h1>GUESS WHICH ASIAN COUNTRY THE FLAG BELONGS TO</h1>
              </div> 
              <div v-else-if="this.continente == this.AFRICA">
                <h1>GUESS WHICH AFRICAN COUNTRY THE FLAG BELONGS TO</h1>
              </div> 
              <div v-else-if="this.continente == this.NORD_AMERICA">
                <h1>GUESS WHICH NORTH OR CENTRAL AMERICAN COUNTRY THE FLAG BELONGS TO</h1>
              </div> 
              <div v-else-if="this.continente == this.OCEANIA">
                <h1>GUESS WHICH COUNTRY OF OCEANIA THE FLAG BELONGS TO</h1>
              </div>
              <div v-else-if="this.continente == this.SUD_AMERICA">
                <h1>GUESS WHICH SOUTH AMERICAN COUNTRY THE FLAG BELONGS TO</h1>
              </div>
              <!-- sotto titolo -->
              <h4>You have to guess the country having the flag available</h4>
            </div>

            <!-- FINLANDESE -->
            <div v-else-if="this.lingua == this.finlandese">
              <!-- titolo -->
              <div v-if="this.continente == this.EUROPA">
                <h1>ARVAVAA MIHIN EUROOPAN MAALE LIPU KUULUU</h1>
              </div>
              <div v-else-if="this.continente == this.ASIA">
                <h1>ARVAVAA MIHIN AASIAAN MAALE LIPU KUULUU</h1>
              </div> 
              <div v-else-if="this.continente == this.AFRICA">
                <h1>ARVAVAA MIHIN AFRIKAN MAALE LIPU KUULUU</h1>
              </div> 
              <div v-else-if="this.continente == this.NORD_AMERICA">
                <h1>ARVAVAA MIHIN POHJOIS- JA KESKI-AMERIKAN MAAAN LIPU KUULUU</h1>
              </div> 
              <div v-else-if="this.continente == this.OCEANIA">
                <h1>ARVAVAA MIHIN OSEANIAAN LIPU KUULUU</h1>
              </div>
              <div v-else-if="this.continente == this.SUD_AMERICA">
                <h1>ARVAVAA MIHIN ETELÄ-AMERIKAN MAAAN LIPU KUULUU</h1>
              </div>
              <!-- sotto titolo -->
              <h4>Sinun on arvattava maa, jolla on lipu oleva pääoma</h4>
            </div>

          </div>
        </div>
      </div>

      <!-- PARTE INTERATTIVA -->
      <div class="row align-items-center justify-content-center pe-5 ps-5">
        <div class="col d-flex justify-content-center card-gioco align-items-center text-center mt-5 pt-5 pb-5 me-5 ms-5">

          <!-- BOTTONE per INIZIARE -->
          <div class="row pt-5 pb-5 justify-content-center" v-if="this.a < 1">
    
            <!-- ITALIANO -->
            <div v-if="this.lingua == this.italiano" class="row align-items-center sperim">
              <button v-on:click="numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> INIZIA </button>
            </div>

            <!-- INGLESE -->
            <div v-else-if="this.lingua == this.inglese" class="row align-items-center sperim">
              <button v-on:click="numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> START </button>
            </div>
            
            <!-- FINLANDESE -->
            <div v-else-if="this.lingua == this.finlandese" class="row align-items-center sperim">
              <button v-on:click="numeroCasuale(); changeState();" class="btn-custom-inizia pb-3 pt-3 ps-4 pe-4"> ALKAA </button>
            </div>
      
          </div>

          <!-- CARD PAGINA FINALE -->
          <div class="row justify-content-center pt-5 pb-5" v-else-if="this.a == this.final_page">

            <!-- ITALIANO -->
            <div class="col" v-if="this.lingua == this.italiano">
              <h5>VALUTAZIONE FINALE</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Punteggio finale: </strong> {{ score }}/{{ total }} </p>
                  <p  class="score-margin"><strong>Totale risposte giuste:</strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p  class="score-margin"><strong>Livello di conoscienza:</strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>Come minchia è possibile che non conosci nemmeno una bandiera!?!? Sei proprio ignorante... Vai a studiare che e meglio...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>Sei un ignorante!! Vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>Sai qualcosina, ma ancora troppo poco, vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>Non sei arrivato neanche a meta, vai a studiare!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Meh, sei arrivatə a metà, ma non ancora alla sufficienza...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>Hai una conoscienza basica della geografia, ma hai ancora molto da imparare</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Discreto, puoi fare di meglio!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Bravo! Hai una buona conoscienza della geografia, ma puoi fare ancora meglio!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Complimentissimi!! Hai un ottima conoscienza della geografia!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Bravissimə!! Conosci tutte le bandiere di questo continente, Ottimo lavoro!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> rincomincia </button>
              </div> 
            </div>
      
            <!-- INGLESE -->
            <div class="col" v-else-if="this.lingua == this.inglese">
              <h5>FINAL EVALUATION</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Final score: </strong> {{ score }}/{{ total }} </p>
                  <p class="score-margin"><strong>Total right answers: </strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p class="score-margin"><strong>Level of knowledge: </strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>How is it possible that you don't even know a flag?!? You're really ignorant... Go study, it's better...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>You are ignorant!! Go study!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>You know a little, but still too little, go study!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>You haven't even reached your destination, go study!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Meh, you're halfway there, but not enough yet...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>You have a basic understanding of geography, but you still have a lot to learn</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Decent, you can do better!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Good! You have a good understanding of geography, but you can do even better!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Congratulations!! You have an excellent knowledge of geography!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Well done!! You know all the flags of this continent, Great job!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> start again </button>
              </div>
            </div>

            <!-- FINLANDESE -->
            <div class="col" v-else-if="this.lingua == this.finlandese">
              <h5>LOPPUARVIOINTI</h5>
              <!-- PUNTEGGI FINALI -->
              <div class="row justify-content-center">
                <div class="score-border me-4 ms-4 mt-4 mb-4">
                  <p class="score-margin"><strong>Lopullinen tulos: </strong> {{ score }}/{{ total }} </p>
                  <p class="score-margin"><strong>Oikeat vastaukset yhteensä: </strong> {{ conoscenza }}/{{ n_stati }}</p>
                  <p class="score-margin"><strong>Tiedon taso: </strong> {{ percentuale }}%</p>
                </div>
              </div>
              <!-- FRASI MOTIVAZIONALI -->
              <div class="mb-4">
                <div v-if="this.percentuale == 0">
                  <h6>Miten on mahdollista, ettet tiedä edes lipua!?!? Olet todella tietämätön... Mene opiskelemaan, se on parempi...</h6>
                </div>
                <div v-else-if="this.percentuale <=20 ">
                  <h6>Olet tietämätön!! Mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale <=40 ">
                  <h6>Tiedät vähän, mutta silti liian vähän, mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale <50 ">
                  <h6>Et ole edes saavuttanut määränpäätäsi, mene opiskelemaan!!</h6>
                </div>
                <div v-else-if="this.percentuale ==50 ">
                  <h6>Mh, olet puolivälissä, mutta ei vielä tarpeeksi...</h6>
                </div>
                <div v-else-if="this.percentuale <=60 ">
                  <h6>Sinulla on perustiedot maantiedosta, mutta sinulla on vielä paljon opittavaa</h6>
                </div>
                <div v-else-if="this.percentuale <=70 ">
                  <h6>Kunnollinen, voit tehdä paremmin!</h6>
                </div>
                <div v-else-if="this.percentuale <=90 ">
                  <h6>Hyvä! Ymmärrät maantiedon hyvin, mutta pystyt vielä parempaan!</h6>
                </div>
                <div v-else-if="this.percentuale <100 ">
                  <h6>Onnittelut!! Sinulla on erinomainen maantieteen taito!</h6>
                </div>
                <div v-else-if="this.percentuale ==100 ">
                  <h6>Hyvin tehty!! Tiedät kaikki tämän mantereen liput, hienoa työtä!! </h6>
                </div>
              </div>
              <!-- bottone per tornare all'inizio -->
              <div>
                <button class="btn-custom" v-on:click="rincomincia"> aloittaa uudelleen </button>
              </div>
            </div>

          </div>

          <!-- GIOCO -->
          <div v-else class="container-fluid text-center pt-5 pb-5 justify-content-center">

            <!-- CARD da compilare -->
            <div class="row align-items-center justify-content-center" v-if="this.cp == true">

              <!-- EUROPA -->
              <div v-if="this.continente == this.EUROPA">

                <!-- ITALIANO -->
                <div v-if="this.lingua == this.italiano">
                  <div v-for="stato in europaIT" class="col text-center">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">

                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereEuropa">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="immagine della bandiera di uno stato europeo">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in europaEN" class="col text-center">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">

                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereEuropa">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="image of the flag of a European state">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in europaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">

                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereEuropa">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="kuva Euroopan valtion lipusta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <!-- ASIA -->
              <div v-else-if="this.continente == this.ASIA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in asiaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereAsia">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="immagine della bandiera di uno stato asiatico">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in asiaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereAsia">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="image of the flag of an Asian state">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in asiaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereAsia">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="kuva Aasian valtion lipusta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <!-- AFRICA -->
              <div v-else-if="this.continente == this.AFRICA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in africaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereAfrica">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="immagine della bandiera di uno stato africano">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in africaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereAfrica">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="image of the flag of an African state">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in africaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereAfrica">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="kuva afrikkalaisen valtion lipusta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- NORD AMERICA -->
              <div v-else-if="this.continente == this.NORD_AMERICA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in nordAmericaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereNordAmerica">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="immagine della bandiera di uno stato nord o centro americano">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in nordAmericaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereNordAmerica">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="image of the flag of a North or Central American state">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in nordAmericaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereNordAmerica">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="kuva Pohjois- tai Keski-Amerikan valtion lipusta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- OCEANIA -->
              <div v-else-if="this.continente == this.OCEANIA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in oceaniaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereOceania">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="immagine della bandiera di uno stato dell'oceania">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in oceaniaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereOceania">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="Image of the flag of an Oceanian state">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in oceaniaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereOceania">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="Kuva Oseanian valtion lipusta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per la capitale -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- SUD AMERICA -->
              <div v-else-if="this.continente == this.SUD_AMERICA">

                <!-- ITALIANO -->
                <div  v-if="this.lingua == this.italiano">
                  <div v-for="stato in sudAmericaIT" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereSudAmerica">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="immagine della bandiera di uno stato sud americano">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="scrivi qui lo stato" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Non hai inserito nessuno stato, scrivi il nome di uno stato</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> controlla </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div v-for="stato in sudAmericaEN" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereSudAmerica">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="image of the flag of a South American state">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="write the country here" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">You have not entered any country, write the name of a country</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> check </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div v-for="stato in sudAmericaFI" class="text-center col">

                    <!-- CARD RIPETUTA -->
                    <div v-if="stato.id == this.id_casuale" class="row text-center">
                      <div class="col">
                        
                        <!-- bandiera -->
                        <div class="mb-4" v-for="bandiera in BandiereSudAmerica">
                          <div v-if="bandiera.id == this.id_casuale">
                            <img :src="bandiera.imgBandiera" alt="kuva Etelä-Amerikan valtion lipusta">
                          </div>                          
                        </div>
                        
                        <form v-on:submit="(event)=>preventEvent(event)">
                          <!-- input per lo stato -->
                          <div>
                            <input type="text" id="stato" class="input-custom mb-4" placeholder="kirjoita maa tähän" v-model="tempStato">
                          </div>
                          <!-- avviso nel caso in cui l'input sia vuoto -->
                          <div v-if="this.passa == true" class="text-center">
                            <p class="advertise-custom">Et ole syöttänyt yhtään maata, kirjoita maan nimi</p>
                          </div>
                          <!-- PULSANTE CONTROLLA -->
                          <div class="sperim mt-4">
                            <button @click="checkState" class="btn-custom mb-4" type="submit"> tarkista </button>
                          </div>
                        </form>     

                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
      
            <!-- CARD di soluzione -->
            <div class="row align-items-center justify-content-center" v-else>

              <!-- SOLUZIONE -->
              <div class="text-center container-fluid">

                <!-- ITALIANO -->
                <div v-if="this.lingua == this.italiano">
                  <div class="orientamento-soluzione pt-3 pb-3">
                    <h5 class="me-2">SOLUZIONE: </h5>
                    <p> {{ Stato }}</p>
                  </div>
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6">complimenti la risposta era corretta!</span>
                    <p class="pt-3">punti + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Ignorante! La risposta era sbagliata!</span>
                    <p class="pt-3">punti -5</p>
                  </div>
                </div>

                <!-- INGLESE -->
                <div v-else-if="this.lingua == this.inglese">
                  <div class="orientamento-soluzione pt-3 pb-3">
                    <h5 class="me-2">CORRECT ANSWER: </h5>
                    <p> {{ Stato }}</p>
                  </div>
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6 ">congratulations, the answer was correct!</span>
                    <p class="pt-3">points + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Ignorant! The answer was wrong!</span>
                    <p class="pt-3">points -5</p>
                  </div>
                </div>

                <!-- FINLANDESE -->
                <div v-else-if="this.lingua == this.finlandese">
                  <div class="orientamento-soluzione pt-3 pb-3">
                    <h5 class="me-2">OIKEA VASTAUS: </h5>
                    <p> {{ Stato }}</p>
                  </div>
                  <div v-if="this.guess == true">
                    <span class="correct-answer h6 ">onnittelut, vastaus oli oikea!</span>
                    <p class="pt-3">pisteitä + 10</p>
                  </div>
                  <div v-else>
                    <span class="wrong-answer h6">Tietämätön! Vastaus oli väärä!</span>
                    <p class="pt-3">pisteitä -5</p>
                  </div>
                </div>
                
              </div>

              <!-- SCORE -->
              <div class="d-flex justify-content-center align-items-center text-center ">

                <!-- ITALIANO -->
                <div class="score-border" v-if="this.lingua == this.italiano">
                  <p class="score-margin">punteggio totale: {{ score }}</p>
                  <p class="score-margin">totale risposte giuste: {{ conoscenza }}</p>
                </div>

                <!-- INGLESE -->
                <div class="score-border" v-else-if="this.lingua == this.inglese">
                  <p class="score-margin">total score: {{ score }}</p>
                  <p class="score-margin">total correct answers: {{ conoscenza }}</p>
                </div>

                <!-- FINLANDESE -->
                <div class="score-border" v-else-if="this.lingua == this.finlandese">
                  <p class="score-margin">kokonaispisteet: {{ score }}</p>
                  <p class="score-margin">täysin oikeat vastaukset: {{ conoscenza }}</p>
                </div>
                
              </div>

              <!-- BOTTONE per CAMBIARE STATO / FINE -->
              <div>
                <!-- CAMBIA STATO -->
                <div v-if="this.a < this.n_stati">
                  <!-- ITALIANO -->
                  <div v-if="this.lingua == this.italiano">
                    <button class="btn-custom mb-4 mt-4" v-on:click="numeroCasuale(); changeState();" type="submit"> prossimo stato </button>
                  </div>
                  <!-- INGLESE -->
                  <div v-else-if="this.lingua == this.inglese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="numeroCasuale(); changeState();" type="submit"> next country </button>
                  </div>
                  <!-- FINLANDESE -->
                  <div v-else-if="this.lingua == this.finlandese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="numeroCasuale(); changeState();" type="submit"> seuraava maa </button>
                  </div>
                </div>
                <!-- FINE -->
                <div v-else>
                  <!-- ITALIANO -->
                  <div v-if="this.lingua == this.italiano">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> fine </button>
                  </div>
                  <!-- INGLESE -->
                  <div v-else-if="this.lingua == this.inglese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> end </button>
                  </div>
                  <!-- FINLANDESE -->
                  <div v-else-if="this.lingua == this.finlandese">
                    <button class="btn-custom mb-4 mt-4" v-on:click="endGame"> loppu </button>
                  </div>
                </div>
              </div>
              
            </div>

            <!-- A QUALE STATO SIAMO -->
            <div class="row">
              <div class="text-center container-fluid">
                <p>{{ a }}/{{ n_stati }}</p>
              </div>
            </div>
            
          </div>

        </div>
      </div>

    </div>

  </body>

</template>

<script>

  //importo i file json
    import statiItaliano from '/statiItalianoJson.json'
    import statiInglese from '/statiIngleseJson.json'
    import statiFinlandese from '/statiFinlandeseJson.json'
  //

  //importiamo le immagini delle bandiere
    //AFRICA
    import imgAlgeria from "../../assets/bandiere/bandiere-africa/icons8-algeria-100.png"
    import imgAngola from "../../assets/bandiere/bandiere-africa/icons8-angola-100.png"
    import imgBenin from "../../assets/bandiere/bandiere-africa/icons8-benin-100.png"
    import imgBotswana from "../../assets/bandiere/bandiere-africa/icons8-botswana-100.png"
    import imgBurkinaFaso from "../../assets/bandiere/bandiere-africa/icons8-burkina-faso-100.png"
    import imgBurundi from "../../assets/bandiere/bandiere-africa/icons8-burundi-100.png"
    import imgCamerun from "../../assets/bandiere/bandiere-africa/icons8-camerun-100.png"
    import imgCapoVerde from "../../assets/bandiere/bandiere-africa/icons8-capo-verde-100.png"
    import imgCiad from "../../assets/bandiere/bandiere-africa/icons8-ciad-100.png"
    import imgComore from "../../assets/bandiere/bandiere-africa/icons8-comore-100.png"
    import imgCongo from "../../assets/bandiere/bandiere-africa/icons8-congo-100.png"
    import imgCostaDAvorio from "../../assets/bandiere/bandiere-africa/icons8-costa-d-avorio-100.png"
    import imgEgitto from "../../assets/bandiere/bandiere-africa/icons8-egitto-100.png"
    import imgEritrea from "../../assets/bandiere/bandiere-africa/icons8-eritrea-100.png"
    import imgEtiopia from "../../assets/bandiere/bandiere-africa/icons8-etiopia-100.png"
    import imgGabon from "../../assets/bandiere/bandiere-africa/icons8-gabon-100.png"
    import imgGambia from "../../assets/bandiere/bandiere-africa/icons8-gambia-100.png"
    import imgGhana from "../../assets/bandiere/bandiere-africa/icons8-ghana-100.png"
    import imgGibuti from "../../assets/bandiere/bandiere-africa/icons8-gibuti-100.png"
    import imgGuinea from "../../assets/bandiere/bandiere-africa/icons8-guinea-100.png"
    import imgGuineaBissau from "../../assets/bandiere/bandiere-africa/icons8-guinea-bissau-100.png"
    import imgGuineaEquatoriale from "../../assets/bandiere/bandiere-africa/icons8-guinea-equatoriale-100.png"
    import imgKenia from "../../assets/bandiere/bandiere-africa/icons8-kenia-100.png"
    import imgLesotho from "../../assets/bandiere/bandiere-africa/icons8-lesotho-100.png"
    import imgLiberia from "../../assets/bandiere/bandiere-africa/icons8-liberia-100.png"
    import imgLibia from "../../assets/bandiere/bandiere-africa/icons8-libia-100.png"
    import imgMadagascar from "../../assets/bandiere/bandiere-africa/icons8-madagascar-100.png"
    import imgMalawi from "../../assets/bandiere/bandiere-africa/icons8-malawi-100.png"
    import imgMali from "../../assets/bandiere/bandiere-africa/icons8-mali-100.png"
    import imgMarocco from "../../assets/bandiere/bandiere-africa/icons8-marocco-100.png"
    import imgMauritania from "../../assets/bandiere/bandiere-africa/icons8-mauritania-100.png"
    import imgMauritius from "../../assets/bandiere/bandiere-africa/icons8-mauritius-100.png"
    import imgMozambico from "../../assets/bandiere/bandiere-africa/icons8-mozambico-100.png"
    import imgNamibia from "../../assets/bandiere/bandiere-africa/icons8-namibia-100.png"
    import imgNiger from "../../assets/bandiere/bandiere-africa/icons8-niger-100.png"
    import imgNigeria from "../../assets/bandiere/bandiere-africa/icons8-nigeria-100.png"
    import imgRepubblicaCentrafricana from "../../assets/bandiere/bandiere-africa/icons8-repubblica-centrafricana-100.png"
    import imgRepubblicaDemocraticaDelCongo from "../../assets/bandiere/bandiere-africa/icons8-repubblica-democratica-del-congo-100.png"
    import imgRuanda from "../../assets/bandiere/bandiere-africa/icons8-ruanda-100.png"
    import imgSaoTomeEPrincipe from "../../assets/bandiere/bandiere-africa/icons8-sao-tome-e-principe-100.png"
    import imgSenegal from "../../assets/bandiere/bandiere-africa/icons8-senegal-100.png"
    import imgSeychelles from "../../assets/bandiere/bandiere-africa/icons8-seychelles-100.png"
    import imgSierraLeone from "../../assets/bandiere/bandiere-africa/icons8-sierra-leone-100.png"
    import imgSomalia from "../../assets/bandiere/bandiere-africa/icons8-somalia-100.png"
    import imgSudAfrica from "../../assets/bandiere/bandiere-africa/icons8-sud-africa-100.png"
    import imgSudan from "../../assets/bandiere/bandiere-africa/icons8-sudan-100.png"
    import imgSudanDelSud from "../../assets/bandiere/bandiere-africa/icons8-sudan-del-sud-100.png"
    import imgSwaziland from "../../assets/bandiere/bandiere-africa/icons8-swaziland-100.png"
    import imgTanzania from "../../assets/bandiere/bandiere-africa/icons8-tanzania-100.png"
    import imgTogo from "../../assets/bandiere/bandiere-africa/icons8-togo-100.png"
    import imgTunisia from "../../assets/bandiere/bandiere-africa/icons8-tunisia-100.png"
    import imgUganda from "../../assets/bandiere/bandiere-africa/icons8-uganda-100.png"
    import imgZambia from "../../assets/bandiere/bandiere-africa/icons8-zambia-100.png"
    import imgZimbabwe from "../../assets/bandiere/bandiere-africa/icons8-zimbabwe-100.png"

    //ASIA
    import imgAfghanistan from "../../assets/bandiere/bandiere-asia/icons8-afghanistan-100.png"
    import imgArabiaSaudita from "../../assets/bandiere/bandiere-asia/icons8-arabia-saudita-100.png"
    import imgArmenia from "../../assets/bandiere/bandiere-asia/icons8-armenia-100.png"
    import imgAzeraigian from "../../assets/bandiere/bandiere-asia/icons8-azerbaigian-100.png"
    import imgBahrein from "../../assets/bandiere/bandiere-asia/icons8-bahrein-100.png"
    import imgBangladesh from "../../assets/bandiere/bandiere-asia/icons8-bangladesh-100.png"
    import imgBhutan from "../../assets/bandiere/bandiere-asia/icons8-bhutan-100.png"
    import imgBirmania from "../../assets/bandiere/bandiere-asia/icons8-birmania-100.png"
    import imgBrunei from "../../assets/bandiere/bandiere-asia/icons8-brunei-100.png"
    import imgCambogia from "../../assets/bandiere/bandiere-asia/icons8-cambogia-100.png"
    import imgCina from "../../assets/bandiere/bandiere-asia/icons8-cina-100.png"
    import imgCoreaDelNord from "../../assets/bandiere/bandiere-asia/icons8-corea-del-nord-100.png"
    import imgCoreaDelSud from "../../assets/bandiere/bandiere-asia/icons8-corea-del-sud-100.png"
    import imgEmiratiArabiUniti from "../../assets/bandiere/bandiere-asia/icons8-emirati-arabi-uniti-100.png"
    import imgFilippine from "../../assets/bandiere/bandiere-asia/icons8-filippine-100.png"
    import imgGeorgia from "../../assets/bandiere/bandiere-asia/icons8-georgia-100.png"
    import imgGiappone from "../../assets/bandiere/bandiere-asia/icons8-giappone-100.png"
    import imgGiordania from "../../assets/bandiere/bandiere-asia/icons8-giordania-100.png"
    import imgIndia from "../../assets/bandiere/bandiere-asia/icons8-india-100.png"
    import imgIndonesia from "../../assets/bandiere/bandiere-asia/icons8-indonesia-100.png"
    import imgIran from "../../assets/bandiere/bandiere-asia/icons8-iran-100.png"
    import imgIraq from "../../assets/bandiere/bandiere-asia/icons8-iraq-100.png"
    import imgIsraele from "../../assets/bandiere/bandiere-asia/icons8-israele-100.png"
    import imgKazakistan from "../../assets/bandiere/bandiere-asia/icons8-kazakistan-100.png"
    import imgKirghizistan from "../../assets/bandiere/bandiere-asia/icons8-kirghizistan-100.png"
    import imgKuwait from "../../assets/bandiere/bandiere-asia/icons8-kuwait-100.png"
    import imgLaos from "../../assets/bandiere/bandiere-asia/icons8-laos-100.png"
    import imgLibano from "../../assets/bandiere/bandiere-asia/icons8-libano-100.png"
    import imgMalaysia from "../../assets/bandiere/bandiere-asia/icons8-malaysia-100.png"
    import imgMaldive from "../../assets/bandiere/bandiere-asia/icons8-maldive-100.png"
    import imgMongolia from "../../assets/bandiere/bandiere-asia/icons8-mongolia-100.png"
    import imgNepal from "../../assets/bandiere/bandiere-asia/icons8-nepal-100.png"
    import imgOman from "../../assets/bandiere/bandiere-asia/icons8-oman-100.png"
    import imgPakistan from "../../assets/bandiere/bandiere-asia/icons8-pakistan-100.png"
    import imgPalestina from "../../assets/bandiere/bandiere-asia/icons8-palestina-100.png"
    import imgQatar from "../../assets/bandiere/bandiere-asia/icons8-qatar-100.png"
    import imgRussia from "../../assets/bandiere/bandiere-asia/icons8-russia-100.png"
    import imgSingapore from "../../assets/bandiere/bandiere-asia/icons8-singapore-100.png"
    import imgSiria from "../../assets/bandiere/bandiere-asia/icons8-siria-100.png"
    import imgSriLanka from "../../assets/bandiere/bandiere-asia/icons8-sri-lanka-100.png"
    import imgTagikistan from "../../assets/bandiere/bandiere-asia/icons8-tagikistan-100.png"
    import imgTaiwan from "../../assets/bandiere/bandiere-asia/icons8-taiwan-100.png"
    import imgThailandia from "../../assets/bandiere/bandiere-asia/icons8-thailandia-100.png"
    import imgTimorEst from "../../assets/bandiere/bandiere-asia/icons8-timor-est-100.png"
    import imgTurkmenistan from "../../assets/bandiere/bandiere-asia/icons8-turkmenistan-100.png"
    import imgUzbekistan from "../../assets/bandiere/bandiere-asia/icons8-uzbekistan-100.png"
    import imgVietnam from "../../assets/bandiere/bandiere-asia/icons8-vietnam-100.png"
    import imgYemen from "../../assets/bandiere/bandiere-asia/icons8-yemen-100.png"

    //EUROPA
    import imgAlbania from "../../assets/bandiere/bandiere-europa/icons8-albania-100.png"
    import imgAndorra from "../../assets/bandiere/bandiere-europa/icons8-andorra-100.png"
    import imgAustria from "../../assets/bandiere/bandiere-europa/icons8-austria-100.png"
    import imgBelarus from "../../assets/bandiere/bandiere-europa/icons8-belarus-100.png"
    import imgBelgio from "../../assets/bandiere/bandiere-europa/icons8-belgio-100.png"
    import imgBosniaEdErzegovina from "../../assets/bandiere/bandiere-europa/icons8-bosnia-ed-erzegovina-100.png"
    import imgBulgaria from "../../assets/bandiere/bandiere-europa/icons8-bulgaria-100.png"
    import imgCipro from "../../assets/bandiere/bandiere-europa/icons8-cipro-100.png"
    import imgCittaDelVaticano from "../../assets/bandiere/bandiere-europa/icons8-città-del-vaticano-100.png"
    import imgCroazia from "../../assets/bandiere/bandiere-europa/icons8-croazia-100.png"
    import imgDanimarca from "../../assets/bandiere/bandiere-europa/icons8-danimarca-100.png"
    import imgEstonia from "../../assets/bandiere/bandiere-europa/icons8-estonia-100.png"
    import imgFinlandia from "../../assets/bandiere/bandiere-europa/icons8-finlandia-100.png"
    import imgFrancia from "../../assets/bandiere/bandiere-europa/icons8-francia-100.png"
    import imgGermania from "../../assets/bandiere/bandiere-europa/icons8-germania-100.png"
    import imgGrecia from "../../assets/bandiere/bandiere-europa/icons8-grecia-100.png"
    import imgIrlanda from "../../assets/bandiere/bandiere-europa/icons8-irlanda-100.png"
    import imgIslanda from "../../assets/bandiere/bandiere-europa/icons8-islanda-100.png"
    import imgItalia from "../../assets/bandiere/bandiere-europa/icons8-italia-100.png"
    import imgLettonia from "../../assets/bandiere/bandiere-europa/icons8-lettonia-100.png"
    import imgLiechtenstein from "../../assets/bandiere/bandiere-europa/icons8-liechtenstein-100.png"
    import imgLituania from "../../assets/bandiere/bandiere-europa/icons8-lituania-100.png"
    import imgLussemburgo from "../../assets/bandiere/bandiere-europa/icons8-lussemburgo-100.png"
    import imgMacedonia from "../../assets/bandiere/bandiere-europa/icons8-macedonia-100.png"
    import imgMalta from "../../assets/bandiere/bandiere-europa/icons8-malta-100.png"
    import imgMoldova from "../../assets/bandiere/bandiere-europa/icons8-moldova-100.png"
    import imgMonaco from "../../assets/bandiere/bandiere-europa/icons8-monaco-100.png"
    import imgMontenegro from "../../assets/bandiere/bandiere-europa/icons8-montenegro-100.png"
    import imgNorvegia from "../../assets/bandiere/bandiere-europa/icons8-norvegia-100.png"
    import imgPaesiBassi from "../../assets/bandiere/bandiere-europa/icons8-paesi-bassi-100.png"
    import imgPolonia from "../../assets/bandiere/bandiere-europa/icons8-polonia-100.png"
    import imgPortogallo from "../../assets/bandiere/bandiere-europa/icons8-portogallo-100.png"
    import imgRegnoUnito from "../../assets/bandiere/bandiere-europa/icons8-regno-unito-100.png"
    import imgRepubblicaCeca from "../../assets/bandiere/bandiere-europa/icons8-repubblica-ceca-100.png"
    import imgRomania from "../../assets/bandiere/bandiere-europa/icons8-romania-100.png"
    import imgSanMarino from "../../assets/bandiere/bandiere-europa/icons8-san-marino-100.png"
    import imgSerbia from "../../assets/bandiere/bandiere-europa/icons8-serbia-100.png"
    import imgSlovacchia from "../../assets/bandiere/bandiere-europa/icons8-slovacchia-100.png"
    import imgSlovenia from "../../assets/bandiere/bandiere-europa/icons8-slovenia-100.png"
    import imgSpagna from "../../assets/bandiere/bandiere-europa/icons8-spagna-2-100.png"
    import imgSvezia from "../../assets/bandiere/bandiere-europa/icons8-svezia-100.png"
    import imgSvizzera from "../../assets/bandiere/bandiere-europa/icons8-svizzera-100.png"
    import imgTurchia from "../../assets/bandiere/bandiere-europa/icons8-turchia-100.png"
    import imgUcraina from "../../assets/bandiere/bandiere-europa/icons8-ucraina-100.png"
    import imgUngheria from "../../assets/bandiere/bandiere-europa/icons8-ungheria-100.png"

    //NORD AMERICA
    import imgAntiguaEBarbuda from "../../assets/bandiere/bandiere-nord-america/icons8-antigua-e-barbuda-100.png"
    import imgBahamas from "../../assets/bandiere/bandiere-nord-america/icons8-bahamas-100.png"
    import imgBarbados from "../../assets/bandiere/bandiere-nord-america/icons8-barbados-100.png"
    import imgBelize from "../../assets/bandiere/bandiere-nord-america/icons8-belize-100.png"
    import imgCanada from "../../assets/bandiere/bandiere-nord-america/icons8-canada-100.png"
    import imgCostaRica from "../../assets/bandiere/bandiere-nord-america/icons8-costa-rica-100.png"
    import imgCuba from "../../assets/bandiere/bandiere-nord-america/icons8-cuba-100.png"
    import imgDominica from "../../assets/bandiere/bandiere-nord-america/icons8-dominica-100.png"
    import imgElSalvador from "../../assets/bandiere/bandiere-nord-america/icons8-el-salvador-100.png"
    import imgGiamaica from "../../assets/bandiere/bandiere-nord-america/icons8-giamaica-100.png"
    import imgGrenada from "../../assets/bandiere/bandiere-nord-america/icons8-grenada-100.png"
    import imgGuatemala from "../../assets/bandiere/bandiere-nord-america/icons8-guatemala-100.png"
    import imgHaiti from "../../assets/bandiere/bandiere-nord-america/icons8-haiti-100.png"
    import imgHonduras from "../../assets/bandiere/bandiere-nord-america/icons8-honduras-100.png"
    import imgMessico from "../../assets/bandiere/bandiere-nord-america/icons8-messico-100.png"
    import imgNicaragua from "../../assets/bandiere/bandiere-nord-america/icons8-nicaragua-100.png"
    import imgPanama from "../../assets/bandiere/bandiere-nord-america/icons8-panama-100.png"
    import imgRepubblicaDominicana from "../../assets/bandiere/bandiere-nord-america/icons8-repubblica-dominicana-100.png"
    import imgSaintKittisENevis from "../../assets/bandiere/bandiere-nord-america/icons8-saint-kittis-e-nevis-100.png"
    import imgSaintVincentEGrenadine from "../../assets/bandiere/bandiere-nord-america/icons8-saint-vincent-e-grenadine-100.png"
    import imgSantaLucia from "../../assets/bandiere/bandiere-nord-america/icons8-santa-lucia-100.png"
    import imgTrinidadETobago from "../../assets/bandiere/bandiere-nord-america/icons8-trinidad-e-tobago-100.png"
    import imgUsa from "../../assets/bandiere/bandiere-nord-america/icons8-usa-100.png"
    
    //OCEANIA
    import imgAustralia from "../../assets/bandiere/bandiere-oceania/icons8-australia-100.png"
    import imgFigi from "../../assets/bandiere/bandiere-oceania/icons8-figi-100.png"
    import imgIsoleMarshall from "../../assets/bandiere/bandiere-oceania/icons8-isole-marshall-100.png"
    import imgIsoleSalomone from "../../assets/bandiere/bandiere-oceania/icons8-isole-salomone-100.png"
    import imgKiribati from "../../assets/bandiere/bandiere-oceania/icons8-kiribati-100.png"
    import imgMicronesia from "../../assets/bandiere/bandiere-oceania/icons8-micronesia-100.png"
    import imgNauru from "../../assets/bandiere/bandiere-oceania/icons8-nauru-100.png"
    import imgNuovaZelanda from "../../assets/bandiere/bandiere-oceania/icons8-nuova-zelanda-100.png"
    import imgPalau from "../../assets/bandiere/bandiere-oceania/icons8-palau-100.png"
    import imgPapuaNuovaGuinea from "../../assets/bandiere/bandiere-oceania/icons8-papua-nuova-guinea-100.png"
    import imgSamoa from "../../assets/bandiere/bandiere-oceania/icons8-samoa-100.png"
    import imgTonga from "../../assets/bandiere/bandiere-oceania/icons8-tonga-100.png"
    import imgTuvalu from "../../assets/bandiere/bandiere-oceania/icons8-tuvalu-100.png"
    import imgVanuatu from "../../assets/bandiere/bandiere-oceania/icons8-vanuatu-100.png"

    //SUD AMERICA
    import imgArgentina from "../../assets/bandiere/bandiere-sud-america/icons8-argentina-100.png"
    import imgBolivia from "../../assets/bandiere/bandiere-sud-america/icons8-bolivia-100.png"
    import imgBrasile from "../../assets/bandiere/bandiere-sud-america/icons8-brasile-100.png"
    import imgCile from "../../assets/bandiere/bandiere-sud-america/icons8-cile-100.png"
    import imgColombia from "../../assets/bandiere/bandiere-sud-america/icons8-colombia-100.png"
    import imgEcuador from "../../assets/bandiere/bandiere-sud-america/icons8-ecuador-100.png"
    import imgGuyana from "../../assets/bandiere/bandiere-sud-america/icons8-guyana-100.png"
    import imgParaguay from "../../assets/bandiere/bandiere-sud-america/icons8-paraguay-100.png"
    import imgPeru from "../../assets/bandiere/bandiere-sud-america/icons8-peru-100.png"
    import imgSuriname from "../../assets/bandiere/bandiere-sud-america/icons8-suriname-100.png"
    import imgUruguay from "../../assets/bandiere/bandiere-sud-america/icons8-uruguay-100.png"
    import imgVenezuela from "../../assets/bandiere/bandiere-sud-america/icons8-venezuela-100.png"
  //

  export default{
    name: 'IndovinaCapitaleEuropaView',
    //importiamo la lingua
    props: ['lingua'],

    data(){
      return{

        //IMPORTIAMO LE IMMAGINI DELLE BANDIERE
        BandiereEuropa: [
          {id:1, imgBandiera:imgAlbania},
          {id:2, imgBandiera:imgAndorra},
          {id:3, imgBandiera:imgAustria},
          {id:4, imgBandiera:imgBelgio},
          {id:5, imgBandiera:imgBelarus},
          {id:6, imgBandiera:imgBosniaEdErzegovina},
          {id:7, imgBandiera:imgBulgaria},
          {id:8, imgBandiera:imgCipro},
          {id:9, imgBandiera:imgCittaDelVaticano},
          {id:10, imgBandiera:imgCroazia},
          {id:11, imgBandiera:imgDanimarca},
          {id:12, imgBandiera:imgEstonia},
          {id:13, imgBandiera:imgFinlandia},
          {id:14, imgBandiera:imgFrancia},
          {id:15, imgBandiera:imgGermania},
          {id:16, imgBandiera:imgGrecia},
          {id:17, imgBandiera:imgIrlanda},
          {id:18, imgBandiera:imgIslanda},
          {id:19, imgBandiera:imgItalia},
          {id:20, imgBandiera:imgLettonia},
          {id:21, imgBandiera:imgLiechtenstein},
          {id:22, imgBandiera:imgLituania},
          {id:23, imgBandiera:imgLussemburgo},
          {id:24, imgBandiera:imgMacedonia},
          {id:25, imgBandiera:imgMalta},
          {id:26, imgBandiera:imgMoldova},
          {id:27, imgBandiera:imgMonaco},
          {id:28, imgBandiera:imgMontenegro},
          {id:29, imgBandiera:imgNorvegia},
          {id:30, imgBandiera:imgPaesiBassi},
          {id:31, imgBandiera:imgPolonia},
          {id:32, imgBandiera:imgPortogallo},
          {id:33, imgBandiera:imgRegnoUnito},
          {id:34, imgBandiera:imgRepubblicaCeca},
          {id:35, imgBandiera:imgRomania},
          {id:36, imgBandiera:imgSanMarino},
          {id:37, imgBandiera:imgSerbia},
          {id:38, imgBandiera:imgSlovacchia},
          {id:39, imgBandiera:imgSlovenia},
          {id:40, imgBandiera:imgSpagna},
          {id:41, imgBandiera:imgSvezia},
          {id:42, imgBandiera:imgSvizzera},
          {id:43, imgBandiera:imgTurchia},
          {id:44, imgBandiera:imgUcraina},
          {id:45, imgBandiera:imgUngheria},
        ],
        BandiereAsia: [
          {id:1, imgBandiera:imgAfghanistan},
          {id:2, imgBandiera:imgArabiaSaudita},
          {id:3, imgBandiera:imgArmenia},
          {id:4, imgBandiera:imgAzeraigian},
          {id:5, imgBandiera:imgBahrein},
          {id:6, imgBandiera:imgBangladesh},
          {id:7, imgBandiera:imgBirmania},
          {id:8, imgBandiera:imgBhutan},
          {id:9, imgBandiera:imgBrunei},
          {id:10, imgBandiera:imgCambogia},
          {id:11, imgBandiera:imgCina},
          {id:12, imgBandiera:imgCoreaDelNord},
          {id:13, imgBandiera:imgCoreaDelSud},
          {id:14, imgBandiera:imgEmiratiArabiUniti},
          {id:15, imgBandiera:imgFilippine},
          {id:16, imgBandiera:imgGeorgia},
          {id:17, imgBandiera:imgGiappone},
          {id:18, imgBandiera:imgGiordania},
          {id:19, imgBandiera:imgIndia},
          {id:20, imgBandiera:imgIndonesia},
          {id:21, imgBandiera:imgIran},
          {id:22, imgBandiera:imgIraq},
          {id:23, imgBandiera:imgIsraele},
          {id:24, imgBandiera:imgKazakistan},
          {id:25, imgBandiera:imgKirghizistan},
          {id:26, imgBandiera:imgKuwait},
          {id:27, imgBandiera:imgLaos},
          {id:28, imgBandiera:imgLibano},
          {id:29, imgBandiera:imgMaldive},
          {id:30, imgBandiera:imgMalaysia},
          {id:31, imgBandiera:imgMongolia},
          {id:32, imgBandiera:imgNepal},
          {id:33, imgBandiera:imgOman},
          {id:34, imgBandiera:imgPakistan},
          {id:35, imgBandiera:imgPalestina},
          {id:36, imgBandiera:imgQatar},
          {id:37, imgBandiera:imgRussia},
          {id:38, imgBandiera:imgSingapore},
          {id:39, imgBandiera:imgSiria},
          {id:40, imgBandiera:imgSriLanka},
          {id:41, imgBandiera:imgTagikistan},
          {id:42, imgBandiera:imgTaiwan},
          {id:43, imgBandiera:imgThailandia},
          {id:44, imgBandiera:imgTimorEst},
          {id:45, imgBandiera:imgTurkmenistan},
          {id:46, imgBandiera:imgUzbekistan},
          {id:47, imgBandiera:imgVietnam},
          {id:48, imgBandiera:imgYemen},
        ],
        BandiereAfrica: [
          {id:1, imgBandiera:imgAlgeria},
          {id:2, imgBandiera:imgAngola},
          {id:3, imgBandiera:imgBenin},
          {id:4, imgBandiera:imgBotswana},
          {id:5, imgBandiera:imgBurkinaFaso},
          {id:6, imgBandiera:imgBurundi},
          {id:7, imgBandiera:imgCamerun},
          {id:8, imgBandiera:imgCapoVerde},
          {id:9, imgBandiera:imgRepubblicaCentrafricana},
          {id:10, imgBandiera:imgCiad},
          {id:11, imgBandiera:imgComore},
          {id:12, imgBandiera:imgCostaDAvorio},
          {id:13, imgBandiera:imgRepubblicaDemocraticaDelCongo},
          {id:14, imgBandiera:imgCongo},
          {id:15, imgBandiera:imgEgitto},
          {id:16, imgBandiera:imgEritrea},
          {id:17, imgBandiera:imgEtiopia},
          {id:18, imgBandiera:imgGabon},
          {id:19, imgBandiera:imgGambia},
          {id:20, imgBandiera:imgGhana},
          {id:21, imgBandiera:imgGibuti},
          {id:22, imgBandiera:imgGuinea},
          {id:23, imgBandiera:imgGuineaBissau},
          {id:24, imgBandiera:imgGuineaEquatoriale},
          {id:25, imgBandiera:imgKenia},
          {id:26, imgBandiera:imgLesotho},
          {id:27, imgBandiera:imgLiberia},
          {id:28, imgBandiera:imgLibia},
          {id:29, imgBandiera:imgMadagascar},
          {id:30, imgBandiera:imgMalawi},
          {id:31, imgBandiera:imgMali},
          {id:32, imgBandiera:imgMauritania},
          {id:33, imgBandiera:imgMauritius},
          {id:34, imgBandiera:imgMarocco},
          {id:35, imgBandiera:imgMozambico},
          {id:36, imgBandiera:imgNamibia},
          {id:37, imgBandiera:imgNiger},
          {id:38, imgBandiera:imgNigeria},
          {id:39, imgBandiera:imgRuanda},
          {id:40, imgBandiera:imgSaoTomeEPrincipe},
          {id:41, imgBandiera:imgSenegal},
          {id:42, imgBandiera:imgSeychelles},
          {id:43, imgBandiera:imgSierraLeone},
          {id:44, imgBandiera:imgSomalia},
          {id:45, imgBandiera:imgSudAfrica},
          {id:46, imgBandiera:imgSudan},
          {id:47, imgBandiera:imgSudanDelSud},
          {id:48, imgBandiera:imgSwaziland},
          {id:49, imgBandiera:imgTanzania},
          {id:50, imgBandiera:imgTogo},
          {id:51, imgBandiera:imgTunisia},
          {id:52, imgBandiera:imgUganda},
          {id:53, imgBandiera:imgZambia},
          {id:54, imgBandiera:imgZimbabwe},
        ],
        BandiereNordAmerica: [
          {id:1, imgBandiera:imgAntiguaEBarbuda},
          {id:2, imgBandiera:imgBahamas},
          {id:3, imgBandiera:imgBarbados},
          {id:4, imgBandiera:imgBelize},
          {id:5, imgBandiera:imgCanada},
          {id:6, imgBandiera:imgCostaRica},
          {id:7, imgBandiera:imgCuba},
          {id:8, imgBandiera:imgDominica},
          {id:9, imgBandiera:imgElSalvador},
          {id:10, imgBandiera:imgGiamaica},
          {id:11, imgBandiera:imgGrenada},
          {id:12, imgBandiera:imgGuatemala},
          {id:13, imgBandiera:imgHaiti},
          {id:14, imgBandiera:imgHonduras},
          {id:15, imgBandiera:imgMessico},
          {id:16, imgBandiera:imgNicaragua},
          {id:17, imgBandiera:imgPanama},
          {id:18, imgBandiera:imgRepubblicaDominicana},
          {id:19, imgBandiera:imgSaintKittisENevis},
          {id:20, imgBandiera:imgSaintVincentEGrenadine},
          {id:21, imgBandiera:imgSantaLucia},
          {id:22, imgBandiera:imgTrinidadETobago},
          {id:23, imgBandiera:imgUsa}
        ],
        BandiereOceania: [
          {id:1, imgBandiera:imgAustralia},
          {id:2, imgBandiera:imgNuovaZelanda},
          {id:3, imgBandiera:imgPapuaNuovaGuinea},
          {id:4, imgBandiera:imgFigi},
          {id:5, imgBandiera:imgIsoleSalomone},
          {id:6, imgBandiera:imgVanuatu},
          {id:7, imgBandiera:imgMicronesia},
          {id:8, imgBandiera:imgKiribati},
          {id:9, imgBandiera:imgIsoleMarshall},
          {id:10, imgBandiera:imgPalau},
          {id:11, imgBandiera:imgNauru},
          {id:12, imgBandiera:imgSamoa},
          {id:13, imgBandiera:imgTonga},
          {id:14, imgBandiera:imgTuvalu}
        ],
        BandiereSudAmerica: [
          {id:1, imgBandiera:imgArgentina},
          {id:2, imgBandiera:imgBolivia},
          {id:3, imgBandiera:imgBrasile},
          {id:4, imgBandiera:imgCile},
          {id:5, imgBandiera:imgColombia},
          {id:6, imgBandiera:imgEcuador},
          {id:7, imgBandiera:imgGuyana},
          {id:8, imgBandiera:imgParaguay},
          {id:9, imgBandiera:imgPeru},
          {id:10, imgBandiera:imgSuriname},
          {id:11, imgBandiera:imgUruguay},
          {id:12, imgBandiera:imgVenezuela}
        ],

        //LINGUE
        inglese: "EN",
        italiano: "IT",
        finlandese: "FI",

        //per intrappolare il parametro delle BANDIERE dalla rotta
        bandiere: this.$route.params.bandiere,
        
        //DETERMINAZIONE del CONTINENTE
          //CICLARE i dati di ogni CONTINENTE
            //ITALIANO
            africaIT: statiItaliano.AFRICA,
            asiaIT: statiItaliano.ASIA,
            europaIT: statiItaliano.EUROPA,
            nordAmericaIT: statiItaliano.NORD_AMERICA,
            oceaniaIT: statiItaliano.OCEANIA,
            sudAmericaIT: statiItaliano.SUD_AMERICA,
            //INGLESE
            africaEN: statiInglese.AFRICA,
            asiaEN: statiInglese.ASIA,
            europaEN: statiInglese.EUROPA,
            nordAmericaEN: statiInglese.NORD_AMERICA,
            oceaniaEN: statiInglese.OCEANIA,
            sudAmericaEN: statiInglese.SUD_AMERICA,
            //FINLANDESE
            africaFI: statiFinlandese.AFRICA,
            asiaFI: statiFinlandese.ASIA,
            europaFI: statiFinlandese.EUROPA,
            nordAmericaFI: statiFinlandese.NORD_AMERICA,
            oceaniaFI: statiFinlandese.OCEANIA,
            sudAmericaFI: statiFinlandese.SUD_AMERICA,
          //intrappolare il parametro del continente dal file json
          AFRICA: statiItaliano.AFRICA[0].continente,
          ASIA: statiItaliano.ASIA[0].continente,
          EUROPA: statiItaliano.EUROPA[0].continente,
          NORD_AMERICA: statiItaliano.NORD_AMERICA[0].continente,
          OCEANIA: statiItaliano.OCEANIA[0].continente,
          SUD_AMERICA: statiItaliano.SUD_AMERICA[0].continente,
          //per intrappolare il parametro del continente dalle rotte
          continente: this.$route.params.continente,
        //

        //variabile per far vedere una sola card alla volta
        a: 0,

        //variabile per mostrare la pagina finale
        final_page: 0,

        //questa variabile racchiuderà il numero di stati del continente che stiamo usando
        n_stati: 0,

        //array vuoto per inserire gli id degli stati usciti.
        check_state_array_vuoto: [], 

        //PER SALVARE IL NUMERO CASUALE
        numero_casuale: 0,

        //PUNTEGGIO
          //variabile per mostrare il punteggio
          score: 0,
          //variabile per mostrare il punteggio totale
          total: 0,
          //per dire se è giusto o sbagliato
          guess: "",
          //varibile per tenere il conto di quante risposte sono giusto
          conoscenza: 0,
          //calcola la percentuale di risposte giuste
          percentuale: 0,
        //

        //stato presa dall'input
        tempStato: "",

        //stato presa dall'oggetto
        stato: "",
        Stato: "",
        STATO: "",
        stato_speciale_1: "",
        stato_speciale_2: "",
        stato_speciale_3: "",
        stato_speciale_4: "",
        stato_speciale_5: "",
        stato_speciale_6: "",
        stato_speciale_7: "",
        stato_speciale_8: "",
        stato_speciale_9: "",

        //controlla/prossimo stato
        cp: true,

        //per avviso nel caso in cui non inserisco niente
        passa: false,
        
      }
    },

    //metodi - funzioni
    methods: {

      //CONTROLLA se la stato inserito è corretto e segna il punteggio
      checkState(){

        //fa partire la funzione solo se il campo dell'imput contiene qualcosa
        if(this.tempStato != ''){

          //controlla se la Stato inserito è uguale a tempStato (che è uguale alla Stato dello stato in questione)
          if(this.tempStato == this.stato || this.tempStato == this.Stato || this.tempStato == this.STATO || this.tempStato == this.stato_speciale_1 || this.tempStato == this.stato_speciale_2 || this.tempStato == this.stato_speciale_3 || this.tempStato == this.stato_speciale_4 || this.tempStato == this.stato_speciale_5 || this.tempStato == this.stato_speciale_6 || this.tempStato == this.stato_speciale_7 || this.tempStato == this.stato_speciale_8 || this.tempStato == this.stato_speciale_9){

            this.guess = true
          }
          else{
            this.guess = false
          } 
          
          //aggiunge i punteggi 
          if(this.guess == true){
            this.score = this.score +10
            this.conoscenza = this.conoscenza +1
          }
          else{
            this.score = this.score - 5
          }

          //cambia la variabile pc, quindi porta alla schermata per vedere la soluzione che comprende il pulsante per cambiare stato
          this.cp = !this.cp

          //si assicura che l'avviso di input vuoto non venga visualizzato
          this.passa = false
        }
        //avviso in cui l'imput sia vuoto
        else{
          this.passa = true
        }
    
      },

      //CAMBIA STATO
      changeState(){

        //per cambiare il valore di a e far scorrere gli stati 
        this.a = this.a + 1

        //cose che cambiano in base al continente assegnato
        if(this.continente == this.ASIA){
          //per dare uno Stato a stato, Stato e STATO 
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.ASIA[this.id_casuale].Stato
            this.stato = statiItaliano.ASIA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.ASIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.ASIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.ASIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.ASIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.ASIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.ASIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.ASIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.ASIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.ASIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.ASIA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.ASIA[this.id_casuale].Stato
            this.stato = statiInglese.ASIA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.ASIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.ASIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.ASIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.ASIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.ASIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.ASIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.ASIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.ASIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.ASIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.ASIA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato= statiFinlandese.ASIA[this.id_casuale].Stato
            this.stato= statiFinlandese.ASIA[this.id_casuale].Stato.toLowerCase()
            this.STATO= statiFinlandese.ASIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.ASIA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.ASIA.length - 1
        }
        else if(this.continente == this.AFRICA){
          //per dare uno stato a stato, Stato STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.AFRICA[this.id_casuale].Stato
            this.stato = statiItaliano.AFRICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.AFRICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.AFRICA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.AFRICA[this.id_casuale].Stato
            this.Stato = statiInglese.AFRICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.AFRICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.AFRICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.AFRICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.AFRICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.AFRICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.AFRICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.AFRICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.AFRICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.AFRICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.AFRICA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.AFRICA[this.id_casuale].Stato
            this.stato = statiFinlandese.AFRICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiFinlandese.AFRICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.AFRICA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.AFRICA.length - 1
        }
        else if(this.continente == this.EUROPA){
          //per dare uno stato a stato, Stato e STATO 
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.EUROPA[this.id_casuale].Stato
            this.stato = statiItaliano.EUROPA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.EUROPA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.EUROPA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.EUROPA[this.id_casuale].Stato
            this.stato = statiInglese.EUROPA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.EUROPA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.EUROPA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.EUROPA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.EUROPA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.EUROPA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.EUROPA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.EUROPA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.EUROPA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.EUROPA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.EUROPA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.EUROPA[this.id_casuale].Stato
            this.stato = statiFinlandese.EUROPA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiFinlandese.EUROPA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.EUROPA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.EUROPA.length - 1
        }
        else if(this.continente == this.NORD_AMERICA){
          //per dare uno stato a stato, Stato e STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.NORD_AMERICA[this.id_casuale].Stato
            this.stato = statiItaliano.NORD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.NORD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.NORD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.NORD_AMERICA[this.id_casuale].Stato
            this.stato = statiInglese.NORD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.NORD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.NORD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.NORD_AMERICA[this.id_casuale].Stato
            this.stato = statiFinlandese.NORD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiFinlandese.NORD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.NORD_AMERICA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.NORD_AMERICA.length - 1
        }
        else if(this.continente == this.OCEANIA){
          //per dare uno stato a stato, Stato e STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.OCEANIA[this.id_casuale].Stato
            this.stato = statiItaliano.OCEANIA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.OCEANIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.OCEANIA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.OCEANIA[this.id_casuale].Stato
            this.stato = statiInglese.OCEANIA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.OCEANIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.OCEANIA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.OCEANIA[this.id_casuale].Stato
            this.stato = statiFinlandese.OCEANIA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiFinlandese.OCEANIA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.OCEANIA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.OCEANIA.length - 1
        }
        else if(this.continente == this.SUD_AMERICA){
          //per dare uno stato a stato, Stato e STATO
          if(this.lingua == this.italiano){
            //italiano
            this.Stato = statiItaliano.SUD_AMERICA[this.id_casuale].Stato
            this.stato = statiItaliano.SUD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiItaliano.SUD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiItaliano.SUD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else if(this.lingua == this.inglese){
            //inglese
            this.Stato = statiInglese.SUD_AMERICA[this.id_casuale].Stato
            this.stato = statiInglese.SUD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiInglese.SUD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiInglese.SUD_AMERICA[this.id_casuale].stato_speciale_9
          }
          else{
            //finlandese
            this.Stato = statiFinlandese.SUD_AMERICA[this.id_casuale].Stato
            this.stato = statiFinlandese.SUD_AMERICA[this.id_casuale].Stato.toLowerCase()
            this.STATO = statiFinlandese.SUD_AMERICA[this.id_casuale].Stato.toUpperCase()
            this.stato_speciale_1 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_1
            this.stato_speciale_2 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_2
            this.stato_speciale_3 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_3
            this.stato_speciale_4 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_4
            this.stato_speciale_5 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_5
            this.stato_speciale_6 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_6
            this.stato_speciale_7 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_7
            this.stato_speciale_8 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_8
            this.stato_speciale_9 = statiFinlandese.SUD_AMERICA[this.id_casuale].stato_speciale_9
          }    
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.SUD_AMERICA.length - 1
        }
        
        //cambia la variabile pc, quindi non fa vedere la schermata della soluzione fino a quando non si cliccherà di nuovo il pulsante controlla
        if(this.a > 1){
          this.cp = !this.cp
        }
        
        //salva temporaneamente la capitale inserita dall'utente
        this.tempStato = ""

      },

      //NUMERO CASUALE
      numeroCasuale(){

        //cose che cambiano in base al continente assegnato
        if(this.continente == this.ASIA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.ASIA.length - 1
        }
        else if(this.continente == this.AFRICA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.AFRICA.length - 1
        }
        else if(this.continente == this.EUROPA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.EUROPA.length - 1
        }
        else if(this.continente == this.NORD_AMERICA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.NORD_AMERICA.length - 1
        }
        else if(this.continente == this.OCEANIA){  
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.OCEANIA.length - 1
        }
        else if(this.continente == this.SUD_AMERICA){
          //serve a determinare quanti stati ci sono nel continente in questione
          this.n_stati = statiItaliano.SUD_AMERICA.length - 1
        }
        //mi da un numero casuale tra 1 e la lunghezza dell'array con gli stati (6)
        this.numero_casuale = Math.ceil(Math.random() * ( (this.n_stati) - (1-1)) + (1-1));

        //se l'array dove abbiamo inserito gli id degli stati usciti, include il numero casuale appena uscito allora rifacciamo partire la funzione
        if(this.check_state_array_vuoto.includes(this.numero_casuale)){
          console.log('è gia presente ritira')
          return this.numeroCasuale();
        }
        //se il numero uscito non è contenuto all'interno dell'array allora lo andiamo ad iserire
        else{
          
          //inserisco l'id dello stato estratto nell'array check_state
          this.check_state_array_vuoto.push(this.numero_casuale);
          //console.log(this.check_state_array_vuoto.sort());
          this.id_casuale = this.numero_casuale

        }

      },

      //FINIRE GIOCO
      endGame(){  
        this.stato = ""
        this.Stato = ""
        this.STATO = ""
        this.stato_speciale_1 = ""
        this.stato_speciale_2 = ""
        this.stato_speciale_3 = ""
        this.stato_speciale_4 = ""
        this.stato_speciale_5 = ""
        this.stato_speciale_6 = ""
        this.stato_speciale_7 = ""
        this.stato_speciale_8 = ""
        this.stato_speciale_9 = ""
        this.guess = ""
        this.cp = true
        this.a = this.a + 1
        //per dare un valore a final_page in modo da mostrare solo la card finale
        this.final_page = this.n_stati + 1
        //per salvare il punteggio totale
        this.total = this.n_stati * 10
        //per dare il valore ppercentuale delle risposte giuste
        this.percentuale = ((this.conoscenza/this.n_stati)*100).toFixed(0)
      },

      //RINCOMINCIA
      rincomincia(){
        this.a = 0
        this.score = 0
        this.conoscenza = 0
        this.final_page = 0
        this.total = 0
        //ripulisce l'array per controllare gli stati
        this.check_state_array_vuoto = [];
      },


      //funzione per evitare l'aggiornamento della pagina quando si cambia stato
      preventEvent(e){
        e.preventDefault();
      }
    },
    
  }

</script>

<style>

/* IMMAGINE DI SFONDO E CENTRAMENTO CONTENUTO */
body{
  margin:0;
  padding:0;
  height: max-content;
}
.img-sfondo-indovina{
  background-image: url("../../assets/carta-da-parti-mondoblu-spazio-531.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.centramento-contenuto{
  padding-top: 7%;
  padding-bottom: 7%;
  padding-left: 5%;
  padding-right: 5%;
}

/* CARD TITOLI */
.card-titoli{ 
  background-color: rgba(189, 220, 249, 0.69);
  border: 5px solid black;
  border-radius: 70px;
  filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
}
.margin-title-gioco{
  padding-top: 28%;
}

/* PARTE GIOCABILE */
  
  /* PULSANTE INIZIA */
  .btn-custom-inizia{
    background-color: transparent;
    color: rgba(189, 220, 249, 0.69);
    border: 2px solid rgba(189, 220, 249, 0.69);
    backdrop-filter: blur(10px);
    font-size: 30px;
    border-radius: 100px;
    transition: 0.2s;
    min-width:max-content;
    font-weight: bold;
  }
  .btn-custom-inizia:hover{
    backdrop-filter: blur(10px);
    background-color: rgba(189, 220, 249, 0.69);
    border: 2px solid black;
    color: black; 
  }
  .sperim{
    min-width:max-content;
  }

  /* CARD GIOCO */
  .card-gioco{
    color:  rgba(189, 220, 249, 0.69);
    border: 5px solid rgba(189, 220, 249, 0.69);
    border-radius: 60px;
    filter: drop-shadow(2px 2px 10px rgb(0, 0, 0));
    backdrop-filter: blur(10px);
    background-color: rgb(0,0,0,0.6);
    width: 100%;
    height: 570px;
  }

  /* INPUT */
  .input-custom{
    background-color: transparent;
    border:none;
    border-bottom: 2px solid rgba(189, 220, 249, 0.69);;
    color:rgba(189, 220, 249,0.8);
    width:80%;
    text-align: center;
  }
  .input-custom::placeholder{
    color:rgba(189, 220, 249, 0.4);  
  }
  .input-custom:focus-visible{
    outline:none;
  }

  /* PULSANTI CONTROLLA, PROSSIMO STATO, FINE e RICOMINCIA */
  .btn-custom{
    background-color: transparent;
    color: rgba(189, 220, 249, 0.69);
    border: 2px solid rgba(189, 220, 249, 0.69);
    backdrop-filter: blur(10px);
    font-size: 20px;
    border-radius: 100px;
    transition: 0.2s;
    min-width:max-content;
    padding-right: 3%;
    padding-left: 3%;
  }
  .btn-custom:hover{
    backdrop-filter: blur(10px);
    background-color: rgba(189, 220, 249, 0.69);
    border: 2px solid black;
    color: black; 
  }

  /* AVVISO di CONTROLLO */
  .advertise-custom{
    background-color: rgba(252, 107, 107, 0.45);
    backdrop-filter: blur(10px);
    padding-bottom: 5px;
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 50px;
    border: 2px solid rgba(189, 220, 249, 0.69);
  }

  /* scritta soluzione */
  .orientamento-soluzione{
    display: block;
    justify-content: center;
  }

  /* AVVISO RISPOSTA CORRETTA O SBAGLIATA */
  .correct-answer{
    background-color: rgba(13, 255, 0, 0.284); 
    padding: 5px 10px 5px 10px;
  }
  .wrong-answer{
    background-color: rgba(254, 6, 2, 0.311); 
    padding: 5px 10px 5px 10px;
  }

  /* bordo punteggi */
  .score-border{
    border: 2px solid rgba(189, 220, 249, 0.69);
    max-width: max-content;
    border-radius: 50px;
  }
  .score-margin{
    margin: 10px 15px 10px 15px;
  }
/*  */


@media (min-width: 270px) { 
  .card-gioco{
  height: 530px;
  }
}
@media (min-width: 300px) {  
  .margin-title-gioco{
  padding-top: 23%;
  }
}
@media (min-width: 400px) { 
  .card-gioco{
    width: 80%;
  }
  .margin-title-gioco{
    padding-top: 20%;
  }
  .card-titoli{
    border-radius: 100px;
  }
}
@media (min-width: 470px) { 
  .margin-title-gioco{
    padding-top: 15%;
  }
}
@media (min-width: 520px) { 
  .card-gioco{
    height: 430px;
    border-radius: 100px;
  }
  .centramento-contenuto{
    padding-left: 10%;
    padding-right: 10%;
  }
  .advertise-custom{
    border-radius: 100px;
  }
}
@media (min-width: 570px) { 
  .margin-title-gioco{
    padding-top: 10%;
  }
}
@media (min-width: 600px) { 
  .centramento-contenuto{
    padding-left: 5%;
    padding-right: 5%;
  }
  .orientamento-soluzione{
    display: flex;
  }
}
@media (min-width: 770px) { 
  .centramento-contenuto{
    padding-left: 10%;
    padding-right: 10%;
  } 
  .margin-title-gioco{
    padding-top: 8%;
  }
  .card-titoli{
    border-radius: 80px;
  }
}
@media (min-width: 992px) { 
  .margin-title-gioco{
    padding-top: 3%;
  }
  .card-titoli{
    border-radius: 100px;
  }
}
@media (min-width: 1000px) { 
  .centramento-contenuto{
    padding-left: 15%;
    padding-right: 15%;
  }
}

</style>