<template>

    <body>

        <nav class="nav-custom container navbar justify-content-center" :class="{'nav-drop-fixed' : this.drop == false, 'nav-drop-block' : this.drop == true}">

            <div class="maximo row justify-content-center">

                <!-- pulsante drop schermo piccolo -->
                <a href="#" type="button" class="pulsante-drop text-center"  @click="droppiamo(), ritira()">
                    <span class="pulsante-drop-custom" :class="{'sfondo-pulsante-drop' : this.drop == true}">≡</span>   
                </a>
                
                <!-- parte droppata -->
                <div class="row justify-content-evenly maximo-2" v-bind:class="{'parte-droppata' : this.drop == false, 'direzione-droppata' : this.drop == true}">

                    <!-- HOME -->
                    <div class="col text-center" >
                        <router-link class="toggle-custom" to="/" type="button" @click="ritira(), ritiraNavDrop()">HOME</router-link>
                    </div>

                    <!-- CONTINENTI -->
                    <div class="col text-center">

                        <a class="toggle-custom"  type="button"  @click="continenteAttivo()"  v-bind:class="{'continente-sfondo' : this.sfondoContinente == true}">
                            <span v-if="this.lenguage == 'IT'">CONTINENTI</span>
                            <span v-if="this.lenguage == 'EN'">CONTINENTS</span>
                            <span v-if="this.lenguage == 'FI'">MAASTEET</span>
                        </a>
                        
                        <div class="spaziamo-un-po" v-bind:class="{'drop-continente': this.dropContinente == false}">
                            
                            <div class="d-flex justify-content-center">
                                <router-link class="drop-item-router d-flex justify-content-center align-item-center" to="/europa" @click="ritira(), ritiraNavDrop()">
                                    <p v-if="this.lenguage == 'IT'" class="drop-item-scritta">Europa</p>
                                    <p v-if="this.lenguage == 'EN'" class="drop-item-scritta">Europe</p>
                                    <p v-if="this.lenguage == 'FI'" class="drop-item-scritta">Eurooppa</p>
                                </router-link>
                            </div>
                        
                            <div class="d-flex justify-content-center">    
                                <router-link class="drop-item-router d-flex justify-content-center align-item-center" to="/asia" @click="ritira(), ritiraNavDrop()">
                                    <p v-if="this.lenguage == 'IT'" class="drop-item-scritta">Asia</p>
                                    <p v-if="this.lenguage == 'EN'" class="drop-item-scritta">Asia</p>
                                    <p v-if="this.lenguage == 'FI'" class="drop-item-scritta">Aasia</p>
                                </router-link>
                            </div>

                            <div class="d-flex justify-content-center">    
                                <router-link class="drop-item-router d-flex justify-content-center align-item-center" to="/africa" @click="ritira(), ritiraNavDrop()">
                                    <p v-if="this.lenguage == 'IT'" class="drop-item-scritta">Africa</p>
                                    <p v-if="this.lenguage == 'EN'" class="drop-item-scritta">Africa</p>
                                    <p v-if="this.lenguage == 'FI'" class="drop-item-scritta">AfriKKa</p>
                                </router-link>
                            </div>
                            
                            <div class="d-flex justify-content-center">    
                                <router-link class="drop-item-router d-flex justify-content-center align-item-center" to="/nord-america" @click="ritira(), ritiraNavDrop()">
                                    <p v-if="this.lenguage == 'IT'" class="drop-item-scritta">Nord America</p>
                                    <p v-if="this.lenguage == 'EN'" class="drop-item-scritta">North America</p>
                                    <p v-if="this.lenguage == 'FI'" class="drop-item-scritta">Pohjois-Amerikka</p>
                                </router-link>
                            </div>

                            <div class="d-flex justify-content-center">    
                                <router-link class="drop-item-router d-flex justify-content-center align-item-center" to="/sud-america" @click="ritira(), ritiraNavDrop()">
                                    <p v-if="this.lenguage == 'IT'" class="drop-item-scritta">Sud America</p>
                                    <p v-if="this.lenguage == 'EN'" class="drop-item-scritta">South America</p>
                                    <p v-if="this.lenguage == 'FI'" class="drop-item-scritta">Etelä-Amerikka</p>
                                </router-link>
                            </div>

                            <div class="d-flex justify-content-center">    
                                <router-link class="drop-item-router d-flex justify-content-center align-item-center" to="/oceania" @click="ritira(), ritiraNavDrop()">
                                    <p v-if="this.lenguage == 'IT'" class="drop-item-scritta">Oceania</p>
                                    <p v-if="this.lenguage == 'EN'" class="drop-item-scritta">Oceania</p>
                                    <p v-if="this.lenguage == 'FI'" class="drop-item-scritta">Oseania</p>
                                </router-link>
                            </div>

                        </div>
                    </div>

                    <!-- STUDIO -->
                    <div class="col text-center">
                        <router-link class="toggle-custom" to="/studio" type="button" @click="ritira(), ritiraNavDrop()">
                            <span v-if="this.lenguage == 'IT'" >STUDIO</span>
                            <span v-if="this.lenguage == 'EN'" >STUDY</span>
                            <span v-if="this.lenguage == 'FI'" >TUTKIMUS</span>
                        </router-link>
                    </div>

                    <!-- LINGUE-->
                    <div class="col text-center">

                        <!-- PULSANTE PER CAMBIARE LINGUA -->
                        <a class="toggle-custom"  type="button" @click="lingueAttivo()"  v-bind:class="{'lingue-sfondo' : this.sfondoLingue == true}">
                            <span v-if="this.lenguage == 'IT'">
                                <img class="grendezza-immagie-lingua-cambia" :src="BandieraLinguaItalia" alt="immagine della bandiera italiana">
                            </span>
                            <span v-if="this.lenguage == 'EN'">
                                <img class="grendezza-immagie-lingua-cambia" :src="BandieraLinguaRegnoUnito" alt="immagine della bandiera italiana">
                            </span>
                            <span v-if="this.lenguage == 'FI'">
                                <img class="grendezza-immagie-lingua-cambia" :src="BandieraLinguaFinlandia" alt="immagine della bandiera italiana">
                            </span>
                        </a>

                        <!-- DROPDOWN BANDIERE -->
                        <div  class="spaziamo-un-po" v-bind:class="{'drop-lingue': this.dropLingue == false}">

                            <!-- BANDIERA ITALIANA -->
                            <div class="drop-item d-flex justify-content-center text-center">
                                <button class="drop-item-router" v-on:click="italiano" @click="invia(lenguage), ritira(), ritiraNavDrop()">
                                    <img class="grendezza-immagie-lingua" :src="BandieraLinguaItalia" alt="immagine della bandiera italiana">
                                </button>
                            </div>

                            <!-- BANDIERA INGLESE -->
                            <div class="drop-item d-flex justify-content-center text-center">
                                <button class="drop-item-router" v-on:click="inglese" @click="invia(lenguage), ritira(), ritiraNavDrop()">
                                    <img class="grendezza-immagie-lingua" :src="BandieraLinguaRegnoUnito" alt="immagine della bandiera italiana">
                                </button>
                            </div>  

                            <!-- BANDIERA FINLANDESE -->
                            <div class="drop-item d-flex justify-content-center text-center">
                                <button class="drop-item-router" v-on:click="finlandese" @click="invia(lenguage), ritira(), ritiraNavDrop()">
                                    <img class="grendezza-immagie-lingua" :src="BandieraLinguaFinlandia" alt="immagine della bandiera italiana">
                                </button>
                            </div>

                        </div>
                    </div>
            

                </div>

            </div>

        </nav>
    </body>

</template>

<script>

    //per importare le immagini per il cambio di lingua
    import imgItalia from "../assets/bandiere/bandiere-europa/icons8-italia-100.png"
    import imgRegnoUnito from "../assets/bandiere/bandiere-europa/icons8-regno-unito-100.png"
    import imgFinlandia from "../assets/bandiere/bandiere-europa/icons8-finlandia-100.png"

    export default{
        name: 'Navbar',
        props: ['lingua'],

        data(){
            return{

                //per settare la lingua
                lenguage: "IT",

                //per le immagini per cambiare lingua
                BandieraLinguaItalia:imgItalia,
                BandieraLinguaFinlandia:imgFinlandia,
                BandieraLinguaRegnoUnito:imgRegnoUnito,

                //per dare uno sfondo quando è attivo
                sfondoContinente: false,
                sfondoLingue: false,

                //display none per i dorpdown
                dropContinente: false,
                dropLingue: false,

                //per droppare quando lo schermo e piccolo
                drop: false,
            }
        },

        methods:{
            //per cambiare la lingua
            inglese(){
                this.lenguage = "EN"
            },
            italiano(){
                this.lenguage = "IT"
            },
            finlandese(){
                this.lenguage = "FI"
            },
            //per inviare la lingua cambiata ad app.vue
            invia(lenguage){
                this.$emit('invia', lenguage)
            },

            //per dare lo sfondo quando il pulsante della e attivo
            continenteAttivo(){
                this.sfondoContinente = !this.sfondoContinente
                this.dropContinente = !this.dropContinente

            },
            lingueAttivo(){
                this.sfondoLingue = !this.sfondoLingue
                this.dropLingue = !this.dropLingue
            },

            //per ritirare il collapse dagli altri pulsanti
            ritira(){
                this.dropContinente = false
                this.sfondoContinente = false
                this.dropLingue = false
                this.sfondoLingue = false
            },
            ritiraNavDrop(){
                this.drop = false
            },

            //droppaggio schermo piccolo
            droppiamo(){
                this.drop = !this.drop
            }
        }
    }
</script>

<style>

.body{
    margin: 0;
    padding: 0;
}

.nav-custom{
    background-color:black;
    color:rgba(189, 220, 249, 0.69);
    min-width:100%;
    height: 60px;
    z-index: 3;
}
.maximo{
    width: 100vh;
}
.maximo-2{
    min-width: max-content;
}

/* parte droppata e pulsante drop per telefono*/
.nav-drop-fixed{
    position: fixed;
}
.nav-drop-block{
    position: block;
}
.parte-droppata{
    display: none;
}
.direzione-droppata{
    flex-direction: column;
    background-color: transparent;
    backdrop-filter: blur(10px);
    margin-top: 6px;
    padding-top: 15px;
    width: 100%;
    padding-bottom: 100%; 
}
.pulsante-drop{ 
    padding: 0px 0px;
    margin-bottom: 8px;
    text-decoration: none;
}
.pulsante-drop-custom{
    color: rgba(189, 220, 249, 0.69);
    border:solid 3px black;
    border-radius: 17px;
    font-size: 25px;
    padding: 5px 10px 5px 10px;
    margin-bottom: 5px;
    
}
.pulsante-drop-custom:hover{
    border:solid 3px rgba(189, 220, 249, 0.69);
}
.sfondo-pulsante-drop{
    background-color: rgba(189, 220, 249, 0.69);
    color:black;
    font-weight: bold;
}

/* pulsanti nella navbar */
.toggle-custom{
    color:rgba(189, 220, 249, 0.69);
    text-decoration: none;
    background-color: black;
    border:solid 3px rgba(189, 220, 249, 0.69);
    padding: 5px 10px;
    border-radius: 100px; 
    min-width:150px;
    margin-top: 35px;
}
.toggle-custom:hover{
    background-color: rgba(189, 220, 249, 0.69);
    color: black;
    font-weight: bold;
    border:solid 3px black;
}
.toggle-custom:active{
    background-color:rgba(189, 220, 249, 0.69);
    color:black;
    font-weight: bold;
}
.continente-sfondo{
    background-color:rgba(189, 220, 249, 0.69);
    color: black;
    font-weight: bold;
}
.lingue-sfondo{
    background-color:rgba(189, 220, 249, 0.69);
    color: black;
    font-weight: bold;
}

/* far visualizzare il contenuto dei pulsanti droppabili */
.drop-continente{
    display: none;
}
.drop-lingue{
    display: none;
}


/* pulsanti droppato*/
.drop-item-router{
    color:rgba(189, 220, 249, 0.69);
    text-decoration: none;
    border:solid 3px rgba(189, 220, 249, 0.69);
    border-radius: 100px; 
    background-color:rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(10px);
    width:100%;
    padding:5px;
    width:150px;
    margin-top: 10px;
}
.drop-item-router:hover{
    background-color:rgba(189, 220, 249, 0.69);
    color:black;
    border:solid 3px black;
    font-weight: bold;
} 
.drop-item-scritta{
    margin:0px;
}

/* bandiere per le lingue */
.grendezza-immagie-lingua{
    width:35px;
}
.grendezza-immagie-lingua-cambia{
    width:25px;
}

@media (min-width: 768px) {
    .nav-drop-block{
        position: block;
    }
    .parte-droppata{
        display: flex;
    }
    .direzione-droppata{
        width: 100vh;
        flex-direction: row;
        background-color: transparent;
        backdrop-filter: none;
        margin-top: 0px;
        padding-bottom: 0px;
        padding-top: 0px
    }
    .pulsante-drop{
        display: none;
    }
    .sfondo-nero{
        flex-direction: row;
    }
    .nav-custom{
        height: 60px;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .toggle-custom{
        margin-top: 0px;   
        color:rgba(189, 220, 249, 0.69); 
        border:solid 3px black;
        background-color: transparent;
    }
    .toggle-custom:hover{
        background-color: transparent;
        color: rgba(189, 220, 249, 0.69);
        font-weight: normal;
        border:solid 3px rgba(189, 220, 249, 0.69);
    }
    .toggle-custom:active{
        background-color:rgba(189, 220, 249, 0.69);
        color:black;
        font-weight: bold;
    }
    .continente-sfondo{
        background-color:rgba(189, 220, 249, 0.69);
        color: black;
        font-weight: bold;
    }
    .lingue-sfondo{
        background-color:rgba(189, 220, 249, 0.69);
        color: black;
        font-weight: bold;
    }
    .spaziamo-un-po{
        margin-top: 7px;
    }
    .maximo{
        width: 100%;
    }
}
@media (min-width: 1008px) {
    .toggle-custom{
        margin: 0px 36px;
    }
    
}

</style>